import { createTheme } from '@material-ui/core/styles';
import { teal, grey, red } from '@material-ui/core/colors';

const theme = createTheme({
  palette: {
    primary: teal,
    secondary: grey,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  }
});

export default theme;