import React from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import ProgressIcon from '@material-ui/icons/Loop';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

import ActionButton from './ActionButton';
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import Moment from 'moment';
import T from 'i18n-react';

export class ToggleButton extends React.Component
{
    constructor(props) {
        super(props);

        if (! props.appContext ) throw new Error("appContext is a required property");
        if (! props.dataId ) throw new Error("dataId is a required property");
        if (! props.onLabel ) throw new Error("onLabel is a required property");
        // if (! props.onLabel2 ) throw new Error("onLabel2 is a required property");
        if (! props.offLabel ) throw new Error("offLabel is a required property");
        // if (! props.offLabel2 ) throw new Error("offLabel2 is a required property");
        if (! props.onIcon ) throw new Error("onIcon is a required property");
        if (! props.offIcon ) throw new Error("offIcon is a required property");

        this.state = {
            dataId: props.dataId,
            currentState: -1,
            dialog: null
        };
    }

    componentDidMount() {
        const self = this;
        const { appContext } = this.props;
        const { idToken, patientId } = appContext.state;
        const { dataId } = this.state;

        // {{base_url}}/{{pathPrefix}}patients/{{patientId}}/data?category=labTest          
        let endTime = Moment().toISOString();
        let startTime = Moment().startOf('day').toISOString();
        let args = [
            `dataId=${dataId}`,
            `startTime=${startTime}`,
            `endTime=${endTime}`,
            `limit=1`
        ]      
        let getData = {
            url: Config.baseUrl + `${Config.pathPrefix}patients/${patientId}/data?${args.join('&')}`,
            method: 'GET',
            headers: { "Authorization": idToken },
        };

        console.log("getData request:", getData);
        AxiosRequest(getData).then((response) => {
            console.log("getData result:", response.data);

            let currentState = 0;
            if (response.data.data.length > 0) {
                currentState = response.data.data[0].data[0];
            }
            self.setState({currentState});
        }).catch((err) => {
            console.error("getData error:", err);
            setTimeout( self.componentDidMount.bind(self), 1000 );      // retry in 1000 ms
        })
    }

    onCloseDialog() {
        this.setState({dialog: null});
    }

    onToggle( confirmed = false ) {
        const { appContext } = this.props;
        const { idToken, patientId } = appContext.state;
        let { dataId, currentState } = this.state;

        if (! confirmed ) {
            let content = null;
            if ( currentState === 0 && this.props.onConfirm ) {
                content = this.props.onConfirm;
            } else if ( currentState === 1 && this.props.offConfirm ) {
                content = this.props.offConfirm;
            }

            if ( content ) {
                let dialog = <Dialog open={true} fullWidth={true} maxWidth={'xs'} style={{background: 'rgba(0,0,0,0.6)'}}>
                    <DialogContent style={{ marginBottom:0 }}>
                        <div style={{ marginRight:10, float: 'left' }}><LiveHelpIcon style={{fontSize:'48'}} /></div>
                        <div style={{ marginTop:5,textAlign:'justify'}}>{content}</div>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'space-between', margin: '10px' }}>
                        <Button id="onsite-cancel" variant='contained' color='primary' onClick={this.onCloseDialog.bind(this)}><T.span style={{ fontSize:14 }} text='cancel' /></Button>
                        <Button id="onsite-confirm" variant='contained' color='primary' onClick={this.onToggle.bind(this, true)}><T.span style={{ fontSize:14 }} text='confirm' /></Button>
                    </DialogActions>
                </Dialog>;

                return this.setState({dialog});
            }
        }

        currentState = currentState <= 0 ? 1 : 0;
        this.setState({currentState, dialog: null});

        let data = {
            dataId,
            data: [ currentState ],
            status: 'submitted',
            baseline: false,
            source: [ 
                { origin: 'patient-web-client' }
            ]
        };
        let saveData = {
            url: Config.baseUrl + `${Config.pathPrefix}patients/${patientId}/data`,
            method: 'POST',
            headers: { "Authorization": idToken },
            data
        };

        console.log("saveData Request:", saveData);
        AxiosRequest(saveData).then((result) => {
            console.log("saveData Result:", result.data);
        }).catch((err) => {
            console.error("saveData error:", err);
        });
    }

    render() {
        let { currentState, dialog } = this.state;

        if ( currentState < 0 ) {       // still loading
            return <ActionButton id={this.props.id} disabled={true} to={this.props.to} onClick={this.onToggle.bind(this, false)}
                color={this.props.color} icon={<ProgressIcon />} label={this.props.onLabel} />;
        }
        else {
            return <ActionButton 
                id={this.props.id} 
                disabled={this.props.disabled} 
                to={this.props.to} 
                onClick={this.onToggle.bind(this, false)} 
                metric={this.props.metric}
                color={this.props.color} 
                icon={currentState ? this.props.offIcon : this.props.onIcon} 
                dialog={dialog}
                label={currentState ? this.props.offLabel : this.props.onLabel}
                label2={currentState ? this.props.offLabel2 : this.props.onLabel2}
                alreadySelected={currentState ? true : false} />;
        }
    }
}

export default ToggleButton;
