import React from 'react';
import { 
    Grid,
    Paper,
    Badge,
    Avatar
} from '@material-ui/core';
import { Logger } from '@apricityhealth/web-common-lib/utils/Logger';
import { Link } from 'react-router-dom';

const log = new Logger();
export class ActionButton extends React.Component {
    constructor(props) {
        super(props);

        if ( props.icon === undefined ) throw new Error("icon property required.");
        if ( props.label === undefined ) throw new Error("label property required.");
        // if ( props.label2 === undefined ) throw new Error("label2 property required.");
        if ( props.to === undefined && props.onClick === undefined ) throw new Error("to or onClick property required");

        this.state = {
            badge: props.badge || null,
            disabled: props.disabled || false
        }
    }

    componentDidUpdate(oldProps) {
        if ( oldProps.badge !== this.props.badge ) {
            this.setState({badge: this.props.badge || null});
        }
        if ( oldProps.disabled !== this.props.disabled ) {
            this.setState({disabled: this.props.disabled});
        }
    }

    render() {
        let { badge, disabled } = this.state;

        let paperStyle = JSON.parse(JSON.stringify(styles.paper));
            paperStyle.background = disabled ? '#bbbbbb' : '#ffffff';
            paperStyle.background = this.props.alreadySelected ? '#dddddd' : '#ffffff';

        let icon = React.cloneElement( this.props.icon, {fontSize: 'small', style: {fill: (( disabled ) || ( this.props.alreadySelected )) ? '#999999' : this.props.color}});
        if (( disabled ) || ( this.props.alreadySelected )) {
            return <Grid id={this.props.id} item xs={12} sm={4} style={styles.actionButton}>
                <div style={{ textDecoration: 'none' }}>
                    <Paper style={paperStyle}>
                        <div style={{ margin: 11, float: 'left' }}>
                            <Badge style={{ right: 5, top: 5 }} color='secondary' overlap="circular" badgeContent={badge}>
                                <Avatar style={{ right: -5, top: -5, width: 35, height: 35, border: '2px solid #999999', backgroundColor: '#dddddd' }}>
                                    {icon}
                                </Avatar>
                            </Badge>
                        </div>
                        <div style={{ marginTop:12, float: 'left', overflow: 'clip' }}>
                            <div style={{ color: '#999999', fontSize: 18, marginBottom: 0, paddingBottom: 0 }}>{this.props.label}</div>
                            {/* All buttons should have a secondary description -desc */}
                            <div style={{ color: '#999999', fontSize: 12, marginTop: 0, paddingTop: 0 }}>{this.props.label2}</div>
                        </div>
                    </Paper>
                </div>
                {this.props.dialog}
            </Grid>;
        }
        else {
            return <Grid id={this.props.id} item xs={12} sm={4} style={styles.actionButton}>
                <Link disabled={disabled} to={this.props.to} onClick={(e) => {
                    log.metric( { EventName: "PatientAction", Action: this.props.metric || this.props.id, count: 1 } );
                    if ( this.props.onClick )
                        this.props.onClick(e);
                }} style={{ textDecoration: 'none' }}>
                    <Paper variant="outlined" elevation={1} style={paperStyle}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ margin: 11, float: 'left' }}>
                                <Badge style={{ right: 5, top: 5 }} color='secondary' overlap="circular" badgeContent={badge}>
                                    <Avatar style={{ right: -5, top: -5, width: 35, height: 35, border: `2px solid ${this.props.color}`, backgroundColor: 'white' }}>
                                        {icon}
                                    </Avatar>
                                </Badge>
                            </div>
                            <div style={{ marginTop: 12, float: 'left' }}>
                                <div style={{ color: '#333333', fontSize: 18, marginBottom: 0, paddingBottom: 0 }}>{this.props.label}</div>
                                {/* All buttons should have a secondary description -desc */}
                                <div style={{ color: '#999999', fontSize: 12, marginTop: 0, paddingTop: 0 }}>{this.props.label2}</div>
                            </div>
                        </div>
                    </Paper>
                </Link>
                {this.props.dialog}
            </Grid>;
        }
    }
}

const styles = {
    actionButton: {
        width: 350,
        minWidth: 340,
    },
    paper: {
        overflow: 'hidden',
        marginLeft: 15,
        marginRight: 15,
        marginTop: 5,
        marginBottom: 5,
        height: 65,
        textAlign: 'left',
        alignItems: 'center',
    }
};

export default ActionButton;
