// returns the highest alert level from the data, that matches the dataId provided.
export function findPeakAlert(data, dataId, alert_levels) {
    let peak = null;
    let peakLevel = null;
    let alertIds = dataId.split(',');
    for(let i=0;i<data.length;++i) {
        let record = data[i];
        if ( alertIds.indexOf( record.dataId ) < 0 ) continue;
        let alert = record.data[0];
        let level = alert_levels.find((e) => e.alertLevelId === alert);
        if (! level ) {
            console.error("Alert level not found:", alert );
            continue;
        }
        if ( peak === null || level.priority > peakLevel.priority) {
            peak = record;
            peakLevel = level;
        }
    }
    return { peak, peakLevel };
}

// searches the data array, and returns the last alerts for all the given dataIds
export function findLastAlerts(data, dataId) {
    let lastAlerts = {};
    let alertIds = dataId.split(',');
    for(let i=0;i<data.length;++i) {
        let record = data[i];
        if ( lastAlerts[record.dataId] ) continue;
        if ( alertIds.indexOf( record.dataId ) < 0 ) continue;
        lastAlerts[record.dataId] = record;
    }   
    return lastAlerts;
}

// returns the highest priority last alert for the given dataId, dataId can be a comma , seperated list of ids
export function findLastAlert(data, dataId, alert_levels ) {
    let lastAlert = null;
    let alerts = findLastAlerts(data, dataId);
    for(let k in alerts) {
        let alert = alerts[k];
        let level = alert_levels.find((e) => e.alertLevelId === alert.data[0]);
        if (! level ) continue;
        if ( lastAlert === null || lastAlert.level.priority < level.priority ) {
            lastAlert = alert;
            lastAlert.level = level;
        }
    }
    return lastAlert;
}

// push the provided unique values into the array, the values may be , seperated.
export function pushUnique(array, values) {
    if ( values ) {
        values = values.split(',');
        for(let i=0;i<values.length;++i) {
            if ( array.indexOf(values[i]) < 0 )
                array.push(values[i]);
        }
    }
}

export function toBoolean(v, defaultValue = false) {
    if (typeof v === 'string')
        v = v.toLowerCase();
    if ( v === true || v === 'true' )
        return true;
    else if ( v === false || v === 'false' )
        return false;
    else
        return defaultValue;
}

