import React from 'react';
import { 
    Grid
} from '@material-ui/core';

export class ContentSimpleView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            content: props.content
        }
    }

    componentDidUpdate(oldProps) {
        if ( oldProps.content !== this.props.content) {
            this.setState({content: this.props.content});
        }
    }

    render() {
        const { appContext } = this.props;
        const { content } = this.state;

        console.log("ContentView.render: ", content );
        function hookDiv(ref) {
            if ( ref ) {
                ref.addEventListener('click',(e) => {
                    if ( appContext.onURL && e.target.tagName === 'A' ) {
                        appContext.onURL({ href: e.target.href} );
                        e.preventDefault();
                    }
                });
            }
        }

        let text = []
        if ( Array.isArray(content)) {
            for (let i = 0; i < content.length; i++) {
                text.push(<div ref={hookDiv} style={styles.div} key={i} 
                    dangerouslySetInnerHTML={{ __html: content[i].text }} />)
                text.push(<br key={`br${i}`} />);
            }
        }
       return (
            <Grid item style={{width: '100%'}}>
                <div style={styles.div}>
                    {text.length > 0 ? text : "Content not found!"}
                </div >
            </Grid>
        );
    }
}

const styles = {
    div: {
        textAlign: 'left'
    }
};

export default ContentSimpleView;
