import { createTheme } from '@material-ui/core/styles';
import { pink, blue, red } from '@material-ui/core/colors';

const theme = createTheme({
  palette: {
    primary: pink,
    secondary: blue,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  }
});

export default theme;