import React from 'react';
import T from 'i18n-react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    DialogTitle,
    IconButton
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close'
import { processEducationContent } from '../views/EducationView';

export class TutorialDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tutorial: props.tutorial,
            canDismiss: false
        }
    }

    render() {
        const { appContext } = this.props;
        const { tutorial, canDismiss } = this.state;

        return <Dialog open={true} maxWidth='lg' fullWidth onClose={this.props.onClose}> 
            <DialogTitle style={{ textAlign: 'right'}}>
                <IconButton onClick={this.props.onClose}><CloseIcon /></IconButton>
            </DialogTitle>
            <DialogContent>
                {processEducationContent([tutorial], appContext, { outerCarousel: false, height: 450, onCarouselChange: (slide, i, count ) => {
                    console.log("onCarouselChange:", slide, i, count );
                    this.setState({canDismiss: i >= (count - 1 )});
                } })}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button variant='contained' color='primary' onClick={this.props.onDismiss}><T.span text='skip' /></Button>
                <Button disabled={!canDismiss} variant='contained' color='primary' onClick={this.props.onDismiss}><T.span text='done' /></Button>
            </DialogActions>
        </Dialog>;
    }
}
