import React from 'react';
import {
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@material-ui/core/'

import Moment from 'moment';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { validPassword } from '@apricityhealth/web-common-lib/views/LoginView';
import EmailValidator from 'email-validator';
import MuiPhoneNumber from 'material-ui-phone-number';
import phone from 'phone';
import T from 'i18n-react';
import _ from 'lodash';

import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import Config from '@apricityhealth/web-common-lib/Config';
import SelectCountry from '@apricityhealth/web-common-lib/components/SelectCountry';
import SelectRace from '@apricityhealth/web-common-lib/components/SelectRace';
import SelectEthnicity from '@apricityhealth/web-common-lib/components/SelectEthnicity';
import { toBoolean } from '../utils/utils';
import SearchProvider from '../components/SearchProvider';
import scanSound from '../sounds/videoChatReady.wav';
import AndroidStoreImage from '../android.png';
import iOSStoreImage from '../ios.png';

function isReactNative() {
    return window.ReactNativeWebView ? true : false;
}

const STAGE = (process.env.REACT_APP_STAGE || 'local').trim();
const ENABLE_TEST_MODE = STAGE === 'local';
const CREATE_STEPS = {
    account: 'account',                 // collect email & password
    verifyEmail: 'verifyEmail',         // verify the email address
    scan: 'scan',                       // scan drivers licence to collect demographic data
    demographics: 'demographics',       // verify & edit demographic data
    provider: 'provider',               // if enabled on the org, ask for their provider and add to the notes
    activationCode: 'activationCode',   // request an activation code
    complete: 'complete'                // complete page, only displayed when iframe is true
};

export class CreateAccountDialog extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);

        let step = CREATE_STEPS.account,
            email = '',
            phone = '',
            tempPassword = '',
            disableEmail = false,
            disablePhone = false;
        if (props.opts) {
            const { opts } = props;
            console.log("CreateAccountDialog found opts:", opts);
            if (opts.username) {
                if (this.validEmail(opts.username)) {
                    email = opts.username;
                    disableEmail = true;
                } else if (this.validPhoneNumber(opts.username)) {
                    phone = opts.username;
                    disablePhone = true;
                }
            }
            if (opts.password) {
                tempPassword = opts.password;
            }
        }

        //const locations = props.org 
        this.state = {
            step,
            email,
            phone,
            tempPassword,
            password: tempPassword,
            confirmPassword: tempPassword,
            validationCode: '',
            firstName: '',
            lastName: '',
            gender: '',
            activationCode: '',
            employeeId: '',
            race: '',
            registrationCode: props.registrationCode || '',
            location: (props.org && props.org.locations && props.org.locations.find(loc => loc.default === true) && props.org.locations.find(loc => loc.default === true).locationId)
                || (props.org && props.org.locations && props.org.locations.length === 1 && props.org.locations[0] && props.org.locations[0].locationId),
            country: 'US',
            postalCode: '',
            notes: [],
            dob: null,
            progress: null,
            error: null,
            dialog: null,
            org: props.org,
            providerId: props.providerId || undefined,
            planId: props.planId || undefined,
            iframe: toBoolean(query.get("iframe")),
            disableEmail,
            disablePhone,
            orgFields: {},
            providerReferral: null
        };

        if (ENABLE_TEST_MODE) {
            this.state.email = `rlyle+test${Math.floor(Math.random() * 1000000)}@apricityhealth.com`;
            this.state.password = this.state.confirmPassword = "Test1234";
            this.state.validationCode = "123456";
            this.state.firstName = "Test";
            this.state.lastName = "Patient";
            this.state.postalCode = "78641";
            this.state.dob = "01/01/1969";
            //this.state.phone = "+15121234567";
        }
        console.log(`STAGE: ${STAGE}, org:`, props.org);
    }


    playScanSound() {
        try {
            const readyAudio = new Audio(scanSound);
            readyAudio.play();
        }
        catch (err) {
            console.log("Failed to play sound:", err);
        }
    }

    componentDidMount() {
        this.props.appContext.registerMessageHandler(this.onMessage.bind(this));
    }

    componentWillUnmount() {
        this.props.appContext.unregisterMessageHandler(this.onMessage.bind(this));
    }

    onMessage(msg) {
        try {
            if (typeof msg.data === 'string') {
                console.log("onMessage:", msg);
                let data = JSON.parse(msg.data);
                if (this[data.action] !== undefined)
                    this[data.action](data);
            }
        }
        catch (err) {
            console.error("onMessage error:", err);
        }
    }

    onScannedBarCode(data) {
        console.log(' **** onScannedBarCode ****', data)
        const { firstName, lastName, postalCode, dob, gender } = data.parsedDemographics;
        this.setState({ firstName, lastName, postalCode, dob, gender, step: CREATE_STEPS.demographics });
        this.playScanSound();
    }

    onCloseDialog() {
        this.setState({dialog: null})
    }

    onCreateAccount() {
        const { org, iframe } = this.state;

        console.log("onCreateAccount:", this.state);
        let registerRequest = {
            url: Config.baseUrl + `${Config.pathPrefix}anon/authentication/register`,
            method: 'POST',
            data: {
                email: this.state.email,
                activationCode: this.state.activationCode,
                employeeId: this.state.employeeId.trim(),
                phone: phone(this.state.phone).phoneNumber || undefined, //.replace(/[- )(]/g, ""),
                firstName: this.state.firstName.trim(),
                lastName: this.state.lastName.trim(),
                gender: this.state.gender,
                dob: Moment(this.state.dob).format("YYYY-MM-DD"),
                race: this.state.race !== 'unspecified' ? this.state.race : '',
                ethnicity: this.state.ethnicity !== 'unspecified' ? this.state.ethnicity : '',
                country: this.state.country,
                postalCode: this.state.postalCode,
                password: this.state.tempPassword || this.state.password,
                newPassword: this.state.tempPassword ? this.state.password : undefined,
                locationId: this.state.location || '',
                providerId: this.state.providerId,
                orgId: org.orgId,
                planId: this.state.planId,
                group: "patients",
                notes: this.state.notes,
                orgFields: this.state.orgFields,
                providerReferral: this.state.providerReferral
            }
        }

        console.log("registerRequest:", registerRequest);
        this.setState({ progress: <CircularProgress size={20} /> });
        AxiosRequest(registerRequest).then((response) => {
            let result = response.data;
            result.username = this.state.email;
            result.country = this.state.country;
            console.log("register response:", result);
            if (iframe) {
                this.setState({ progress: null, step: CREATE_STEPS.complete })
            } else {
                this.setState({ progress: null });
                this.props.onDone(result);
            }
        }).catch((err) => {
            console.error("onCreateAccount error:", err);
            if (err.response && err.response.status === 409) {
                let accountConflict = <Dialog open={true}>
                    <DialogTitle>
                        <T.span text='accountConflictTitle' />
                    </DialogTitle>
                    <DialogContent>
                        <T.span text='accountConflict' />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" variant="contained" onClick={this.onCloseDialog.bind(this)}><T.span text='close' /></Button>
                    </DialogActions>
                </Dialog>;

                this.setState({ progress: null, dialog: accountConflict, error: getErrorMessage(err) });
            }
            else if (err.response && err.response.status === 404) {
                let unableToRegister = <Dialog open={true}>
                    <DialogTitle>
                        <T.span text='registerErrorTitle' />
                    </DialogTitle>
                    <DialogContent>
                        {org.enableRoster ? <T.span text='rosterNotFound' /> : <T.span text='codeNotFound' />}
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" variant="contained" onClick={this.onCloseDialog.bind(this)}><T.span text='close' /></Button>
                    </DialogActions>
                </Dialog>;

                this.setState({ progress: null, dialog: unableToRegister, error: getErrorMessage(err) });
            }
            else {
                let registerError = <Dialog open={true}>
                    <DialogTitle>
                        <T.span text='registerErrorTitle' />
                    </DialogTitle>
                    <DialogContent>
                        <T.span text='registerError' />
                        <br />
                        <br />
                        <span style={{ color: 'red' }}>{getErrorMessage(err).toUpperCase()}</span>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" variant="contained" onClick={this.onCloseDialog.bind(this)}><T.span text='close' /></Button>
                    </DialogActions>
                </Dialog>

                this.setState({ progress: null, dialog: registerError, error: getErrorMessage(err) });
            }
        })
    }

    validEmail(email) {
        return EmailValidator.validate(email);
    }

    validPhoneNumber(number) {
        return phone(number).isValid;
    }

    validDOB(dob) {
        let validDob = true
        if (dob) {
            let mDob = Moment(dob)
            let mNow = Moment()
            if (mNow.diff(mDob, 'years') < 13) {
                validDob = false
            }
        }
        else {
            validDob = false
        }
        return validDob
    }

    onChange(prop, e) {
        if (e.target)
            e = e.target.value;

        if (prop === 'firstName' || prop === 'lastName' || prop === 'email')
            e = e.replace(/(['\u2018\u2019])/ig, "'");

        let newState = {};
        newState[prop] = e;
        console.log(`onChange ${prop} = ${e}`);
        this.setState(newState);
    }

    verifyRegistrationCode() {
        return new Promise((resolve, reject) => {
            const { registrationCode, org } = this.state;
            if (registrationCode) {
                const verifyRegistrationCode = {
                    url: Config.baseUrl + `${Config.pathPrefix}anon/orgs/code/${encodeURIComponent(registrationCode)}`,
                    method: 'GET'
                };

                console.log("verifyRegistationCode request:", verifyRegistrationCode);
                AxiosRequest(verifyRegistrationCode).then((result) => {
                    console.log("verifyRegistationCode result:", result.data);
                    const { appContext } = this.props;
                    let { planId, providerId } = this.state;
                    const { code, org } = result.data;
                    const { locationId: location } = code;

                    const defaultPlan = org.planIds ? org.planIds.find((e) => e.default === true) : null;
                    if (defaultPlan)
                        planId = defaultPlan.planId;
                    if (org.defaultProviderId)
                        providerId = org.defaultProviderId;

                    console.log(`location: ${location}, planId: ${planId}, providerId: ${providerId}, org:`, org);
                    this.setState({ org, location, planId, providerId });
                    appContext.setState({ domainOrg: org, defaultPlanId: planId, defaultProviderId: providerId });            // also update the domain org in the main app

                    resolve();
                }).catch((err) => {
                    reject(err);
                });
            } else {
                if (org && org.requireRegistrationCode)
                    return reject(new Error("Registration code is required!"));
                // no registration code, so just resolve()
                resolve();
            }
        })
    }

    onVerifyAccount() {
        const { email, disableEmail } = this.state;

        this.setState({ progress: <CircularProgress size={20} />, error: null });
        this.verifyRegistrationCode().then(() => {
            if (disableEmail) {
                // if email is disabled, this means they are using a federated ID, so we can skip the email validation..
                return this.onValidateEmailDone();
            }
            let verifyEmail = {
                url: Config.baseUrl + `${Config.pathPrefix}anon/authentication/emailValidate`,
                method: 'POST',
                data: {
                    email
                }
            }

            console.log("verifyEmail request:", verifyEmail);
            AxiosRequest(verifyEmail).then((result) => {
                console.log("verifyEmail result:", result.data);
                this.setState({ progress: null, step: CREATE_STEPS.verifyEmail });
            }).catch((err) => {
                this.setState({ progress: null, error: getErrorMessage(err) });
            })

            // start a timer, to check if the email gets validated by a link in the email..
            this._checkVerified = setInterval(() => {
                let checkVerified = {
                    url: Config.baseUrl + `${Config.pathPrefix}anon/authentication/emailValidate`,
                    method: 'POST',
                    data: {
                        email,
                        isVerified: true
                    }
                }
                console.log("emailValidate request:", checkVerified);
                AxiosRequest(checkVerified).then((result) => {
                    console.log("emailValidate result:", result.data);
                    if (result.data.validated === true) {
                        this.stopEmailVerificationTimer();
                        this.onValidateEmailDone();
                    }
                }).catch((err) => {
                    console.error("emailValidate error:", err);
                })
            }, 1000);
        }).catch((err) => {
            this.setState({ progress: null, error: getErrorMessage(err) });
        })
    }

    stopEmailVerificationTimer() {
        if (this._checkVerified) {
            clearInterval(this._checkVerified);
            this._checkVerified = null;
        }
    }

    onValidateEmail() {
        this.stopEmailVerificationTimer();

        if (ENABLE_TEST_MODE && this.state.validationCode === '123456') {
            return this.onValidateEmailDone();
        }

        let verifyEmail = {
            url: Config.baseUrl + `${Config.pathPrefix}anon/authentication/emailValidate`,
            method: 'POST',
            data: {
                email: this.state.email,
                validationCode: this.state.validationCode
            }
        }

        this.setState({ progress: <CircularProgress size={20} />, error: null });
        console.log("verifyEmail request:", verifyEmail);
        AxiosRequest(verifyEmail).then((result) => {
            console.log("verifyEmail result:", result.data);
            this.onValidateEmailDone();
        }).catch((err) => {
            this.setState({ validationCode: '', progress: null, error: getErrorMessage(err) });
        })
    }

    onValidateEmailDone() {
        if (isReactNative()) {
            this.setState({ progress: null, step: CREATE_STEPS.scan, error: null });
        } else {
            this.setState({ progress: null, step: CREATE_STEPS.demographics, error: null });
        }
    }

    onDemographicsNext() {
        const { org } = this.state;
        console.log("onDemographicsNext:", org);
        if (org.enableProviderSearch) {
            this.setState({ step: CREATE_STEPS.provider });
        } else if (org.enableActivationCode) {
            this.setState({ step: CREATE_STEPS.activationCode });
        } else {
            this.onCreateAccount();
        }
    }

    onDemographicsBack() {
        this.setState({ step: (isReactNative() ? CREATE_STEPS.scan : CREATE_STEPS.account), error: null });
    }

    onProviderNext() {
        const { org } = this.state;
        if (org.enableActivationCode) {
            this.setState({ step: CREATE_STEPS.activationCode });
        } else {
            this.onCreateAccount();
        }
    }

    onProviderBack() {
        this.setState({ step: CREATE_STEPS.demographics, error: null });
    }

    onActivationCodeNext() {
        this.onCreateAccount();
    }

    onActivationCodeBack() {
        const { org } = this.state;
        if (org.enableProviderSearch) {
            this.setState({ step: CREATE_STEPS.provider, error: null });
        } else {
            this.setState({ step: CREATE_STEPS.demographics, error: null });
        }
    }

    getView() {
        const self = this;
        let { org, step, error, progress, email, phone, validationCode, registrationCode, activationCode, employeeId, race, postalCode, country, notes,
            firstName, lastName, ethnicity, gender, dob, password, confirmPassword, dialog, iframe, disableEmail, disablePhone, orgFields, tempPassword } = this.state;

        let { appContext } = this.props;
        const orgConfig = _.get(org, "config") || {};
        const disableRegisterRace = toBoolean(_.get(orgConfig, "disableRegisterRace", "false"));
        const disableRegisterGender = toBoolean(_.get(orgConfig, "disableRegisterGender", "false"));
        const cancelButton = iframe ? null : <Button id="registerCancel" color="primary" variant="contained" onClick={this.props.onCancel}><T.span text='cancel' /></Button>;

        switch (step) {
            default:
                console.error(`Invalid step:`, step);
                break;
            case CREATE_STEPS.account:
                {
                    const passwordErrors = password && validPassword(password);
                    const passwordErrorsDiv = passwordErrors ? <div style={{ textAlign: 'center', maxWidth: 200, marginTop: 10, color: 'red', fontStyle: 'italic' }}>
                        <div style={{ textAlign: 'left' }}>
                            <T.span text='passwordError' /><br /><br />
                            <div dangerouslySetInnerHTML={{ __html: passwordErrors }} />
                        </div>
                    </div> : null;
                    const enableRegistrationCode = org ? (org.enableRegistrationCode || org.requireRegistrationCode) || false : true;          // if we don't know the org, then always enable the registration code
                    const enableEmployeeId = org ? org.enableRoster || false : false;
                    const invalidEmail = !this.validEmail(email);
                    const invalidPassword = password !== confirmPassword || passwordErrors.length > 0;
                    const invalidEmployeeId = enableEmployeeId && employeeId.length < 1;
                    const invalidRegistrationCode = (enableRegistrationCode && org.requireRegistrationCode) ? registrationCode.length < 1 : false;
                    const nextDisabled = !password || invalidEmail || invalidPassword || invalidEmployeeId || invalidRegistrationCode;
                    //console.log(`nextDisabled: ${nextDisabled}, invalidEmail: ${invalidEmail}, invalidPassword: ${invalidPassword}, invalidPhone: ${invalidPhone}, invalidCode: ${invalidCode}`);
                    const nextButton = progress ? progress : <Button style={styles.button}
                        variant='contained' color='primary' disabled={nextDisabled} onClick={this.onVerifyAccount.bind(this)}><T.span text="next" /></Button>;

                    return <React.Fragment>
                        <DialogContent style={styles.content}>
                            <div align='center'>
                                {/*<T.p text='createAccount' />*/}
                                <TextField id="registerEmail" autoFocus={true} autoComplete="email" error={invalidEmail} type='email' fullWidth style={styles.text} label={<T.span text='email' />}
                                    value={email} disabled={disableEmail} onChange={this.onChange.bind(this, 'email')} />
                                <br />
                                {!tempPassword && <div><TextField id="registerPassword" autoComplete="new-password" type='password' error={passwordErrors.length > 0} fullWidth style={styles.text} label={<T.span text='password' />}
                                    value={password} onChange={this.onChange.bind(this, 'password')} />
                                <br />
                                <TextField id="registerConfirmPassword" autoComplete="new-password" type='password' error={invalidPassword} fullWidth style={styles.text} label={<T.span text='confirmPassword' />}
                                    value={confirmPassword} onChange={this.onChange.bind(this, 'confirmPassword')} />
                                <br /></div>}
                                {enableRegistrationCode ? <div><TextField id="registrationCode" autoComplete="one-time-code" fullWidth style={styles.text} label={<T.span text='registrationCode' />} error={invalidRegistrationCode}
                                    value={registrationCode} onChange={this.onChange.bind(this, 'registrationCode')} /><br /></div> : null}
                                {enableEmployeeId ? <div><TextField id="registerEmployeeId" autoComplete="one-time-code" fullWidth style={styles.text} error={invalidEmployeeId} label={<T.span text='employeeId' />}
                                    value={employeeId} onChange={this.onChange.bind(this, 'employeeId')} /><br /></div> : null}
                                {!tempPassword && passwordErrorsDiv}
                                <span style={styles.error}>{error}</span>
                                {dialog}
                            </div>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'flex-end' }}>
                            {cancelButton}
                            {nextButton}
                        </DialogActions>
                    </React.Fragment>;
                }
            case CREATE_STEPS.verifyEmail:
                {
                    const invalidVerificationCode = validationCode.length !== 6;
                    const backButton = <Button id="backButton" color="primary" variant="contained" onClick={() => {
                        this.stopEmailVerificationTimer();
                        this.setState({ step: CREATE_STEPS.account, error: null });
                    }}><T.span text='back' /></Button>
                    const nextButton = progress ? progress : <Button style={styles.button}
                        variant='contained' color='primary' disabled={invalidVerificationCode} onClick={this.onValidateEmail.bind(this)}><T.span text="next" /></Button>;

                    return <React.Fragment>
                        <DialogContent style={styles.content}>
                            <div align='center'>
                                <T.p text={{ key: 'verifyEmail', email: this.state.email }} />
                                <TextField id='validationCode' autoFocus={true} autoComplete="one-time-code" fullWidth style={styles.text} label={<T.span text='validationCode' />} type='number'
                                    value={validationCode} onChange={this.onChange.bind(this, 'validationCode')} />
                                <br />
                                <span style={styles.error}>{error}</span>
                                {dialog}
                            </div>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'flex-end' }}>
                            {backButton}
                            {nextButton}
                        </DialogActions>
                    </React.Fragment>;
                }
            case CREATE_STEPS.scan:
                {
                    const scanButton = <Button
                        style={styles.button}
                        variant='contained' color='primary'
                        onClick={() => {
                            appContext.postMessage({ action: 'onScanBarcode' });
                            //this.setState({ step: CREATE_STEPS.demographics });
                        }}><T.span text="scanId" /></Button>;
                    const nextButton = <Button
                        style={styles.button}
                        variant='contained' color='primary'
                        onClick={() => {
                            this.setState({ step: CREATE_STEPS.demographics })
                        }}><T.span text='next' /></Button>;

                    return <React.Fragment>
                        <DialogContent style={styles.content}>
                            <div align='center'>
                                <T.span text="scanLicense" /><br /><br />
                                {scanButton}
                            </div>
                            {dialog}
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'flex-end' }}>
                            {cancelButton}
                            {nextButton}
                        </DialogActions>
                    </React.Fragment>
                }
            case CREATE_STEPS.demographics:
                {
                    if (! Array.isArray(org.orgFields) ) org.orgFields = [];
                    //let invalidPhone = !this.validPhoneNumber(phone);
                    let nextDisabled = !email || !firstName || !lastName || !this.validDOB(dob) || (country === 'US' ? postalCode.length !== 5 : false)
                        || (!disableRegisterGender && !gender)
                        || org.orgFields.reduce((p,f) => {
                            if (f.required) {
                                if (orgFields[f.id] === undefined) orgFields[f.id] = '';
                                if ( !orgFields[f.id] || (f.minLength > 0 && orgFields[f.id].length < f.minLength) || (f.maxLength > 0 && orgFields[f.id].length > f.maxLength) ) {
                                    p |= true;
                                }
                            }                            
                            return p;
                        }, false);
                    let nextButton = progress ? progress : <Button id="register-next2" style={styles.button}
                        variant='contained' color='primary' disabled={nextDisabled} onClick={this.onDemographicsNext.bind(this)}><T.span text="next" /></Button>;
                    let backButton = <Button id="registerBack" style={styles.button}
                        variant='contained' color='primary' onClick={this.onDemographicsBack.bind(this)}><T.span text='back' /></Button>;

                    return <React.Fragment>
                        <DialogContent style={styles.content}>
                            <div align='center'>
                                <T.p text='demographics' />
                                <TextField id="registerFirstName" autoComplete="given-name" fullWidth style={styles.text} label={<T.span text='firstName' />} error={firstName.length < 1} value={firstName} onChange={this.onChange.bind(this, 'firstName')} />
                                <br />
                                <TextField id="registerLasttName" autoComplete="family-name" fullWidth style={styles.text} label={<T.span text='lastName' />} error={lastName.length < 1} value={lastName} onChange={this.onChange.bind(this, 'lastName')} />
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        id="register-dob"
                                        label={T.translate('dobLabel')}
                                        format={"MM/dd/yyyy"}
                                        autoComplete="off"
                                        fullWidth
                                        style={styles.text}
                                        disableFuture
                                        error={!this.validDOB(dob)}
                                        maxDate={Moment().subtract(13, "years").toDate()}
                                        maxDateMessage={T.translate('dobMax')}
                                        minDateMessage={T.translate('dobInvalid')}
                                        invalidDateMessage={T.translate('dobInvalid')}
                                        onChange={(dob) => { self.setState({ dob }); }}
                                        animateYearScrolling={false}
                                        value={dob}
                                    />
                                </MuiPickersUtilsProvider>
                                <br />
                                {!disableRegisterGender ? <FormControl fullWidth id="register-gender" style={styles.text}>
                                    <InputLabel><T.span text='gender' /></InputLabel>
                                    <Select autoComplete='sex' value={gender} onChange={this.onChange.bind(this, 'gender')} error={!gender}>
                                        <MenuItem value={'female'}><T.span text='female' /></MenuItem>
                                        <MenuItem value={'male'}><T.span text='male' /></MenuItem>
                                    </Select>
                                </FormControl> : null}
                                {!disableRegisterGender ? <br /> : null}
                                <TextField
                                    id="postalCode"
                                    error={postalCode.length !== 5}
                                    autoComplete="postal-code"
                                    label={<T.span text="postalCode"></T.span>}
                                    value={postalCode}
                                    onChange={this.onChange.bind(this, 'postalCode')}
                                    type={country === "US" ? "number" : "text"}
                                    fullWidth
                                    style={styles.text}

                                />
                                <br />
                                <MuiPhoneNumber id="registerPhone" autoComplete="off" autoFormat={true} defaultCountry={'us'} label={<T.span text='mobile' />} fullWidth style={styles.text}
                                    value={phone} disabled={disablePhone} onChange={this.onChange.bind(this, 'phone')} />
                                <br />
                                <SelectCountry id="registerCountry" style={styles.country}
                                    value={country} onChange={this.onChange.bind(this, 'country')} />
                                <br />
                                {org.orgFields.map((f) => {
                                    if (f.required) {
                                        if (orgFields[f.id] === undefined) orgFields[f.id] = '';
                                        let error = !orgFields[f.id] || (f.minLength > 0 && orgFields[f.id].length < f.minLength) || (f.maxLength > 0 && orgFields[f.id].length > f.maxLength);

                                        return <div>
                                            <TextField
                                                id={f.id}
                                                label={f.description}
                                                value={orgFields[f.id]}
                                                error={error}
                                                onChange={(e) => {
                                                    orgFields[f.id] = e.target.value;
                                                    this.setState({ orgFields });
                                                }}
                                                fullWidth
                                                style={styles.text}
                                            />
                                        </div>;
                                    } else {
                                        return null;
                                    }
                                })}
                                <div style={{ marginTop: 15 }}>
                                    <T.span text='optional' style={{ fontStyle: 'italic' }} />
                                </div>
                                {org.orgFields.map((f) => {
                                    if (!f.required) {
                                        if (orgFields[f.id] === undefined) orgFields[f.id] = '';
                                        let error = (orgFields[f.id] && f.minLength > 0 && orgFields[f.id].length < f.minLength) || (orgFields[f.id] && f.maxLength > 0 && orgFields[f.id].length > f.maxLength);
                                        return <div>
                                            <TextField 
                                                id={f.id} 
                                                label={f.description} 
                                                value={orgFields[f.id]} 
                                                error={error}
                                                onChange={(e) => {
                                                    orgFields[f.id] = e.target.value;
                                                    this.setState({orgFields});
                                                }}
                                                fullWidth
                                                style={styles.text} 
                                            />
                                        </div>;
                                    } else {
                                        return null;
                                    }
                                })}
                                {!disableRegisterRace ? <FormControl fullWidth style={styles.text}>
                                    <SelectRace style={styles} value={race} onChange={(e) => {
                                        self.setState({ race: e })
                                    }} />
                                </FormControl> : null}
                                {!disableRegisterRace ? <br /> : null}
                                {!disableRegisterRace ? <FormControl fullWidth style={styles.text}>
                                    <SelectEthnicity style={styles} value={ethnicity} onChange={(e) => {
                                        self.setState({ ethnicity: e })
                                    }} />
                                </FormControl> : null}
                                {!disableRegisterRace ? <br /> : null}
                                <span style={styles.error}>{error}</span>
                                {dialog}
                            </div>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'flex-end' }}>
                            {backButton}
                            {nextButton}
                        </DialogActions>
                    </React.Fragment>;
                }
            case CREATE_STEPS.provider:
                {
                    const backButton = <Button id="backButton" color="primary" variant="contained" onClick={this.onProviderBack.bind(this)}><T.span text='back' /></Button>
                    const nextButton = progress ? progress : <Button style={styles.button} disabled={notes.length === 0}
                        variant='contained' color='primary' onClick={this.onProviderNext.bind(this)}><T.span text="next" /></Button>;
                    const skipButton = <Button style={styles.button}
                        variant='contained' color='primary' onClick={this.onProviderNext.bind(this)}><T.span text="skip" /></Button>;

                    return <React.Fragment>
                        <DialogContent style={styles.content}>
                            <div align='center'>
                                <T.p text={{ key: 'selectProviderInfo' }} />
                                <SearchProvider onChange={(e) => {
                                    let note = `Name: ${e.firstName} ${e.middleName ? e.middleName : ''} ${e.lastName}\n` +
                                        `Clinic: ${e.clinicName}\n` +
                                        `Address: ${e.standardizedAddressLine1}, ${e.standardizedCity}, ${e.standardizedState}, ${e.standardizedZip}\n`;
                                    if (e.directAddress)
                                        note += `Direct Email: ${e.directAddress}\n`;
                                    if (e.fax)
                                        note += `Fax: ${e.fax}\n`;
                                    if (e.phonePrimary)
                                        note += `Primary Phone: ${e.phonePrimary}\n`;
                                    if (e.npi)
                                        note += `NPI: ${e.npi}\n`;

                                    notes = [{
                                        category: 'other',
                                        note
                                    }];
                                    console.log("notes:", notes);
                                    this.setState({ notes, providerReferral: e });
                                }} />
                                <br />
                                <span style={styles.error}>{error}</span>
                                {dialog}
                            </div>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'flex-end' }}>
                            {backButton}
                            {skipButton}
                            {nextButton}
                        </DialogActions>
                    </React.Fragment>;
                }
            case CREATE_STEPS.activationCode:
                {
                    const invalidActivationCode = activationCode.length !== 6;
                    const backButton = <Button id="backButton" color="primary" variant="contained" onClick={this.onActivationCodeBack.bind(this)}><T.span text='back' /></Button>
                    const nextButton = progress ? progress : <Button style={styles.button}
                        variant='contained' color='primary' disabled={invalidActivationCode} onClick={this.onActivationCodeNext.bind(this)}><T.span text="next" /></Button>;

                    return <React.Fragment>
                        <DialogContent style={styles.content}>
                            <div align='center'>
                                <T.p text={{ key: 'enterActivationCode', name: org.name }} />
                                <TextField id="registerActivationCode" type='number' autoComplete="one-time-code" error={invalidActivationCode} fullWidth style={styles.text} label={<T.span text='activationCode' />}
                                    value={activationCode} onChange={this.onChange.bind(this, 'activationCode')} /><br />
                                <br />
                                <span style={styles.error}>{error}</span>
                                {dialog}
                            </div>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'flex-end' }}>
                            {backButton}
                            {nextButton}
                        </DialogActions>
                    </React.Fragment>;
                }
            case CREATE_STEPS.complete:
                {
                    const { androidLink, iosLink } = this.props.appContext.state;
                    const patientUrl = Config.patientClientUrl + `?u=${encodeURIComponent(this.state.email)}&p=${encodeURIComponent(this.state.password)}`

                    return <React.Fragment>
                        <DialogContent>
                            <div align='center'>
                                <T.p text='registrationCompleted' />
                                <Button variant='contained' color='primary' style={{ margin: 10 }} onClick={() => window.open(patientUrl)}><T.span text='launchClient' /></Button><br /><br />
                                {androidLink ? <Button onClick={() => window.open(androidLink.url)}><img alt='Android' style={{ margin: 10, width: 150 }} src={AndroidStoreImage} /></Button> : null}<br />
                                {iosLink ? <Button onClick={() => window.open(iosLink.url)} ><img alt='iOS' style={{ margin: 10, width: 150 }} src={iOSStoreImage} /></Button> : null}
                            </div>
                        </DialogContent>
                    </React.Fragment>
                }
        }
    }

    render() {
        const { iframe } = this.state;
        return <Dialog open={true} fullWidth={true} maxWidth='sm' scroll='paper' fullScreen={iframe}>
            <DialogTitle><T.span text="accountRegistration" /></DialogTitle>
            {this.getView()}
        </Dialog>
    }
};


const styles = {
    error: {
        textAlign: 'center',
        maxWidth: 200,
        marginTop: 10,
        color: 'red',
        fontStyle: 'italic'
    },
    content: {
        minHeight: 450
    },
    button: {
        margin: 5
    },
    text: {
        margin: 5,
        maxWidth: 350
    },
    country: {
        align: 'left',
        margin: 5,
        maxWidth: 350
    },
    nextButton: {
        margin: 5,
        maxWidth: 250,
        display: 'flex',
        justifyContent: 'flex-end'
    }
}