import React from 'react';
import {
    Button,
    Paper,
    Grid,
    Switch,
    FormControlLabel
} from '@material-ui/core/';

import T from 'i18n-react';
import _ from 'lodash';
import Moment from 'moment';
import { Link } from 'react-router-dom';

import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import { Logger } from '@apricityhealth/web-common-lib/utils/Logger';
import { toBoolean } from '@apricityhealth/web-common-lib/utils/Utils';
import Config from '@apricityhealth/web-common-lib/Config';
import ChangePassword from '@apricityhealth/web-common-lib/components/ChangePassword';
import UploadPatientImage from '@apricityhealth/web-common-lib/components/UploadPatientImage';
import PatientImage from '@apricityhealth/web-common-lib/components/PatientImage';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import Localize from '@apricityhealth/web-common-lib/components/Localize';

import DelegatesView from './DelegatesView';
import SelectContactMethod from '../dialogs/SelectContactMethod';
import ChangeAddress from '../dialogs/ChangeAddress';
import ChangeUser from '../dialogs/ChangeUser';
import DelegateHeader from '../components/headers/DelegateHeader';
import SubscriptionHeader from '../components/headers/SubscriptionHeader';
import SubscriptionView from './SubscriptionView';
import BaselineHeader from '../components/headers/BaselineHeader';
import ProfileHeader from '../components/headers/ProfileHeader';
import { ReminderDialog } from 'dialogs/ReminderDialog';

const log = new Logger();

export class ProfileView extends React.Component {
    constructor(props) {
        super(props);

        const { patient, theme } = props.appContext.state;
        const { firstName, lastName, dob, contactMethod, address, photo, patientId } = (patient || {});
        this.state = {
            dialog: null,
            language: props.appContext.state.language,
            firstName,
            lastName,
            dob,
            contactMethod: contactMethod || 'email',
            theme,
            userId: '',
            address,
            image: photo,
            patientId,
            email: '',
            mobile: '',
            baseline: null,
        };
    }

    componentDidMount() {
        const { appContext, onMount } = this.props;
        const { user } = appContext.state;

        log.debug(`componentDidMount:`, this.props, this.state, user);
        if (onMount) onMount();
        this.setState({
            userId: user.username,
            email: user.email,
            mobile: user.phone
        });

        this.getUser();
    }

    onCloseDialog(email, mobile) {
        const newState = { dialog: null }
        if (email !== undefined) newState.email = email
        if (mobile !== undefined) newState.mobile = mobile
        this.setState(newState);
        //this.componentDidMount();
    }

    onClosePasswordDialog() {
        this.setState({dialog: null});
        this.getUser();
    }

    onCloseAddress() {
        try {
            const { appContext: { state: { patient: { address } }}} = this.props;

            if (address) {
                this.setState({ dialog: null, address });
            } else {
                this.setState({ dialog: null });
            }
        } catch(err) {
            console.error("onCloseAddress error:", err );
        }
    }

    onChangeContactMethod(contactMethod) {
        if (contactMethod !== this.state.contactMethod) {
            const { appContext: { state: { patient } } } = this.props;
            this.updatePatient({ contactMethod });
            this.setState({ contactMethod });
            patient.contactMethod = contactMethod;
        }
    }

    onChangeImage() {
        this.updatePatient({ photo: this.state.image });
    }

    onChangeReminder(e) {
        const enabled = e.target.checked;
        const { appContext } = this.props;
        if ( enabled ) {
            const dialog = <ReminderDialog
                appContext={appContext}
                onSet={() => {
                    this.setState({dialog: null});
                }}
            />;
            this.setState({dialog});
        } else {
            this.setState({dialog: null});
            this.updatePatient({ reminderEnabled: false });
        }
    };

    updatePatient(update) {
        this.props.appContext.updatePatient(update)
    }

    updateUser() {
        const { appContext } = this.props;
        const { state: { idToken, user } } = appContext;
        const request = {
            url: Config.baseUrl + `${Config.pathPrefix}authentication/users/`,
            data: user,
            method: 'POST',
            headers: { "Authorization": idToken }
        };
        console.log("updateUser request", request);
        AxiosRequest(request).then((response) => {
            console.log("updateUser response:", response.data);
            if ( response.data.user )
                appContext.setState({ user: response.data.user }, this.componentDidMount.bind(this));
        }).catch((err) => {
            console.error("updateUser error:", getErrorMessage(err));
        });
    }

    getUser() {
        const { appContext: { state: { idToken, user } } } = this.props;
        const request = {
            url: Config.baseUrl + `${Config.pathPrefix}authentication/users/${user.username}`,
            method: 'GET',
            headers: { Authorization: idToken }
        };
        log.debug("getUser request:", request );
        AxiosRequest(request).then((response) => {
            log.debug("getUser response:", response.data );
            if ( response.data.user )
                this.props.appContext.setState({ user: response.data.user });
        }).catch((err) => {
            log.error("getUser error:", getErrorMessage(err));
        })
    }

    onToggleMFA(e) {
        const mfaEnabled = e.target.checked;
        const { appContext } = this.props;
        const { user } = appContext.state;;
        user.mfaEnabled = mfaEnabled;
        appContext.setState({ user });

        this.updateUser();
    }

    render() {
        const self = this;
        const { appContext } = this.props;
        const { patientId, patient, org, lastBaseline, config, user, planActive } = appContext.state;
        const {
            dialog,
            firstName,
            lastName,
            dob,
            userId,
            email,
            mobile,
            contactMethod,
            address
        } = this.state;

        const planId = patient && Array.isArray(patient.plans) ? patient.plans.map((e) => e.planId).join(',') : '';
        //const orgConfig = _.get(org, "config") || {};
        const disableDelegates = toBoolean(_.get(config, "disableDelegates", "false"));

        //log.info('reminderTime:', reminderTime);

        let zip = '';
        let country = 'US';
        if (Array.isArray(address) && address.length > 0 && address[0] && address[0].postalCode) {
            zip = address[0].postalCode;
            country = address[0].country || 'US';
        }

        function hookDiv(ref) {
            if (ref) {
                ref.addEventListener('click', (e) => {
                    if (appContext.onURL && e.target.tagName === 'A') {
                        appContext.onURL({ href: e.target.href });
                        e.preventDefault();
                    }
                });
            }
        }

        let baselineRows = [];
        let baselineDate = <T.span text="never" />;
        let baselineInProgress = false;
        if (lastBaseline && Array.isArray(lastBaseline.journal)) {
            baselineDate = Moment(lastBaseline.createDate).format('LLLL');
            baselineInProgress = !lastBaseline.journal.find((j) => j.eod);
            baselineRows = lastBaseline.journal
                .filter(({ answers }) => Array.isArray(answers) && answers.length > 0)
                .map(({ question, answers }, i) => {
                    const answerText = answers.map(({ text }) => text).join(', ');
                    return (
                        <tr key={i} style={{ background: i % 2 ? '#DDDDDD' : '#FFFFFF' }}>
                            <td valign="top" style={{ width: '60%' }}>
                                <div ref={hookDiv} dangerouslySetInnerHTML={{ __html: question }} />
                            </td>
                            <td valign="top">
                                <Localize appContext={appContext} planId={planId} text={answerText} />
                            </td>
                        </tr>
                    );
                });
        }

        return (
            <Grid id={this.props.id} container align="center" style={{ marginTop: 50, width: '100%' }}>
                <Grid item style={{ width: '90%', margin: 10, marginTop: 15, marginBottom: 15 }}>
                    <Paper id='subscription' style={{ width: '100%', textAlign: 'left', padding: 10 }}>
                        <div style={styles.div}>
                            <ProfileHeader title={this.props.label} color={this.props.color} />
                            <UploadPatientImage appContext={appContext} patientId={patientId} useImagePicker={true}
                                enableCropScale={true} scaleSize={300}
                                onChange={(image) => { self.setState({ image }, self.onChangeImage.bind(self)); }}>
                                <div>
                                    <PatientImage
                                        appContext={appContext}
                                        blobId={_.get(appContext, 'state.patient.photo') || ''}
                                    />
                                </div>
                            </UploadPatientImage>
                            <table style={{ width: '100%', margin: 5 }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <T.span text="firstName" />:
                                        </td>
                                        <td>{firstName}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <T.span text="lastName" />:
                                        </td>
                                        <td>{lastName}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <T.span text="dob" />:
                                        </td>
                                        <td>{Moment(dob).utcOffset(0).format('MM/DD/YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <T.span text="id" />:
                                        </td>
                                        <td>{userId}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <T.span text="email" />:
                                        </td>
                                        <td>{email}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <T.span text="mobile" />:
                                        </td>
                                        <td>{mobile}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <T.span text="country" />:
                                        </td>
                                        <td>{country}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <T.span text="postalCode" />:
                                        </td>
                                        <td>{zip}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <SelectContactMethod
                                contactMethod={contactMethod}
                                onChange={self.onChangeContactMethod.bind(self)}
                            />
                            <br />
                            <Button
                                id="changePassword"
                                color="primary"
                                variant="contained"
                                style={styles.button}
                                onClick={() => {
                                    self.setState({
                                        dialog: (
                                            <ChangePassword
                                                appContext={appContext}
                                                username={appContext.state.username}
                                                oldPassword={appContext.state.user.tempPassword}
                                                onDone={self.onClosePasswordDialog.bind(self)}
                                            />
                                        ),
                                    });
                                }}
                            >
                                <T.span text="changePassword" />
                            </Button>
                            <Button
                                id="changeEmailMobile"
                                color="primary"
                                variant="contained"
                                style={styles.button}
                                onClick={() => {
                                    this.setState({
                                        dialog: (
                                            <ChangeUser
                                                appContext={appContext}
                                                userId={userId}
                                                email={email}
                                                mobile={mobile}
                                                onDone={this.onCloseDialog.bind(this)}
                                            />
                                        ),
                                    });
                                }}
                            >
                                <T.span text="updateUser" />
                            </Button>
                            <Button
                                id="changeAddress"
                                color="primary"
                                variant="contained"
                                style={styles.button}
                                onClick={() => {
                                    self.setState({
                                        dialog: (
                                            <ChangeAddress
                                                appContext={appContext}
                                                address={address}
                                                onDone={self.onCloseAddress.bind(self)}
                                            />
                                        ),
                                    });
                                }}
                            >
                                <T.span text="changeAddress" />
                            </Button>
                            <FormControlLabel style={styles.button} control={<Switch checked={toBoolean(user.mfaEnabled)}
                                onChange={this.onToggleMFA.bind(this)} />}
                                label={<T.span text='enableMFA' />} />
                            <FormControlLabel style={styles.button} control={<Switch checked={toBoolean(patient.reminderEnabled)}
                                onChange={this.onChangeReminder.bind(this)} />}
                                label={<T.span text='enableCheckInReminder' />} />
                            {patient.reminderEnabled ? <div align='center'>
                                <Paper style={{margin: 5, padding: 15, width: '90%', textAlign: 'center', fontSize: 15, backgroundColor: '#CCE5FF'}}>
                                    <b>{patient.reminderInterval === 1440 ? <T.span text='daily' /> : Moment(patient.reminderTime).format('dddd')} <T.span text='at' /> {Moment(patient.reminderTime).format("h:mm A")}</b>
                                </Paper>
                                </div> : null }
                            {dialog}
                        </div>
                    </Paper>
                </Grid>
                <Grid item style={{ width: '90%', margin: 10, marginTop: 15, marginBottom: 15 }}>
                    {org.billingType === 'square' ? <Paper id='subscription' style={{ width: '100%', textAlign: 'left', padding: 10 }}>
                        <Grid>
                            <SubscriptionHeader title={<T.span text="subscription" />} color={this.props.color} />
                            <SubscriptionView appContext={appContext} />
                        </Grid>
                    </Paper> : null}
                </Grid>
                <Grid item style={{ width: '90%', margin: 10, marginTop: 15, marginBottom: 15 }}>
                    {!disableDelegates ? <Paper id="delegates" style={{ width: '100%', textAlign: 'left', padding: 10 }}>
                        <Grid>
                            <DelegateHeader title={<T.span text="delegates" />} color={this.props.color} />
                            <DelegatesView appContext={appContext} />
                        </Grid>
                    </Paper> : null}
                </Grid>

                <Grid item style={{ width: '90%', margin: 10, marginTop: 15, marginBottom: 15 }}>
                    <Paper id="lastBaseline" style={{ width: '100%', textAlign: 'left', padding: 10 }}>
                        <Grid>
                            <BaselineHeader title={<T.span text="baseline" />} color={this.props.color} />
                            <div>
                                <T.span text={{ key: 'lastBaseline', createDate: baselineDate }} />
                            </div>
                            <table style={{ margin: '20px 0', width: '100%' }}>
                                <tbody>{baselineRows}</tbody>
                            </table>

                            <div align="left" style={{ padding: '10px 0' }}>
                                {baselineInProgress ? (
                                    <Button
                                        id="submitNewBaseline"
                                        disabled={!planActive}
                                        color="primary"
                                        variant="contained"
                                        component={Link}
                                        to="/baseline"
                                    >
                                        <T.span text="continueExistingBaseline" />
                                    </Button>
                                ) : (
                                    <Button
                                        id="submitNewBaseline"
                                        disabled={!planActive}
                                        color="primary"
                                        variant="contained"
                                        component={Link}
                                        to="/baseline"
                                    >
                                        <T.span text="submitNewBaseline" />
                                    </Button>
                                )}
                            </div>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

const styles = {
    text: {
        margin: 5,
        width: 250,
    },
    div: {
        margin: 5,
        fontSize: '12px',
        wordBreak: 'break-word',
        textAlign: 'left',
    },
    button: {
        margin: 5,
    }
};

export default ProfileView;
