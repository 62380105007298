import React, { Component } from 'react';
import {
    CircularProgress,
    Grid,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    Avatar
} from '@material-ui/core/';

import { Redirect } from 'react-router-dom';

import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import { Logger } from '@apricityhealth/web-common-lib'
import Video, { connect } from 'twilio-video';

import T from 'i18n-react';
import Moment from 'moment';

import AppointmentsIcon from '@material-ui/icons/Today';
import RequestAppointmentDialog from '../dialogs/RequestAppointmentDialog'

const log = new Logger();

export class VideoChatView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            getContent: false,
            appointmentIn: null,
            appointmentTime: null,
            providerName: null,
            canVideoChat: false,
            localTrack: null,
            previewTracks: null,
            progress: null,
            remoteProgress: null,
            localProgress: null,
            connecting: false,
            connected: false,
            remoteConnected: false,
            message: '',
            disabled: false,
            disabledMessage: null,
            dialog: null,
            isAudioEnabled: true,
            unconfirmedAppointments: [],
            nextAppointmentIsUnconfirmed: false
        };

        this.localMediaContainer = React.createRef();
        this.remoteMediaContainer = React.createRef();

        if (!Video.isSupported && !window.ReactNativeWebView) {
            console.log("Video is not supported, disabled view!");
            this.state.disabled = true;
            this.state.disabledMessage = <div className='notSupported' align='center'><T.span text='videoNotSupported' /></div>
        }
    }

    componentDidMount() {
        this.props.appContext.registerMessageHandler(this.onMessage.bind(this));
        this.contentTimer = setInterval(this.getContent.bind(this), 30 * 1000);
        this.getContent();
    }

    componentWillUnmount() {
        this.props.appContext.unregisterMessageHandler(this.onMessage.bind(this));
        clearInterval(this.contentTimer);
        this.disconnectRoom();
    }

    onMessage(msg) {
        try {
            if ( typeof msg.data === 'string' ) {
                log.debug("onMessage:", msg );
                let data = JSON.parse(msg.data);
                if ( this[data.action] !== undefined )
                    this[data.action]( data );
            }
        }
        catch(err) {
            log.warning("onMessage error:", err );
        }
    }

    onSetState(data) {
        this.setState(data.state);
    }

    getContent() {
        const self = this;
        let { disabled } = this.state;
        if ( disabled ) {
            return
        }

        this.setState({ progress: <CircularProgress /> });
        Promise.all([this.getAppointments(), this.getPatientProviders(), this.getRooms()]).then(([appointments, providers, rooms]) => {
            // look for next or active appointment
            let nextAppointment = null;         // our next appointment

            // get unconfirmed
            let unconfirmedAppointments = []

            if (Array.isArray(appointments)) {
                for (let i = 0; i < appointments.length; ++i) {
                    let appt = appointments[i];
                    if (Moment(appt.end) > Moment() && appt.status === 'requested') {
                        unconfirmedAppointments.push(appt)
                    }
                    if (!nextAppointment || Moment(appt.start) < Moment(nextAppointment.start))
                        nextAppointment = appt;
                }
            }
            self.setState({unconfirmedAppointments})

            console.log("nextAppointment:", nextAppointment);
            let canVideoChat = false;
            if (nextAppointment) {
                let providerRecord = providers.find((e) => e.provider.providerId === nextAppointment.providerId);
                let providerName = nextAppointment.providerId;
                if (providerRecord && providerRecord.provider) {
                    let provider = providerRecord.provider;
                    providerName = `${provider.firstName} ${provider.lastName}`;
                    if (provider.title)
                        providerName = `${provider.title} ` + providerName;
                }

                let now = Moment();                                             // 1585176285664
                let days = Moment(nextAppointment.start).diff(now, 'days');
                let hours = Moment(nextAppointment.start).diff(now, 'hours');
                let minutes = Moment(nextAppointment.start).diff(now, 'minutes');
                if (hours > 0)
                    minutes = minutes % 60;

                let apptUpcoming = now > Moment(nextAppointment.start);
                let apptNow = now >= Moment(nextAppointment.start);
                let apptDone = now > nextAppointment.end;

                canVideoChat = nextAppointment.status === 'confirmed' && apptUpcoming && !apptDone && !disabled;
                console.log(`canVideoChat: ${canVideoChat}, apptNow: ${apptNow}, apptUpcoming: ${apptUpcoming}, apptDone: ${apptDone}`);

                let appointmentIn = days > 0 ? <T.span text={{ key: 'days', days }} /> :
                    hours > 0 ? <T.span text={{ key: 'hours', hours, minutes }} /> :
                        <T.span text={{ key: 'minutes', minutes }} />;
                let appointmentTime = Moment(nextAppointment.start).local().format('MMMM Do YYYY, h:mm a');
                
                let nextAppointmentIsUnconfirmed = nextAppointment.status === 'requested'
                self.setState({
                    getContent: true,
                    appointment: nextAppointment,
                    apptNow, apptDone,
                    appointmentIn, appointmentTime,
                    providerName,
                    nextAppointmentIsUnconfirmed
                });
            }

            if (canVideoChat || (Array.isArray(rooms) && rooms.length > 0)) {
                const { connected, connecting } = self.state;
                if (!connected && !connecting)
                    self.connectToRoom();
                else
                    self.setState({ progress: null });
            }
            else {
                self.setState({ getContent: true, progress: null })
            }
        })
    }

    getPatientProviders() {
        const { appContext } = this.props
        const { patientId, idToken } = appContext.state;

        return new Promise((resolve, reject) => {
            const getPatientProviders = {
                url: Config.baseUrl + `${Config.pathPrefix}providers/patient/${patientId}?includeOrg=true&inactive=false`,
                method: 'GET',
                headers: { "Authorization": idToken }
            };

            console.log("getPatientProviders:", getPatientProviders);
            AxiosRequest(getPatientProviders).then((result) => {
                let providers = result.data.providers;
                console.log("getPatientProviders result", providers);
                resolve(providers);
            }).catch((err) => {
                console.log("getPatientProviders error:", err);
                reject(err);
            })
        });
    }

    getAppointments() {
        const { appContext } = this.props;
        const { patientId, idToken } = appContext.state;

        return new Promise((resolve, reject) => {
            let currentTime = Moment().toISOString()
            const getAppointments = {
                url: Config.baseUrl + `${Config.pathPrefix}orgs/*/appointments?patientId=${patientId}&endAfter=${currentTime}`,
                method: 'GET',
                headers: { "Authorization": idToken }
            }
            AxiosRequest(getAppointments).then((result) => {
                let appointments = result.data.appointments.filter((e) => e.status !== 'rejected' && e.category === 'teleMedicine');
                console.log("getAppointments result:", appointments);
                resolve(appointments);
            }).catch((err) => {
                console.error("getAppointments error:", err);
                reject(err);
            })
        })
    }

    getRooms() {
        const { appContext } = this.props;
        const { patientId, idToken } = appContext.state;

        return new Promise((resolve, reject) => {
            let listRooms = {
                url: Config.baseUrl + `${Config.pathPrefix}messaging/listRooms?roomName=${patientId}`,
                method: 'GET',
                headers: { "Authorization": idToken },
            }

            AxiosRequest(listRooms).then((response) => {
                let rooms = response.data;
                console.log("listRooms result:", rooms);
                resolve(rooms);
            }).catch((err) => {
                console.error("listRooms error:", err);
                reject(err);
            });
        })
    }

    trackSubscribed(div, track) {
        console.log("trackSubscribed:", track, div);
        div.appendChild(track.attach());
    }

    trackUnsubscribed(track) {
        console.log("trackUnsubscribed:", track);
        track.detach().forEach(element => element.remove());
    }

    participantConnected(participant, div) {
        const self = this;
        console.log(`Participant ${participant.identity} connected:`, participant);

        div.id = participant.sid;       // set the ID so participantDisconnected() can find the element so we can detach the tracks

        participant.on('trackSubscribed', (track) => { self.trackSubscribed(div, track) });
        participant.on('trackUnsubscribed', (track) => { self.trackUnsubscribed(track) });

        participant.tracks.forEach(publication => {
            console.log("publication:", publication);
            if (publication.track) {
                self.trackSubscribed(div, publication.track);
            }
        });
    }

    participantDisconnected(participant) {
        console.log('Participant "%s" disconnected', participant.identity);
        let element = document.getElementById(participant.sid);
        if (element) element.innerHTML = '';
    }

    connectToRoom() {
        let self = this;
        let { appContext } = this.props;
        const { appointment } = this.state;
        const { patientId } = appContext.state;

        let roomName = appContext.state.patientId;
        let url = Config.baseUrl + `${Config.pathPrefix}messaging/getRoomToken?roomName=${roomName}`;
        let request = {
            url: url,
            method: 'GET',
            headers: { "Authorization": appContext.state.idToken },
        }

        this.setState({
            connecting: true, message: <T.span text='connectingToRoom' />,
            progress: <CircularProgress size={30} />
        });
        console.log("get room token Request:", request);
        AxiosRequest(request).then((response) => {
            console.log("get room token response:", response);
            let token = response.data;

            let config = {
                audio: true,
                name: roomName,
                video: { width: 640 },
                //logLevel: 'debug'
            };

            if ( window.ReactNativeWebView ) {
                appContext.postMessage({ action: "onConnectToRoom", token, roomName } );
                self.setState({ progress: null });
            }
            else {
                console.log(`Connecting to room ${roomName}:`, token);
                connect(token, config).then(room => {
                    console.log(`Connected to room:`, room)
                    self.setState({ room, progress: null, connecting: false, connected: true, message: <T.span text="waitingForProvider" /> });

                    room.participants.forEach((participant) => {
                        self.participantConnected(participant, self.remoteMediaContainer.current)
                        self.setState({ remoteConnected: true, message: '' });
                    });
                    room.on('participantConnected', (participant) => {
                        self.participantConnected(participant, self.remoteMediaContainer.current)
                        self.setState({ remoteConnected: true, message: '' });
                    })
                    room.on('participantDisconnected', (participant) => {
                        self.participantDisconnected(participant);
                        self.setState({ remoteConnected: false, message: <T.span text="waitingForProvider" /> });
                    });
                    room.once('disconnected', (error) => {
                        room.participants.forEach(self.participantDisconnected.bind(self))
                        self.setState({ connected: false, remoteConnected: false, connecting: false });
                    });

                    // Attach LocalParticipant's Tracks, if not already attached.
                    let previewContainer = self.localMediaContainer.current
                    if (previewContainer && !previewContainer.querySelector('video')) {
                        this.participantConnected(room.localParticipant, previewContainer);
                    }

                    log.metric(
                        { EventName: "AppointmentConnect" },
                        { AppointmentId: appointment.appointmentId },
                        { PatientId: patientId }
                    );
                }, error => {
                    console.error(`Error connecting to a room `, error)
                    self.setState({ progress: error.message, remoteProgress: null, localProgress: null, connecting: false, connected: false });
                    self.disconnectRoom();
                });
            }
        }).catch((error) => {
            self.setState({ progress: error.message });
        });
    }

    disconnectRoom() {
        let { room } = this.state;
        console.log(`Disconnecting:`, room);
        if (room && room.state === 'connected') {
            // Detach the local media elements
            room.localParticipant.tracks.forEach(publication => {
                const attachedElements = publication.track.detach();
                attachedElements.forEach(element => element.remove());
            });
            console.log(`Disconnecting room `)
            room.disconnect();

            this.onRoomDisconnected();
        }
    }

    toggleAudioEnabled() {
        let { room, isAudioEnabled } = this.state;
        isAudioEnabled = !isAudioEnabled;
        if ( isAudioEnabled ) {
            room.localParticipant.audioTracks.forEach((publication) => { 
                publication.track.enable(); 
            });        
        }
        else {
            room.localParticipant.audioTracks.forEach((publication) => { 
                publication.track.disable(); 
            });        
        }
        this.setState( { isAudioEnabled } );
    }

    onRoomDisconnected() {
        const { state: { appointment } } = this;
        log.metric(
            { EventName: "AppointmentDisconnect" },
            { AppointmentId: appointment.appointmentId },
            { PatientId: appointment.patientId }
        );
        this.setState({
            room: null,
            connected: false,
            connecting: false,
            progress: null,
            message: ''
        });
    }

    openRequestDialog() {
        this.setState({ dialog: <RequestAppointmentDialog appContext={this.props.appContext} onDone={this.onRequestAppointmentFinish.bind(this)} onCancel={this.onCloseDialog.bind(this)} 
        icon={this.props.icon} color={this.props.color} />})
    }
    onRequestAppointmentFinish() {
        let dialog = (
            <Dialog open={true} fullWidth={true} maxWidth={'xs'} style={{background: 'rgba(0,0,0,0.6)'}}>
                <DialogContent ><T.span text="requestAppointmentConfirmed" /></DialogContent>
                <DialogActions>
                    <Button color="primary" variant="contained" onClick={this.onCloseDialog.bind(this)}><T.span text='confirm' /></Button>
                </DialogActions>
            </Dialog>
        )
        this.setState({ dialog })
    }
    onCloseDialog() {
        this.setState({ dialog: null })
    }

    render() {
        const self = this;
        const { redirect, getContent, dialog, apptNow, apptDone, appointmentIn, appointmentTime, isAudioEnabled,
            providerName, message, progress, remoteConnected, disabledMessage, connected, unconfirmedAppointments, nextAppointmentIsUnconfirmed } = this.state;
        if ( redirect ) {
            return <Redirect to={redirect} />;
        }

        if (disabledMessage) return (
            <Grid container align="center" style={{marginTop:80}}>
                <Grid item style={styles.grid}>
                        {disabledMessage}
                </Grid>
            </Grid>
        );

        let appointmentKey = appointmentTime ? (apptNow && !apptDone) ? 'appointmentNow' : 'appointment' : 'noAppointment';
        let appointment = getContent ? <T.span text={{ key: appointmentKey, providerName, appointmentTime, appointmentIn }} /> : null;
        let unconfirmedAppointment = nextAppointmentIsUnconfirmed ? <div><br/><T.span text='nextAppointmentIsUnconfirmed'/></div> : null
        let controls = connected ? <div><Button onClick={this.toggleAudioEnabled.bind(this)}>{isAudioEnabled ? <T.span text='mute' /> : <T.span text='unmute' />}</Button>
            <Button onClick={ () => { self.setState({ redirect: '/dashboard' }); }}><T.span text='disconnect' /></Button>
            <br /></div> : null;

        let icon = React.cloneElement( this.props.icon, {fontSize: 'small', style: {fill: this.props.color} } );
        return <Grid id={this.props.id} container align="center" style={{marginTop:50}}>
            <Grid item style={{width: '100%', marginTop: 15, marginBottom: 15}}>
                <Paper style={{textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 5, padding: 15}} >

                    <div style={{ marginBottom: 15, paddingBottom: 15, borderBottom: `1px solid grey` }}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item style={{ marginRight: 10 }}>
                                <Avatar style={{ width: 35, height: 35, border: `2px solid ${this.props.color}`, backgroundColor: 'white' }}>{icon}</Avatar>
                            </Grid>
                            <Grid item >
                                <span style={{ fontSize: 22, fontColor: '#666666' }}>{this.props.label}</span>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container>
                        <Grid item style={styles.grid}>
                                <div className='video-container' align='center'>
                                    <div className='remoteVideo' ref={this.remoteMediaContainer} />
                                    <div className={remoteConnected ? 'localVideo' : 'remoteVideo'} ref={this.localMediaContainer} />
                                </div>
                                {controls}
                                {appointment}
                                {unconfirmedAppointment}
                            <br />
                        </Grid>
                    </Grid>
                    <div style={styles.grid}>
                        {message}
                        {progress}
                    </div>
                    {unconfirmedAppointments && unconfirmedAppointments.length > 0  && <div style={{textAlign:'center'}}><br/>
                        <T.span text="youHave" /> {unconfirmedAppointments.length} <T.span text="unconfirmedAppointments"/><br/><br/>
                        <Button variant="outlined" onClick={ () => { self.setState({ redirect: '/appointments' }); }} ><AppointmentsIcon /><T.span text="viewAppointments" /></Button>
                    </div>}
                </Paper>
                        {dialog}
            </Grid>
        </Grid>;
    }
}

const styles = {
    grid: {
        margin: 5,
        width: '95%'
    },
    newAppointmentHeader: {
        display: 'flex',
        justifyContent: 'center',
        margin: 20
    },
    paper: {
        padding: 10,
        margin: 5,
        width: '95%',
        textAlign: 'left'
    },
    // cameraPaper: {
    //     padding: 10,
    //     margin: 5,
    //     width: '95%',
    //     textAlign: 'center'
    // },
    div: {
        margin: 10
    },
    button: {
        margin: 10
    },
    table: {
        "width": "100%",
        "margin": 10
    },
    td: {
        "textAlign": "right"
    },
    textField: {
        "width": "100%"
    },
    notSupported: {
        marginTop: 10
    }
};

export default VideoChatView;
