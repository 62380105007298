import React from 'react';
import {
    Paper,
    Grid,
    FormControlLabel,
    Switch,
    Avatar,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@material-ui/core/';

import Config from '@apricityhealth/web-common-lib/Config';
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import T from 'i18n-react';
import SelectLanguage from '@apricityhealth/web-common-lib/components/SelectLanguage';
import ContentSimpleView from './ContentSimpleView';

export class SettingsView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            language: props.appContext.state.language,
            temperatureUnit: props.appContext.state.temperatureUnit,
            patient: props.appContext.state.patient,
            user: props.appContext.state.user,
            installInstructions: [],
            progress: null
        }
    }


    componentDidMount() {
        this.getInstructions();
    }

    getInstructions() {
        const self = this;
        const { patient } = self.state;
        let planIds = patient.plans ? patient.plans.map(p => p.planId) : [];
        if (planIds.length > 0) {
            let queryParams = [
                "textId=MobilePWAInstallInstructions",
                `language=${self.state.language}`,
                "planId=" + planIds.join(',')
            ];
            let getInstructions = {
                url: Config.baseUrl + `${Config.pathPrefix}content/text?` + queryParams.join('&'),
                method: 'GET',
                headers: { "Authorization": self.props.appContext.state.idToken },
            }
            console.log("getInstallInstructions:", getInstructions);
            AxiosRequest(getInstructions).then((response) => {
                console.log("getInstallInstructions result:", response.data.text);
                self.setState({ installInstructions: response.data.text });
            }).catch((err) => {
                console.error("getInstallInstructions error:", err);
            })
        }
    }

    onChangeContactTracing(e) {
        const { appContext } = this.props;
        const { username, refreshToken, config } = appContext.state;
        const { minDistance } = config;
        const { patientId } = this.state.patient;

        let contactTracingEnabled = e.target.checked;
        if (contactTracingEnabled) {
            let message = {
                action: 'onEnableLocationTracking',
                enabled: true,
                patientId,
                username,
                refreshToken,
                minDistance
            };
            appContext.postMessage(message);
        }
        else {
            appContext.postMessage({ action: 'onEnableLocationTracking', enabled: false });
        }
        appContext.setState({ contactTracingEnabled });
    }

    onChangeBiometricLogin(e) {
        const { appContext } = this.props;
        let biometricLoginEnabled = e.target.checked;
        appContext.postMessage({ action: 'onEnableBiometricLogin', enabled: biometricLoginEnabled });
        appContext.setState({ biometricLoginEnabled });
    }

    render() {
        const self = this;
        const { appContext } = this.props;
        const { config, biometricLoginEnabled, contactTracingEnabled, isReactNative } = appContext.state;
        const { dialog, progress } = this.state;

        let installationInstructionsGridItem = !isReactNative
            && Array.isArray(this.state.installInstructions)
            && this.state.installInstructions.length > 0 ?
            <Grid item style={{ width: '100%', marginTop: 15, marginBottom: 15 }}>
                <Paper style={{ marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 5, padding: 15 }} >
                    <ContentSimpleView appContext={appContext} content={self.state.installInstructions} />
                </Paper>
            </Grid> : null;

        let enableBiometricLogin = isReactNative ?
            <FormControlLabel control={<Switch checked={biometricLoginEnabled} onChange={this.onChangeBiometricLogin.bind(this)} />}
                label={<T.span text='enableBiometricLogin' />} /> : null;
        let enableContactTracing = config.enableContactTracing && isReactNative ?
            <FormControlLabel control={<Switch checked={contactTracingEnabled} onChange={this.onChangeContactTracing.bind(this)} />}
                label={<T.span text='enableContactTracing' />} /> : null;
            
        let icon = React.cloneElement(this.props.icon, { fontSize: 'small', style: { fill: this.props.color } });
        return (<Grid id={this.props.id} container align="center" style={{ marginTop: 50 }}>
            <Grid item style={{ width: '100%', marginTop: 15, marginBottom: 15 }}>
                <Paper style={{ textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 5, padding: 15 }} >
                    <div style={styles.div}>
                        <div style={{ marginBottom: 15, paddingBottom: 15, borderBottom: `1px solid grey` }}>
                            <Grid container direction="row" alignItems="center">
                                <Grid item style={{ marginRight: 10 }}>
                                    <Avatar style={{ width: 35, height: 35, border: `2px solid ${this.props.color}`, backgroundColor: 'white' }}>{icon}</Avatar>
                                </Grid>
                                <Grid item >
                                    <span style={{ fontSize: 22, fontColor: '#666666' }}>{this.props.label}</span>
                                </Grid>
                            </Grid>
                        </div>
                        <span><SelectLanguage style={{ margin: 5, width: 150 }} appContext={appContext} language={self.state.language} languages={config.languages}
                            onChange={(newLanguage) => {
                                if (self.state.language !== newLanguage) {
                                    self.setState({ language: newLanguage }, this.getInstructions);
                                    appContext.onSetLanguage(newLanguage);
                                }
                            }} />
                        <br /></span>
                        <FormControl style={{ margin: 5, width: 150 }}>
                            <InputLabel><T.span text='temperatureUnit' /></InputLabel>
                            <Select id="temperatureUnit" style={{ marginTop: 15 }} value={self.state.temperatureUnit} onChange={(e) => {
                                let temperatureUnit = e.target.value;
                                if (self.state.temperatureUnit !== temperatureUnit) {
                                    self.setState({ temperatureUnit: e.target.value });
                                    appContext.onSetTemperatureUnit(e.target.value);
                                }
                            }}>
                                <MenuItem value='F'>F</MenuItem>
                                <MenuItem value='C'>C</MenuItem>
                            </Select>
                        </FormControl>
                        <br />
                        {enableBiometricLogin}
                        <br />
                        {enableContactTracing}
                        <br />
                        {progress}
                    </div >
                </Paper>
                {installationInstructionsGridItem}
                {dialog}
            </Grid>
        </Grid>
        );
    }
}


const styles = {
    text: {
        margin: 5,
        width: 250
    },
    div: {
        fontSize: '12px',
        wordBreak: 'break-word',
        margin: 10,
        textAlign: 'left'
    },
    button: {
        margin: 5
    },
    table: {
        "width": "100%",
        "margin": 10
    },
    td: {
        "textAlign": "right"
    },
};

export default SettingsView;
