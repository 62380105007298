import React from "react";
import moment from 'moment-timezone';
import {
    FormControl,
    InputLabel,
    Select,
    ListSubheader,
    MenuItem
} from '@material-ui/core';

import T from 'i18n-react';

import { Logger } from '@apricityhealth/web-common-lib';
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import { isArrayValid } from "@apricityhealth/web-common-lib/utils/Services";

const log = new Logger();

export class CallbackSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || {},
            limit: props.limit || 24,
            appointmentTimes: null
        }
    }

    componentDidMount() {
        this.getAppointmentTimes();
    }

    getHours() {
        const { appContext } = this.props;
        const { org } = appContext.state;

        const officeHours = org.hours || [];
        const orgTimeZone = org.timeZone || 'America/Chicago';

        const days = new Array(7).fill().map((i, index) => {
            const hours = officeHours
                .filter(group => group.days && group.hours && index >= group.days.start && index <= group.days.end)
                .map(group => ({ start: group.hours.start / 60, end: group.hours.end / 60, startMin: group.hours.start % 60, endMin: group.hours.end % 60 })) //const ranges = [{ start: 7, end: 9 }, { start: 17, end: 23 }]
                .map(range => {
                    const convert = (h) => moment().tz(orgTimeZone).hour(h).tz(moment.tz.guess()).hour();
                    const currentDay = moment().day();
                    const convertedStartDay = moment().tz(orgTimeZone).hour(range.start).tz(moment.tz.guess()).day();
                    const convertedEndDay = moment().tz(orgTimeZone).hour(range.end).tz(moment.tz.guess()).day();
                    const isStartNewDay = (convertedStartDay > currentDay) || (convertedStartDay !== currentDay && convertedStartDay === 0);
                    const isEndNewDay = (convertedEndDay > currentDay) || (convertedEndDay !== currentDay && convertedEndDay === 0);
                    return {
                        start: isStartNewDay ? convert(range.start) + 24 : convert(range.start),
                        end: isEndNewDay ? convert(range.end) + 24 : convert(range.end),
                        startMin: range.startMin,
                        endMin: range.endMin
                    }
                });
            const items = hours.reduce((prev, next) => {
                let array = [];
                let i = next.start;
                while (i < next.end) {
                    array.push({
                        start: i,
                        end: i + 1,
                        startMin: i === next.start && next.startMin > 0 ? next.startMin : 0,
                        endMin: 0
                    });
                    if (i === (next.end - 1) && next.endMin > 0) array.push({
                        start: i + 1,
                        end: i + 1,
                        startMin: 0,
                        endMin: next.endMin
                    });
                    i++;
                }
                return [...prev, ...array];
            }, []).map(hour => {
                const currentDay = moment().day();
                const date = index === currentDay ? moment() : index > currentDay ? moment().day(index) : moment().day(index + 7);
                return {
                    start: moment(date).startOf('day').hour(hour.start).minute(hour.startMin).toISOString(),
                    end: moment(date).startOf('day').hour(hour.end).minute(hour.endMin).toISOString()
                };
            }).sort((a, b) => {
                if (new Date(a.start) < new Date(b.start)) return -1;
                if (new Date(a.start) > new Date(b.start)) return 1;
                return 0;
            });
            const currentDay = moment().day();
            return {
                day: index,
                hours: index === currentDay ? items.filter(item => moment(item.start).hour() > moment().hour()) : items
            }
        }).filter(day => day.hours && day.hours.length > 0);
        return days;
    }

    getAppointmentTimes() {
        const { appContext } = this.props;
        const { idToken, org, patientId } = appContext.state;
        let { value, limit } = this.state;

        if (org.enableAppointments) {
            const request = {
                url: Config.baseUrl + `${Config.pathPrefix}orgs/${org.orgId}/appointmentTimes?limit=${limit}&category=phone&ignoreAppointments=true&patientId=${patientId}`,
                method: 'GET',
                headers: { Authorization: idToken },
            };
            log.debug("getAppointmentTimes request", request);
            AxiosRequest(request).then((response) => {
                log.debug("getAppointmentTimes response:", response);
                const appointmentTimes = response.data.availableTimes.map(time => {
                    return {
                        ...time,
                        day: moment(time.start).day()
                    }
                }).sort((a, b) => {
                    if (new Date(a.start) < new Date(b.start)) return -1;
                    if (new Date(a.start) > new Date(b.start)) return 1;
                    return 0;
                }).reduce((prev, next) => {
                    if (!prev.some(i => i.day === next.day)) {
                        prev.push({ day: next.day, hours: [next] });
                        return prev;
                    } else {
                        const existing = prev.find(i => i.day === next.day);
                        const filtered = prev.filter(i => i.day !== next.day);
                        return [...filtered, {
                            ...existing,
                            hours: [...existing.hours, next]
                        }]
                    }
                }, []);
                let selected = response.data.patientAppointments.find(a => {
                    return a.status !== 'rejected' && a.category === 'phone';
                });
                if (selected) {
                    // look for an existing appointment slot that matches our current upcoming appointment, if found
                    // then select that one, if not found then add it into the list.
                    let day = moment(selected.start).day();
                    let dayObj = appointmentTimes.find(i => i.day === day);
                    if (dayObj) {
                        let hoursObj = dayObj.hours.find(i => i.start === selected.start);
                        if (hoursObj) {
                            if (hoursObj.availableProviderIds.indexOf(selected.providerId) < 0) {
                                hoursObj.availableProviderIds.push(selected.providerId);
                            }
                            selected = hoursObj;
                        }
                        else
                            dayObj.hours.push(selected);
                    } else {
                        appointmentTimes.push({ day, hours: [selected] });
                    }
                    value = JSON.stringify(selected);
                    if (this.props.onPreSelected)
                        this.props.onPreSelected(value);
                    this.props.onChange(value);
                }
                if (appointmentTimes.length === 0) {
                    this.props.onChange('NONE');
                    value = 'NONE';
                }
                this.setState({ appointmentTimes, value });

            }).catch((error) => {
                log.error("getAppointmentTimes error:", getErrorMessage(error));
                this.setState({ value: 'NONE' });
                this.props.onChange('NONE');
            });
        } else {
            this.setState({ value: 'NONE' });
            this.props.onChange('NONE');
        }
    }

    handleChange(event) {
        const { appointmentTimes } = this.state;
        if (event.target.value !== 'NONE') {
            const value = JSON.parse(event.target.value);
            const dayObj = appointmentTimes.find(i => i.day === moment(value.start).day());
            const hourObj = dayObj && dayObj.hours.find(i => i.start === value.start);
            console.log("handleChange:", value, appointmentTimes, dayObj, hourObj);
            this.props.onChange(JSON.stringify(hourObj));
            this.setState({ value: JSON.stringify(value) });
        } else {
            this.props.onChange('NONE');
            this.setState({ value: 'NONE' });
        }
    }

    render() {
        const { disabled } = this.props;
        const days = this.getHours();
        const currentDay = days.find(i => i.day === moment().day());
        const currentDayHours = currentDay && currentDay.hours && currentDay.hours.filter(item => moment(item.start).hour() > moment().hour());
        const currentDayIndex = days && days.findIndex(i => i.day === moment().day());
        let filteredDays =
            days && currentDayIndex >= 0 ? currentDayHours.length > 0 ? days.slice(currentDayIndex) : days.slice(currentDayIndex + 1) :
                days && currentDayIndex === -1 ? days.slice(0, 2) :
                    undefined;
        if (filteredDays && filteredDays.length === 0) filteredDays = days.slice(0, 2);
        if (filteredDays && filteredDays.length === 1) filteredDays = [...filteredDays, days[0]];
        if (filteredDays && filteredDays.length >= 2) filteredDays = filteredDays.slice(0, 2);

        const { appointmentTimes, value } = this.state;
        if (!isArrayValid(appointmentTimes)) return null;

        return (
            <FormControl variant="outlined" style={this.props.style}>
                <InputLabel>{T.texts.selectCallback}</InputLabel>
                <Select
                    disabled={disabled}
                    value={value}
                    onChange={this.handleChange.bind(this)}
                    label={T.texts.selectCallback}
                    variant="outlined"
                >
                    {this.props.enableNone === true ? <MenuItem value="NONE">
                        <em>{T.texts.selectCallbackDefault}</em>
                    </MenuItem> : null}
                    {appointmentTimes.map(day => [
                        <ListSubheader>
                            {moment().day() === day.day ? 'Today' :
                                moment().day() + 1 === day.day || (moment().day() === 6 && day.day === 0) ? 'Tomorrow' :
                                    moment().day(day.day).format('dddd')
                            }
                        </ListSubheader>,
                        day.hours.map((item, index) => <MenuItem key={index} value={JSON.stringify(item)}>
                            <T.span text="selectCallbackTime" start={moment(item.start).format('h:mma')} end={moment(item.end).format('h:mma')} />
                        </MenuItem>)
                    ])}
                </Select>
            </FormControl>
        )
    }
}

export default CallbackSelector;
