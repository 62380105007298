import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core';

import T from 'i18n-react';
import Moment from 'moment-timezone';
import _ from 'lodash';
import QRCode from 'qrcode.react';
import { Link } from 'react-router-dom';

import { findLastAlerts, findLastAlert, pushUnique } from '../utils/utils';
import PatientImage from "@apricityhealth/web-common-lib/components/PatientImage";
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';

export class BadgeDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            textColor: _.get(props.settings,'textColor') || '#ffffff',
            policyAlertId: _.get(props.settings, 'policyAlertId') || 'iraeOverallAlert',
            overrideAlertIds: _.get(props.settings, 'overrideAlertIds') || 'iraeOverallAlert',
            overallAlertId: _.get(props.settings,'overallAlertId') || 'iraeOverallAlert',
            permittedOverrideId: _.get(props.settings,'permittedOverrideId') || 'permittedOverride',
            permittedOverrideDays: _.get(props.settings, 'permittedOverrideDays') || 5,
            badgeExpiresWhen: props.badgeExpiresWhen,
            lastCheckIn: props.lastCheckIn,
            policyTextId: props.policyTextId,
            currentStatusText: props.currentStatusText,
            currentStatusColor: props.currentStatusColor,
            contentLoaded: props.badgeExpiresWhen && props.lastCheckIn && props.policyTextId && props.currentStatusText && props.currentStatusColor 
        }
    }

    componentDidMount() {
        console.log("BadgeDialog.componentDidMount()", this.state );
        if (! this.state.contentLoaded )
            this.loadContent();
    }

    loadContent(retries = 0) {
        const self = this;
        const { appContext } = this.props;
        const { patientId, alert_levels, alertLevelsLoaded, configLoaded, providerLoaded } = appContext.state; 
        const { policyAlertId, overrideAlertIds, overallAlertId, permittedOverrideId, permittedOverrideDays } = this.state;

        if ( !alertLevelsLoaded || !configLoaded || !providerLoaded ) {
            console.log(`Waiting for alerts: ${alertLevelsLoaded}, config: ${configLoaded}, provider: ${providerLoaded}`);
            // not all data loaded by the main app yet, wait until we have a config, provider, and alert levels
            return setTimeout( () => self.loadContent(), 250 );
        }

        let dataIds = [];
        pushUnique( dataIds, overallAlertId );
        pushUnique( dataIds, policyAlertId );
        pushUnique( dataIds, permittedOverrideId );

        let getData = {
            url: Config.baseUrl + `${Config.pathPrefix}patients/${patientId}/data?dataId=${dataIds.join(',')}&days=14`,
            method: 'GET',
            headers: { "Authorization": appContext.state.idToken },
        };

        console.log("getData Request:", getData);
        AxiosRequest(getData).then((result) => {
            let data = result.data.data;
            console.log("getData Result:", data);

            let permittedOverride = null;
            if( permittedOverrideId ) {
                let override = data.find((e) => e.dataId === permittedOverrideId);
                if ( override ) {
                    let days = override.data[1] || permittedOverrideDays;
                    let diff = Moment().diff(Moment(override.eventTime),'days', true);
                    if ( diff <= days ) {
                        console.log(`permittedOverride, diff: ${diff}:`, override );
                        permittedOverride = override.data[0];
                    }
                }
            }

            let lastAlert = findLastAlert( data, permittedOverride ? overrideAlertIds : overallAlertId, alert_levels ); // data.find((e) => e.dataId === overallAlertId);
            let lastCheckIn = lastAlert ? lastAlert.eventTime : null;
            let currentStatus = lastAlert ? lastAlert.data[0] : null;
            let currentStatusColor = lastAlert ? lastAlert.level.color : '#808080';
            let currentStatusText = lastAlert ? T.translate(currentStatus + 'AlertStatus') : T.translate("noRecentCheckIn");

            let org = _.get(appContext,"state.org");
            let badgeExpireTime = _.get(org,"config.badgeExpireTime") || 24;

            //if no last alert, make a moment instance in the past
            let badgeExpiresWhen = lastAlert ? Moment(lastAlert.eventTime).add( badgeExpireTime, 'hours') : Moment().subtract(1, 'minutes');

            let policyTextId = null;
            let enablePolicyText = self.props.settings.policy !== undefined;
            if ( enablePolicyText && policyAlertId ) {
                let policyAlerts = findLastAlerts(data, policyAlertId );
                for(let k in policyAlerts ) {
                    let alert = policyAlerts[k];
                    let policyId = `${alert.data[0]}_${alert.dataId}_policy`;
                    policyTextId = _.get(org.config, policyId) || _.get(this.props.settings.policy, policyId) || "notPermitted";
                    // if notPermitted, then stop right here, no need to look at the other alerts                    
                    if ( policyTextId === 'notPermitted' ) break;       
                }

                // if we have an override, then override the policy based on the alerts 
                if ( permittedOverride ) {
                    policyTextId = permittedOverride;
                }
                console.log(`policyTextId: ${policyTextId}, policyAlerts:`, policyAlerts );
            }
    
            self.setState({ contentLoaded: true, lastAlert,
                currentStatus, currentStatusColor, currentStatusText, permittedOverride,
                lastCheckIn, org, badgeExpireTime, badgeExpiresWhen, policyTextId }, () => {
                    console.log("loadContent done:", self.state );
                });
        }).catch((err) => {
            console.error("loadContent error:", err);
            if (retries < 3) {
                self.loadContent(retries + 1);
            }
        });
    }

    render() {
        const { appContext } = this.props
        const { firstName, lastName, photo } = appContext.state.patient;
        const { badgeExpiresWhen, contentLoaded, lastCheckIn, currentStatusText, 
            permittedOverride, currentStatusColor, textColor, policyTextId } = this.state;
        if (! contentLoaded ) return null;

        if (Moment() > badgeExpiresWhen) {
            let dialog = <Dialog open={true}>
                <DialogContent dangerouslySetInnerHTML={{__html: T.translate('badgeExpired')}}>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between', margin: '10px' }}>
                    <Button id="badge-cancel" color="primary" variant="contained" onClick={this.props.onClose}><T.span text='cancel' /></Button>
                    <Button id="badge-yes" color="primary" variant="contained" component={Link} to='/conversation' 
                        onClick={this.props.onClose}><T.span text='yes' /></Button>
                </DialogActions>
            </Dialog>;
            return dialog;
        }

        console.log(`BadgeDialog.render() - policyTextId: ${policyTextId}, permittedOverride: ${permittedOverride}`);
        let lastCheckInTime = lastCheckIn ? Moment(lastCheckIn).format("l LT ") + Moment(lastCheckIn).tz(Moment.tz.guess()).zoneAbbr() : null;
        let encodeString = `${lastName}, ${firstName} - Check In @ ${lastCheckIn}, Status: ${currentStatusText}`
        return (
            <Dialog open={true} maxWidth='lg' onClose={this.props.onClose}>
                <DialogTitle style={{backgroundColor: currentStatusColor}}>
                    <div align='center' style={{color: textColor}}>{firstName} {lastName}</div>
                </DialogTitle>
                <DialogContent id="badge-dialog" style={{ minWidth: 200, minHeight: 150, backgroundColor: currentStatusColor, color: textColor }} onClick={this.props.onClose}>
                    <div align='center'>
                        <PatientImage appContext={appContext} blobId={photo || ''} size={200} />
                    </div>
                    <br/>
                    <div align='center'>
                        <QRCode
                            value={encodeString}
                            fgColor={'black'}
                            width={100}
                            height={100}
                        />
                        <br />
                        <br />
                        <T.div align='center' style={{ backgroundColor: currentStatusColor, color: textColor}} 
                            text={{ key: 'currentStatus', currentStatus: currentStatusText }} />
                        <h3><T.span text={policyTextId} />{permittedOverride ? T.translate('overrideStatus') : null}</h3>
                        <h3>{lastCheckInTime ? lastCheckInTime : null}</h3>
                    </div>
                </DialogContent>
            </Dialog >
        )
    }
}

export default BadgeDialog;
