import React from "react";
import {
    Paper,
    CircularProgress,
    Dialog,
    DialogContent,
    Button,
    DialogActions,
    Slide
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import Moment from 'moment-timezone';
import T from 'i18n-react';
import { Link } from 'react-router-dom';

import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import { toBoolean } from '@apricityhealth/web-common-lib/utils/Utils';
import CallbackSelector from '../components/CallbackSelector';
import CallIcon from '@material-ui/icons/Call';
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import { Typography } from "@material-ui/core";
import { addActivity } from "@apricityhealth/web-common-lib/utils/Services";

function extractTagContent(content, tag, defaultTagContent = '') {
    const startTag = `<${tag}>`, endTag = `</${tag}>`;
    let start = content.indexOf(startTag);
    if (start >= 0) {
        let end = content.indexOf(endTag);
        if (end >= 0) {
            const tagContent = content.substring(start + startTag.length, end);
            content = content.substring(0, start) + content.substring(end + endTag.length);
            return { content, tagContent };
        }
    }
    return { content, tagContent: defaultTagContent };
}

export class EodDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            eodResult: props.eodResult,
            lastAlert: props.lastAlert,
            currentStatus: props.currentStatus,
            currentStatusText: props.currentStatusText,
            currentStatusColor: props.currentStatusColor,
            statusText: null,
            disclaimerDialog: null,
            disclaimerDialogOpen: false,
            progress: null,
            callbackSelector: false,
            callbackTime: undefined,
            previousCallbackTime: undefined,
            error: null,
            disabled: false
        }
    }

    componentDidMount() {
        const self = this;
        const { appContext } = this.props
        const { eodResult, lastAlert } = this.state

        if (eodResult) {
            console.log("eodResult:", eodResult);
            const { alert_levels } = appContext.state;
            let validFlag = eodResult.flags.find((e) => alert_levels.find((x) => x.addFlag === e));
            let flag = alert_levels.find((e) => e.addFlag === validFlag);

            let statusText = eodResult?.response;
            let checkInTime = eodResult?.eventTime;
            let currentStatus = flag ? flag.alertLevelId : null;
            let currentStatusColor = flag ? flag.color : '#808080';
            let currentStatusText = currentStatus ? T.translate(currentStatus + 'AlertStatus') : T.translate("noAlertStatus");
            let callbackSelector = statusText ? statusText.indexOf("&&displayCallbackSelector") >= 0 : false;
            self.setState({ disabled: false, statusText, currentStatus, checkInTime, currentStatusColor, currentStatusText, callbackSelector });
        }
        else if (lastAlert) {
            console.log("lastAlert:", lastAlert);
            let idToken = appContext.state.idToken;
            let args = [
                `sessionId=${lastAlert.sessionId}`,
                "limit=1"
            ];
            const getJournal = {
                url: Config.baseUrl + `${Config.pathPrefix}journal/${lastAlert.patientId}?` + args.join('&'),
                method: 'GET',
                headers: { "Authorization": idToken }
            }
            console.log(`getJournal request`, getJournal);
            this.setState({ progress: <CircularProgress size={30} style={{ margin: 'auto' }} />, callbackSelector: false });
            AxiosRequest(getJournal).then((response) => {
                console.log(`getJournal response`, response.data);

                let records = response?.data?.records;
                let eodResponse = records?.length > 0 ? records[0].journal.find((e) => e.eod === true) : null;
                let statusText = eodResponse?.question ? eodResponse.question : null;
                let callbackSelector = statusText ? statusText.indexOf("&&displayCallbackSelector") >= 0 : false;
                let checkInTime = lastAlert.eventTime;
                if (statusText) {
                    self.setState({ disabled: true, progress: null, statusText, checkInTime, callbackSelector });
                }
                else {
                    self.setState({ progress: null, statusText: `<title>${T.translate('noRecentCheckIn')}</title>` })
                }
            }).catch((error) => {
                this.setState({ progress: null });
                console.error("loadText error", error);
            });
        }
        else {
            self.setState({ statusText: `<title>${T.translate('noRecentCheckIn')}</title>` })
        }
    }

    onDone() {
        const { appContext } = this.props;
        const { patientId } = appContext.state;
        const { callbackTime, previousCallbackTime, eodResult } = this.state;
        if (callbackTime && callbackTime !== previousCallbackTime && callbackTime !== "NONE") {
            const timeSlot = JSON.parse(callbackTime);
            this.setState({ progress: <CircularProgress size={20} />, error: null });

            appContext.createAppointment(timeSlot).then(() => {
                addActivity(appContext, { activityType: 'PatientRequestedCallback', patientId, sessionId: eodResult?.sessionId, description: `Patient requested callback for ${Moment(timeSlot.start).format('LLL')}.` });

                this.setState({ progress: null });
                this.props.onClose();
            }).catch((err) => {
                this.setState({ progress: null, error: getErrorMessage(err) });
            })
        } else {
            if (callbackTime === 'NONE') {
                addActivity(appContext, { activityType: 'PatientRejectedCallback', sessionId: eodResult?.sessionId, patientId, description: 'Patient rejected callback.' });
            }
            this.props.onClose();
        }
    }

    render() {
        const { appContext } = this.props;
        const { progress, statusText, currentStatus, currentStatusColor, eodResult,
            checkInTime, callbackSelector, callbackTime, error, disabled } = this.state

        function hookDiv(ref) {
            if (ref) {
                ref.addEventListener('click', (e) => {
                    if (appContext.onURL && e.target.tagName === 'A') {
                        appContext.onURL({ href: e.target.href });
                        e.preventDefault();
                    }
                });
            }
        }

        if (!statusText && progress) {
            return <Dialog 
                open={true} fullScreen={appContext.isCompactDisplay()} 
                maxWidth='md' fullWidth={true} hideBackdrop={true} TransitionComponent={Slide} TransitionProps={{direction: 'up'}}>
                <DialogContent>
                    <div>
                        <Skeleton variant='rect' animation='wave' height={150} style={{ margin: 15 }} />
                        <br /><br /><br />
                        <Skeleton variant='rect' animation='wave' height={35} style={{ margin: 15 }} />
                        <Skeleton variant='rect' animation='wave' height={35} style={{ margin: 15 }} />
                        <Skeleton variant='rect' animation='wave' height={35} style={{ margin: 15 }} />
                        <Skeleton variant='rect' animation='wave' height={35} style={{ margin: 15 }} />
                    </div>
                </DialogContent>
            </Dialog>;

            // return <Dialog open={true} style={{ backgroundColor: currentStatusColor }}>
            //     <DialogContent style={{ marginBottom: 0 }}>
            //         {progress}
            //     </DialogContent>
            // </Dialog>;
        }
        if (!statusText) {
            return null;
        }

        console.debug(`Status text `, statusText)
        let { tagContent: title, content: content2 } = extractTagContent(statusText, 'title');
        let { tagContent: disclaimer, content } = extractTagContent(content2, 'disclaimer', T.texts['eod-disclaimer-link'])

        const COMPONENTS = {
            "displayCallDr": (key) => {
                return appContext.state.providerPhones ?
                    <div key={key} align='center' style={{ marginBottom: 20 }}>
                        <Paper onClick={appContext.onCall.bind(appContext, appContext.state.providerPhones)} style={{ color: currentStatusColor, width: 80, height: 80, paddingTop: 15, paddingLeft: 15, paddingRight: 15 }}>
                            <CallIcon style={{ fontSize: 50 }} /><br />
                            <T.span text='callDr' />
                        </Paper>
                    </div> : null;
            },
            "displayCallOrg": (key) => {
                return appContext.state.orgPhones ?
                    <div key={key} align='center' style={{ marginBottom: 20 }}>
                        <Paper onClick={appContext.onCall.bind(appContext, appContext.state.orgPhones)} style={{ color: currentStatusColor, width: 80, height: 80, paddingTop: 15, paddingLeft: 15, paddingRight: 15 }}>
                            <CallIcon style={{ fontSize: 50 }} /><br />
                            <T.span text='callOrg' />
                        </Paper>
                    </div> : null;
            },
            "displayCallbackSelector": (key, args) => {
                const enableNone = toBoolean(args[0], false);
                const limit = args[1] ? Number(args[1]) : undefined;
                return <div key={key} style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                    <CallbackSelector disabled={disabled} appContext={appContext} enableNone={enableNone} limit={limit} value={callbackTime}
                        onChange={(value) => {
                            console.log("callbackTime:", value);
                            this.setState({ callbackTime: value });
                        }}
                        onPreSelected={(value) => {
                            console.log("onPreSelected:", value);
                            this.setState({ previousCallbackTime: value })
                        }}
                        style={{ width: '80%' }} />
                </div>;
            }
        }
        content = content.split('&&');
        for (let i = 0; i < content.length; ++i) {
            let key = content[i];
            let args = [];
            let argStart = key.indexOf('(');
            if (argStart >= 0) {
                let argEnd = key.lastIndexOf(')');
                if (argEnd > argStart) {
                    try {
                        // eslint-disable-next-line
                        args = eval("[" + key.substring(argStart + 1, argEnd) + "]");       // parse the args as an array
                    } catch (err) {
                        console.error("Error parsing args:", key);
                    }
                    key = key.substring(0, argStart);
                }
            }
            if (COMPONENTS[key] !== undefined) {
                content[i] = COMPONENTS[key](i, args);
            } else {
                content[i] = <span key={i} ref={hookDiv} dangerouslySetInnerHTML={{ __html: content[i] }} />
            }
        }

        const ackDisabled = eodResult ? callbackSelector && callbackTime === undefined : false;
        const ackButton = progress ? progress : <Button style={{ width: "100%", height: "4rem", borderRadius: "10px" }} disabled={ackDisabled} id="eodDone" color="primary" variant="contained" component={Link} to='/dashboard'
            onClick={this.onDone.bind(this)}><T.span text='acknowledge' /></Button>;
        //const dialogStyle = appContext.isCompactDisplay() ? {} : { backgroundColor: currentStatusColor };

        console.debug(`content `, content)
        return (
            <Dialog
                open={true}
                fullScreen={appContext.isCompactDisplay()}
                //style={dialogStyle}
                maxWidth='md'
                fullWidth={true}
                TransitionComponent={Slide} 
                TransitionProps={{direction: 'up'}}          
            >
                <DialogContent
                    style={{
                        margin: 0,
                        padding: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        height: "100%",
                        width: "100%",
                        overflow: 'hidden'
                    }}>
                    {<React.Fragment>
                        <div style={{ backgroundColor: currentStatusColor, padding: "3rem 1rem ", textAlign: "center", borderBottomLeftRadius: "35px" }}>
                            {!title && currentStatus && <Typography style={{ minHeight: '3rem', paddingBottom: "2.5rem" }} variant="h3"> {currentStatus[0].toUpperCase() + currentStatus.slice(1)}</Typography>}
                            {title && <Typography style={{ minHeight: '3rem' }} variant="h6"><div dangerouslySetInnerHTML={{ __html: title }} /></Typography>}
                            {checkInTime && <Typography>{T.texts['completed']} {checkInTime && Moment(checkInTime).format("llll")}</Typography>}
                        </div>
                    </React.Fragment>}

                    <div style={{ position: "relative", overflowX: "hidden", overflowY: "auto", padding: "1rem 2rem " }}>
                        {content}
                    </div>
                    <div style={{ flexGrow: 1 }}></div>
                    <div style={{ textAlign: "center", marginLeft: 20, marginRight: 20 }}>
                        <Typography style={{ minHeight: '3rem', color: "red" }} variant="h6">
                            <div onClick={(e) => { e.stopPropagation(); this.setState({ disclaimerDialogOpen: true }) }} dangerouslySetInnerHTML={{ __html: disclaimer }} />
                        </Typography>
                    </div>
                    <br />
                    <div style={{ padding: "0 4rem" }} align='center'>
                        {ackButton}
                    </div>
                    <br />
                    <div align='center'>
                        <span style={{ color: 'red' }}>{error}</span>
                    </div>
                    <Dialog id="disclaimer" open={this.state.disclaimerDialogOpen}>
                        <DialogContent>
                            <div dangerouslySetInnerHTML={{ __html: T.texts['eod-disclaimer'] }} />
                        </DialogContent>
                        <DialogActions >
                            <Button id="eodDisclaimer" color="primary" variant="contained"
                                onClick={(e) => { e.stopPropagation(); this.setState({ disclaimerDialogOpen: false }) }} ><T.span text='ok' /></Button>
                        </DialogActions>
                    </Dialog>

                </DialogContent>
            </Dialog>
        )
    }


}
