import React from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core/';

import T from 'i18n-react';

export class SelectContactMethod extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contactMethod: props.contactMethod || 'email',
        };
    }

    render() {
        const self = this;
        return (
            <FormControl style={{ margin: 5 }}>
                <InputLabel>
                    <T.span text="contactMethod" />
                </InputLabel>
                <Select
                    value={this.state.contactMethod}
                    style={{ width: 200 }}
                    onChange={(e) => {
                        self.setState({ contactMethod: e.target.value }, () => {
                            self.props.onChange(this.state.contactMethod);
                        });
                    }}
                >
                    <MenuItem value={'email'}>
                        <T.span text="email" />
                    </MenuItem>
                    <MenuItem value={'phone'}>
                        <T.span text="mobile" />
                    </MenuItem>
                </Select>
            </FormControl>
        );
    }
}

export default SelectContactMethod;
