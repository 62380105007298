import React, { Component } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle
} from '@material-ui/core/'
import MuiPhoneNumber from 'material-ui-phone-number';

import T from 'i18n-react';

export class FaxNumberDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            faxNumber: props.faxNumber
        };
    }

    ok() {
        const { faxNumber } = this.state;
        if (this.props.ok)
            this.props.ok(faxNumber)
    }

    cancel() {
        if (this.props.cancel)
            this.props.cancel()
    }

    render() {
        let self = this;
        let { faxNumber } = this.state;
        return <Dialog open={true} >
            <DialogTitle><T.span text='enterFaxNumber'/></DialogTitle>
            <DialogContent >
                <div >
                    <MuiPhoneNumber
                        inputStyle={styles.phone}
                        defaultCountry={'us'}
                        value={faxNumber}
                        onChange={(phone) => { console.log(`phone`, phone); self.setState({ faxNumber: phone }) }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={this.cancel.bind(this)}><T.span text='cancel'/></Button>
                <Button color="primary" variant="contained" onClick={this.ok.bind(this)}><T.span text='confirm'/></Button>
            </DialogActions>
        </Dialog>

    }
};


const styles = {
    phone: {
        width: 300
    }
}

export default FaxNumberDialog;

