import React, { Component } from 'react';
import moment from 'moment-timezone';
import UploadPatientImage from '@apricityhealth/web-common-lib/components/UploadPatientImage';
import PatientImage from '@apricityhealth/web-common-lib/components/PatientImage';
import defaultImage from '@apricityhealth/web-common-lib/uploadImage.jpg';
import {
	TextField,
	Button,
	Select,
	MenuItem,
    FormControl,
    InputLabel,
} from '@material-ui/core';
import T from 'i18n-react';


export class VaccinationDialog extends Component {
    constructor(props){
        super(props)

        this.imageUploadRef = React.createRef()

        const { vaccination, create } = props

        this.state = {
            vaccination,
            create,
            _id: vaccination?._id,
            medId: vaccination?.data?.[0], 
            dose: vaccination?.data?.[1], 
            eventTime: vaccination?.eventTime && new Date(vaccination.eventTime).toISOString().substring(0, 10),
            blobId: vaccination?.data?.[3], 
        }
    }

    /*
        ⁃	Form 
        Med select
        ⁃	[0]
        Date select
        ⁃	vac.eventTime
        Dose select
        ⁃	[1]
        Status
        ⁃	[2]
        Image select
        ⁃	[3]
    */

    onDone() {
		const { create, onDone } = this.props
        if (!onDone) return

        const { _id, medId, dose, blobId, eventTime } = this.state

		onDone({
            create,
            medId: medId,
            dose: dose,
            blobId: blobId,
            eventTime: eventTime,
            _id: _id,
        })
    }

    onCancel() {
        const { onCancel} = this.props;
        if (onCancel)
            onCancel()
    }

    onImageChange(blobId) {
        this.setState({blobId})
    }

	render() {
        let { medId, dose, eventTime, blobId } = this.state
        let { meds, appContext, patientId, create } = this.props

        const isFutureDate = eventTime && moment(eventTime).startOf('day').toISOString() > moment().startOf('day').toISOString();
        const isInvalidDate = !eventTime || (eventTime && !moment(eventTime).isValid());

        const disabled = !medId || !dose || !eventTime || !blobId || isFutureDate || isInvalidDate

        const medSelect = <FormControl fullWidth>
            <InputLabel>{T.translate('vaccine')}</InputLabel>
            <Select fullWidth onChange={(e) => { medId = e.target.value; this.setState({ medId })}} label={T.translate('vaccine')} value={medId} style={styles.formItem}>
            {
                Object.keys(meds).map((key)=> {
                    return ( <MenuItem key={key} value={key}>{meds[key]}</MenuItem>)
                })
            }
            </Select> 
        </FormControl>

        const eventTimeSelect = <FormControl fullWidth>
            <TextField id={'eventTime'}
                fullWidth
                label={T.translate('date')}
                style={styles.formItem}
                InputLabelProps={{ shrink: true }}
                type={'date'}
                inputProps= { {inputMode: 'date'}}
                value={eventTime}
                onChange={evt => this.setState({ eventTime: evt.target.value })}
            />
        </FormControl>

        const doseSelect = <FormControl fullWidth style={styles.formItem}>
            <InputLabel>{T.translate('dose')}</InputLabel> 
            <Select fullWidth onChange={evt => this.setState({ dose: evt.target.value })} label={T.translate('dose')} placeholder={T.translate('dose')} value={dose} style={styles.formItem}>
                <MenuItem value={1}>{T.translate('dose1')}</MenuItem>
                <MenuItem value={2}>{T.translate('dose2')}</MenuItem>
                <MenuItem value={3}>{T.translate('dose3')}</MenuItem>
                <MenuItem value={4}>{T.translate('dose4')}</MenuItem>
            </Select>
        </FormControl>

	    return (
            <div> 
                {medSelect}
                {eventTimeSelect}
                {isFutureDate && <span style={styles.invalidDate}>{T.translate('dateFutureError')}</span>}
                {doseSelect}
                <UploadPatientImage
                    styles={styles.formItem}
                    maxImageCount={1}
                    key={0}
                    ref={this.imageUploadRef}
                    appContext={appContext}
                    patientId={patientId}
                    disabled={false}
                    onChange={this.onImageChange.bind(this)}
                    hideUploads={false}
                >
                    <PatientImage
                            key={0}
                            styles={{justifyContent:'center'}}
                            appContext={appContext}
                            blobId={blobId}
                            size={300}
                            defaultImage={defaultImage}
                            alt={'vaccination label'}
                            square={false}
                            enableDelete={false}
                        />
                </UploadPatientImage>
                <div style={styles.buttonSection}>
                    <Button variant="contained" style={styles.button} onClick={(e) => { this.onCancel() }}>
                        {T.translate('cancel')}
                    </Button>
                    <Button disabled={disabled} variant="contained" style={styles.button} onClick={(e) => { this.onDone() }}>
                        {create ? T.translate('add') : T.translate('save')}
                    </Button>
                </div>
            </div>
	    )
	}
}

const styles = {
    formItem: {
        marginTop:15,
        marginBottom:15,
        minWidth:300
    },
    button:{
        margin:5
    },
    buttonSection:{
        display:'flex',
        justifyContent:'space-between'
    },
    invalidDate: {
        color: 'red'
    },
} 

export default VaccinationDialog;
