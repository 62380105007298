import React from 'react';
import Moment from 'moment';
import T from 'i18n-react';

import {
    Grid,
    Paper,
    CircularProgress,
    Avatar
    //Button
} from '@material-ui/core';

import SymptomIcon from '@material-ui/icons/CheckCircle';
import FailedIcon from '@material-ui/icons/RemoveCircle';

import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

const CLEARANCE_DAYS = 30;
const CLEARANCE_DATA_IDS = [
    "clearanceProtocol",
    "exposureAlert",
    "covidAlert",
    "temperature",
    "positiveForCovid19"
];

function f2c( fahrenheit ) {
    return (fahrenheit - 32) * 5 / 9;
}

export class ClearanceView extends React.Component {
    constructor(props) {
        super(props);
        if (! props.appContext ) throw new Error("appContext is a required property");

        this.state = {
            clearanceProtocol: 0,
            clearanceStart: null,
            days: [],
            data: [],
            progress: null,
            error: null,
            dialog: null
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    loadContent(retries = 0) {
        const self = this;
        const { appContext } = this.props;
        const { patientId } = appContext.state;

        let getData = {
            url: Config.baseUrl + `${Config.pathPrefix}patients/${patientId}/data?dataId=${CLEARANCE_DATA_IDS.join(',')}&days=${CLEARANCE_DAYS}`,
            method: 'GET',
            headers: { "Authorization": appContext.state.idToken },
        };

        this.setState({ progress: <CircularProgress size={30} />, error: null });
        
        let clearanceProtocol = 0;
        let clearanceStart = null;

        console.log("getData Request:", getData);
        AxiosRequest(getData).then((result) => {
            let data = result.data.data;
            console.log("getData Result:", data);

            for(let i=0;i<data.length;++i) {
                if ( data[i].dataId !== 'clearanceProtocol') continue;
                if ( clearanceStart !== null && !Number(data[i].data[0])) {
                    console.log(`Clearance stopped at ${data[i].eventTime}:`, data[i] );
                    clearanceStart = Moment(data[i].eventTime);
                    // clearance is stopped, so remove any data prior to this data point..
                    data.splice( i, (data.length - i) + 1 );
                    break;
                }
                else if ( clearanceStart === null ) {
                    clearanceProtocol = Number(data[i].data[0]);
                    clearanceStart = Moment(data[i].eventTime);
                } else if ( clearanceStart !== null ) {
                    clearanceStart = Moment(data[i].eventTime);
                }
            }

            let days = [];
            let now = Moment();
            for(let i=0;i<data.length;++i) {
                let dataTime = Moment(data[i].eventTime);
                if ( dataTime < clearanceStart ) break;
                let dayStart = Moment(dataTime).startOf('day');
                let day = now.diff( dayStart, 'days');
                if (! days[day] )
                    days[day] = { data: [], dayStart, positive: false };
                days[day].data.push( data[i] );
            }

            console.log("days:", days );
            self.setState({ progress: null, days, data, clearanceProtocol, clearanceStart });
        }).catch((err) => {
            console.error("loadContent error:", err);
            if (retries < 3)
                self.loadContent(retries + 1);
            else
                self.setState({ progress: null, error: getErrorMessage(err) });
        });
    }

    saveClearanceProtocol( clearanceProtocol, answer ) {
        const self = this;
        const { appContext } = this.props;
        const { patientId } = appContext.state;

        let data = {
            dataId: 'clearanceProtocol',
            data: [ clearanceProtocol, answer, 0, 0 ],
            status: 'preliminary',
            baseline: false,
            source: [ 
                { origin: 'patient-web-client' }
            ]
        };
        let saveData = {
            url: Config.baseUrl + `${Config.pathPrefix}patients/${patientId}/data`,
            method: 'POST',
            headers: { "Authorization": appContext.state.idToken },
            data
        };

        this.setState({ progress: <CircularProgress size={30} />, error: null });
        console.log("saveData Request:", saveData);
        AxiosRequest(saveData).then((result) => {
            console.log("saveData Result:", result.data);
            return self.loadContent();
        }).catch((err) => {
            console.error("saveData error:", err);
            self.setState({progress: null, error: getErrorMessage(err)});
        });
    }

    stopClearanceProtocol() {
        this.saveClearanceProtocol( 0, "Stop" );
    }

    startClearanceProtocol() {
        this.saveClearanceProtocol( 1, "Yes" );
    }

    render() {
        const { appContext } = this.props;
        const { alert_levels, temperatureUnit } = appContext.state;
        const { clearanceProtocol, clearanceStart, days, progress, error } = this.state;

        let lastEvent = null;
        let components = [];
        if ( error )
            components.push(<span key='error'>{error}</span>);
            
        if ( progress ) {
            components.push( 
                <Grid item style={styles.gridItem} key={'progress'}> 
                    <Paper style={styles.paper}>
                        <div align='center'>
                            {progress}
                        </div>
                    </Paper>
                </Grid>);
        }
        else if (! clearanceProtocol ) {
            components.push( 
                <Grid item style={styles.gridItem} key={'clearanceProtocolInfo'}> 
                        <div dangerouslySetInnerHTML={{__html: T.texts['clearanceProtocolInfo']}} />
                        <br />
                </Grid>);
            // components.push( <Button key='startButton' color='primary' variant='contained' 
            //     onClick={this.startClearanceProtocol.bind(this)}><T.span text='startClearance' /></Button> );
        }
        else {
            // group all data, but their day calendar
            function getPeakAlert(data, alert_levels, dataId) {
                let lowestLevel = null;
                for(let i=0;i<alert_levels.length;++i) {
                    if ( ! lowestLevel || alert_levels[i].priority < lowestLevel.priority) lowestLevel = alert_levels[i];
                }

                let peak = null;
                let peakLevel = null;
                for(let i=0;i<data.length;++i) {
                    if ( data[i].dataId !== dataId ) continue;
                    let alert = data[i].data[0];
                    let level = alert_levels.find((e) => e.alertLevelId === alert);
                    if (! level ) {
                        console.error("Alert level not found:", alert );
                        continue;
                    }
                    if ( peak === null || level.priority > peakLevel.priority) {
                        peak = data[i];
                        peakLevel = level;
                    }
                }
                return { peak, peakLevel, lowestLevel };
            }

            function getPeakData(data, dataId) {
                let peak = null;
                for(let i=0;i<data.length;++i) {
                    if ( data[i].dataId !== dataId) continue;
                    if ( peak === null || Number(data[i].data[0]) > Number(peak.data[0]) )
                        peak = data[i];
                }
                return peak;
            }

            function getSymptomRow(day, dataId, textId, negTextId) {
                let data = getPeakData(day.data, dataId);
                if (! data ) return null;
                let positive = Number(data.data[0]) > 0;
                day.positive |= positive;
                let color = positive ? '#de1219' : '#11d11e';
                let icon = positive ? <FailedIcon style={{fill: color}} /> : <SymptomIcon style={{fill: color}} />;
                if ( negTextId !== undefined && !positive ) textId = negTextId;
                return <tr><td><T.span text={textId} /></td><td align='right'>{icon}</td></tr>;
            }

            function getAlertRow(day, dataId, textId, negTextId) {
                let data = getPeakAlert(day.data, alert_levels, dataId);
                if(! data ) return null;
                console.log("getAlertRow:", data );
                let positive = data.peakLevel && data.lowestLevel ? Number(data.peakLevel.priority) > Number(data.lowestLevel.priority) : false;     // positive if the peak level is higher than the lowest alert level
                day.positive |= positive;
                let color = positive ? '#de1219' : '#11d11e';
                let icon = positive ? <FailedIcon style={{fill: color}} /> : <SymptomIcon style={{fill: color}} />;
                if ( negTextId !== undefined && !positive ) textId = negTextId;
                return <tr><td><T.span text={textId} /></td><td align='right'>{icon}</td></tr>;
            }

            // generate grid item for each day...
            for(let i=0;i<days.length;++i) {
                let day = days[i];
                let symptomsAlert = day ? getPeakAlert(day.data, alert_levels, 'covidAlert') : null;
                let clearanceDay = (days.length - i);       // add 1 because 0 confuses people
                if ( day ) {
                    day.positive = symptomsAlert && symptomsAlert.peak ? symptomsAlert.peakLevel.priority > symptomsAlert.lowestLevel.priority : false;
                    console.log("symptomsAlert:", symptomsAlert );
                    let symptomsColor = day.positive ? '#de1219' : '#11d11e';
                    let symptomsIcon = day.positive ? <FailedIcon style={{fill: symptomsColor}} /> : <SymptomIcon style={{fill: symptomsColor}} />;
                    let symptomsItem = <tr><td><T.span text='clearanceDaySymptoms' /></td><td align='right'>{symptomsIcon}</td></tr>;
                    
                    let covidTestItem = getSymptomRow(day, "positiveForCovid19", "positiveForCovid", "negativeForCovid" );
                    let exposureItem = getAlertRow(day, "exposureAlert", "noExposures" );
                    let temperature = getPeakData(day.data,"temperature");
                    if ( day.positive && lastEvent === null)
                        lastEvent = day.dayStart;
                    let temperatureValue = temperature ? Number(temperature.data[0]).toFixed(1) : NaN;
                    if ( !isNaN(temperatureValue) && temperatureUnit === 'C' )
                        temperatureValue = f2c( Number(temperatureValue) ).toFixed(1);
                    let temperatureItem = temperature ? <tr><td><T.span text='temperature' /></td>
                        <td align='right'><b>{temperatureValue}{temperatureUnit}</b></td></tr> : null;

                    components.push( <Grid item style={styles.gridItem} key={i}>
                        <Paper style={styles.paper}>
                            <h1 align='center'><T.span text={{key: 'clearanceDayHeader', clearanceDay }} /></h1>
                            <table style={{width: '100%'}}><tbody>
                                {temperatureItem}
                                {symptomsItem}
                                {exposureItem}
                                {covidTestItem}
                            </tbody></table>
                        </Paper>
                    </Grid>);
                } else {
                    // no data for this day
                    components.push(<Grid item style={styles.gridItem} key={i}>
                        <Paper style={styles.paper}>
                            <h1 align='center'><T.span text={{ key: 'clearanceDayHeader', clearanceDay }} /></h1>
                            <table style={{ width: '100%' }}><tbody>
                                <tr><td>
                                    <T.div text='clearanceDayNoData' />
                                </td><td align='right'>
                                        <SymptomIcon style={{ fill: '#808080' }} />
                                    </td>
                                </tr>
                            </tbody></table>
                        </Paper>
                    </Grid>);
                }
            }

            components.push( 
                <Grid item style={styles.gridItem} key={'clearanceProtocolInfo'}> 
                    <Paper style={styles.paper}>
                        <div dangerouslySetInnerHTML={{__html: T.texts['clearanceProtocolInfo']}} />
                    </Paper>
                </Grid>);

            // components.push( <Button key='stopButton' color='secondary' variant='contained' 
            //     onClick={this.stopClearanceProtocol.bind(this)}><T.span text='stopClearance' /></Button> );
        }

        if ( lastEvent === null )
            lastEvent = clearanceStart;
        let clearanceDays = Moment().diff(Moment(lastEvent), 'days' );
        let daysRemaining = Number(this.props.clearanceDays) - clearanceDays;
        if ( daysRemaining < 0 ) daysRemaining = 0;
        console.log(`daysRemaining: ${daysRemaining}, clearanceDays: ${clearanceDays}, lastEvent: ${lastEvent}, clearanceStart: ${clearanceStart}`);

        let icon = React.cloneElement( this.props.icon, {fontSize: 'small', style: {fill: this.props.color} } );
        return <Grid id={this.props.id} container align="center" style={{marginTop:50}}>
            <Grid item style={{width: '100%', marginTop: 15, marginBottom: 15}}>
                <Paper style={{textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 5, padding: 15}} >
                    <div style={styles.div}>
                        <div style={{ marginBottom: 15, paddingBottom: 15, borderBottom: `1px solid grey` }}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item style={{ marginRight: 10 }}>
                                <Avatar style={{ width: 35, height: 35, border: `2px solid ${this.props.color}`, backgroundColor: 'white' }}>{icon}</Avatar>
                            </Grid>
                            <Grid item >
                                <span style={{ fontSize: 22, fontColor: '#666666' }}>{this.props.label}</span>
                                <br />
                                { /* {daysRemaining ? <T.span style={{ fontSize: 12, color: '#b81233'}} text={{key: 'clearanceDaysRemaining', daysRemaining }} /> : null} */ }
                            </Grid>
                        </Grid>
                        </div>
                        {components}
                    </div >
                </Paper>
            </Grid>
        </Grid>;
    }
}

const styles = {
    div: {
        margin: 5,
        textAlign: 'left'
    },
    gridContainer: {
        padding: 5,
        margin: 0,
        width: '95%'
    },
    gridItem: {
        margin: 5,
        width: '95%',
        cursor: 'pointer'
    },
    paper: {
        padding: 10,
        margin: 5,
        width: '95%',
        textAlign: 'left'
    }
};

export default ClearanceView;
