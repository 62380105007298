import React from 'react';
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';

import {
    CircularProgress,
    Grid,
    Paper,
    Button,
    Avatar
} from '@material-ui/core/';

import T from 'i18n-react';

import FaxNumberDialog from '../dialogs/FaxNumberDialog';
import { MobilePDFReader } from 'react-read-pdf';

function isReactNative() {
    return window.ReactNativeWebView ? true : false;
}

export class ReportView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: null,
            showHeader: true,
            showHeatmapAndSymptoms: true,
            showRecommendations: true
        };
    }
    componentDidMount() {
        this.getPatientSummary();
    }

    componentWillUnmount() {
    }

    getPatientSummary() {
        const self = this;
        let { appContext } = this.props;
        let { showHeader, showHeatmapAndSymptoms, showRecommendations } = self.state;
        let patientId = appContext.state.patientId;

        self.setState({ progress: <CircularProgress />, report: null });
        const recipe = 'pdf';
        let data = {
            reportName: "PatientSummary",
            recipe,
            args: { patientId, showHeader, showHeatmapAndSymptoms, showRecommendations }
        }
        const request = {
            url: Config.baseUrl + `${Config.pathPrefix}reporting/report`,
            data,
            method: 'POST',
            headers: { "Authorization": appContext.state.idToken }
        };

        console.log("getReport:", request);
        AxiosRequest(request).then((response) => {
            console.log("getReport response:", response);
            let report = response.data;
            if (report.error) {
                //check for the erorr in the data itself.
                self.setState({ progress: <T.span text="noReportAvailable" /> });
            } else {
                self.setState({ progress: null, report });
            }

        }).catch((error) => {
            console.log("getReport error:", error.response);
            self.setState({ progress: <T.span text="noReportAvailable" /> });
        });
    }

    getFaxNumber() {
        let self = this;
        let { faxNumber } = this.state;
        self.setState({
            dialog: <FaxNumberDialog faxNumber={faxNumber} ok={(faxNumber) => { self.sendFax(faxNumber) }} cancel={() => { self.cancel() }} />
        });
    }

    cancel() {
        this.setState({ dialog: null });
    }

    sendFax(faxNumber) {
        console.log(`faxing `, faxNumber)
        const self = this;
        let { appContext } = this.props;
        let patientId = appContext.state.patientId;

        self.setState({ dialog: null, faxNumber, progress: <CircularProgress /> });
        const recipe = 'pdf';
        let data = {
            reportName: "PatientSummary",
            recipe,
            args: { patientId, showHeader: true, showHeatmapAndSymptoms: true, showRecommendations: true }
        }
        const request = {
            url: Config.baseUrl + `${Config.pathPrefix}reporting/eFax?documentTitle=PatientSummary&faxNumber=${faxNumber}`,
            data,
            method: 'POST',
            headers: { "Authorization": appContext.state.idToken }
        };

        console.log("sendFax:", request);
        AxiosRequest(request).then((response) => {
            console.log("sendFax response:", response);
            self.setState({ progress: null });
        }).catch((error) => {
            console.log("sendFax error:", error.response);
            self.setState({ progress: error.message });
        });

    }

    render() {
        let { report, progress, dialog } = this.state;

        let url = report ? "data:application/pdf;base64," + report : null;
        let icon = React.cloneElement(this.props.icon, { fontSize: 'small', style: { fill: this.props.color } });
        return <Grid id={this.props.id} container align="center" style={{ marginTop: 50 }}>
            <Grid item style={{ width: '100%', marginTop: 15, marginBottom: 15 }}>
                <Paper style={{ textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 5, padding: 15 }} >
                    <div style={styles.div}>
                        <div style={{ marginBottom: 15, paddingBottom: 15, borderBottom: `1px solid grey` }}>
                            <Grid container direction="row" alignItems="center">
                                <Grid item style={{ marginRight: 10 }}>
                                    <Avatar style={{ width: 35, height: 35, border: `2px solid ${this.props.color}`, backgroundColor: 'white' }}>{icon}</Avatar>
                                </Grid>
                                <Grid item >
                                    <span style={{ fontSize: 22, fontColor: '#666666' }}>{this.props.label}</span>
                                </Grid>
                            </Grid>
                        </div>

                        {report ? <div style={styles.div}>
                            {!isReactNative() ? <Button color="primary" variant="contained" style={styles.button} href={url} target="_blank" download="eReport.pdf" >
                                <T.span text="download" />
                            </Button> : null}
                            <Button color="primary" variant="contained" style={styles.button} onClick={this.getFaxNumber.bind(this)} >
                                <T.span text="eFax" />
                            </Button>
                            <MobilePDFReader style={styles.report} isShowHeader={false} isShowFooter={false} url={url} />
                        </div> :
                            <Grid item style={styles.progress}>
                                <div style={{height: 500, paddingTop: 250}}>
                                    {progress ? progress : <T.span text='noReportAvailable' />}
                                </div>
                            </Grid>
                        }
                    </div>
                </Paper>

                {dialog}
            </Grid>
        </Grid>
    }
}

const styles = {
    gridContainer: {
        margin: 5,
        width: '100%',
        alignContent: 'center',
        alignItems: 'center'
    },
    div: {
        margin: 0,
        padding: 0
    },
    button: {
        margin: 5,
        width: '30',
        textTransform: 'none',
    },
    report: {
        margin: 0,
        padding: 0,
        width: '100%',
        alignContent: 'center',
        alignItems: 'center'
    },
    progress: {
        position:'relative',
        width: '100%',
        minHeight: 500,
        textAlign: 'center',
        alignItems: 'center'        
    }
}