import React from 'react';
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import {
    CircularProgress,
    Grid,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Avatar
} from '@material-ui/core';

import T from 'i18n-react';
import _ from 'lodash';
import Moment from 'moment-timezone';

import EditIcon from '@material-ui/icons/Edit';
import ImageIcon from '@material-ui/icons/Image';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import EnhancedTableToolbar from '@apricityhealth/web-common-lib/components/EnhancedTableToolbar';
import Config from '@apricityhealth/web-common-lib/Config';
import PatientImage from '@apricityhealth/web-common-lib/components/PatientImage';
import defaultImage from '@apricityhealth/web-common-lib/uploadImage.jpg';
import VaccinationDialog from '../dialogs/VaccinationDialog'

const COVID19_VACCINE_PARENT = "630a09ad-b1f6-497e-ab9b-a77a7aa7aa9a";

export class VaccinationsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vaccinations: [],
            loading: null,
            modalOpen: false,
            confirmModalOpen: false,
            imageModalOpen: false,
            medications: {},
            editIndex: 0,
            create: false
        };
    }

    /////TUPLE vaccination
    //medicationId
    //Dose
    //Status
    //Record Image 

    //onMount, load medications, dataTypes
    componentDidMount() {
        this.loadData()
    }
    loadData() {
        this.setState({ loading: true })

        const planIds = (_.get(this,"props.appContext.state.patient.plans") || []).map((e) => e.planId);
        const getMedications = {
            url: Config.baseUrl + `${Config.pathPrefix}types/${planIds.join(',')}/medications/*?parentId=${COVID19_VACCINE_PARENT}`,
            method: 'GET',
            headers: { "Authorization": this.props.appContext.state.idToken }
        };

        console.log("getMedications:", getMedications );
        AxiosRequest(getMedications).then((response) => {
            console.log("getMedications response:", response );
            let dataIds = ['vaccination'];
            const patientId = _.get(this, "props.appContext.state.patientId");
            const getData = {
                url: Config.baseUrl + `${Config.pathPrefix}patients/${patientId}/data?dataId=${dataIds.join(',')}`,
                method: 'GET',
                headers: { "Authorization": this.props.appContext.state.idToken },
            };
    
            this.setState({medications: response.data.reduce((p,c) => {
                p[c.medicationId] = c.name;
                return p;
            }, {} )});

            console.log("loadData:", getData );
            return AxiosRequest(getData)
        }).then((response) => {
            const { medications } = this.state;
            console.log("loadData response:", response );
            const vaccinations = response.data.data.filter((e) => medications[e.data[0]] !== undefined && e.status !== 'preliminary' && e.data[1] !== 'NA');
            this.setState({ loading: false, vaccinations })
        }).catch((err) => {
            console.log("getPatientData error:", err);
        })
    }

    confirmDelete(evt, index) {
        this.setState({ confirmModalOpen: true, delIndex: index })
    }

    deleteVaccination(evt, index) {
        const self = this
        const patientId = _.get(this, "props.appContext.state.patientId");
        let vaccination = this.state.vaccinations[this.state.delIndex]
        self.setState({ modalOpen: false, confirmModalOpen: false, loading: true })
        let delData = {
            url: Config.baseUrl + `${Config.pathPrefix}patients/${patientId}/data/vaccination/${vaccination._id}`,
            method: 'DELETE',
            headers: { "Authorization": this.props.appContext.state.idToken },
        };
        console.log("delData Request:", delData);
        AxiosRequest(delData).then((result) => {
            console.log("delData Result:", result.data);
            this.props.appContext.setState({ hardReset: true });
            this.loadData()
        }).catch((err) => {
            console.error("saveData error:", err);
        });
    }
    handleClose = () => {
        this.setState({ modalOpen: null, imageModalOpen: null, confirmModalOpen: null })
    }
    handleOnAdd = () => {
        this.setState({ create: true, modalOpen: true })
    }
    editVaccination(evt, index) {
        this.setState({ create: false, modalOpen: true, editIndex: index })
    }
    viewImage = (evt, index) => {
        this.setState({ imageModalOpen: true, editIndex: index })
    }
    onDone = ({ _id, create, dose, medId, blobId, eventTime }) => {
        dose = Number(dose)

        let status = 1
        if (dose >= 3 || dose === 2 || (dose === 1 && medId === 'bc187d93-5d86-4648-8cbc-6e1ce5795fe7'))
            status = 2

        const tuple = [
            medId,
            dose,
            status,
            blobId,
            '',
            ''
        ]

        let editVaccine = create ? {} : this.state.vaccinations?.find(vaccine => vaccine._id = _id)
        if (!editVaccine) return

        editVaccine = {
            ...editVaccine,
            dataId: 'vaccination',
            data: tuple,
            eventTime: Moment(eventTime).toDate(),
            status: 'submitted',
        }
        editVaccine.source = editVaccine.source || [];
        if ( !editVaccine.source.find((e) => e.origin) ) {
            editVaccine.source.push( { origin: 'patient-web-client' } );
        }

        this.save(editVaccine)
    }

    save = (vaccineData) => {
        const self = this
        const patientId = _.get(this, "props.appContext.state.patientId"); 
        self.setState({ modalOpen: false, loading: true })
        let saveData = {
            data: vaccineData,
            url: Config.baseUrl + `${Config.pathPrefix}patients/${patientId}/data`,
            method: 'POST',
            headers: { "Authorization": this.props.appContext.state.idToken },
        };

        console.log("saveData Request:", saveData);
        AxiosRequest(saveData).then((result) => {
            console.log("saveData Result:", result.data);
            this.props.appContext.setState({ hardReset: true });
            this.loadData()
        }).catch((err) => {
            console.error("saveData error:", err);
        });
    }


    render() {
        const self = this;
        const { vaccinations, medications, modalOpen, imageModalOpen, confirmModalOpen, editIndex, create, loading } = self.state;
        const patientId = this.props.appContext && this.props.appContext.state && this.props.appContext.state.patientId

        let { appContext } = this.props

        let icon = React.cloneElement(this.props.icon, { fontSize: 'small', style: { fill: this.props.color } });

        let viewImageModal = imageModalOpen && <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={true}
        >
            <DialogContent>
            <PatientImage
                key={0}
                appContext={this.props.appContext}
                blobId={vaccinations && vaccinations[editIndex] && vaccinations[editIndex].data && vaccinations[editIndex].data[3]}
                defaultImage={defaultImage}
                size={440}
                alt={'vaccination label'}
                square={false}
                enableDelete={false}
            />
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='primary' onClick={this.handleClose.bind(this)}><T.span text='ok' /></Button>
            </DialogActions>
        </Dialog>

        let confirmDeleteModal = confirmModalOpen && <Dialog
            onClose={this.handleClose}
            open={true}
        >
            <DialogTitle id="confirm-delete" onClose={this.handleClose}>
                {T.translate('deleteVaccination')}
            </DialogTitle>
            <DialogContent>
                {T.translate('deleteVaccinationDetails')}
            </DialogContent>
            <div style={styles.buttonSection}>
                <Button style={styles.button} variant="contained" onClick={this.handleClose.bind(this)}>{T.translate('cancel')}</Button>
                <Button style={styles.button} variant="contained" onClick={this.deleteVaccination.bind(this)}>{T.translate('delete')}</Button>
            </div>
        </Dialog>

        let editCreateModal = modalOpen && <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={true}
        >
            <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                {T.translate('vaccinationDetails')}
            </DialogTitle>
            <DialogContent>
                <VaccinationDialog
                    meds={medications}
                    patientId={patientId}
                    vaccination={this.state.create ? {} : vaccinations[editIndex]}
                    appContext={appContext}
                    onCancel={this.handleClose.bind(this)}
                    onDone={this.onDone.bind(this)}
                    create={create}
                />
            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog>

        let vaccinationRows = vaccinations && vaccinations.length > 0 && vaccinations.map((vaccination, index) => {
            //const vaccineStatus = Number(vaccination.data?.[2])
            let dose = T.translate(`dose${vaccination.data[1]}`);
            let isValidated = !!vaccination.data[4];

            return <TableRow
                hover
                tabIndex={index}
                key={index}
            >
                <TableCell>
                    <IconButton
                        aria-label="Edit"
                        onClick={(evt) => this.editVaccination(evt, index)}
                        disabled={isValidated}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        aria-label="Delete"
                        onClick={(evt) => this.confirmDelete(evt, index)}
                        disabled={isValidated}
                    >
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
                <TableCell>
                    {(medications[vaccination.data?.[0] || ''] || 'N/A')}
                </TableCell>
                <TableCell>
                    {vaccination.eventTime ? Moment(vaccination.eventTime).utc(false).format('MM/DD/YYYY') : 'N/A'}
                </TableCell>
                <TableCell>
                    {vaccination.data?.[3] && <ImageIcon
                        onClick={(evt) => this.viewImage(evt, index)} />}
                </TableCell>
                <TableCell>
                    {dose}
                </TableCell>
            </TableRow>
        })

        return (
            <Grid id={this.props.id} container align="center" style={{ marginTop: 50 }}>
                <Grid item style={{ width: '100%', marginTop: 15, marginBottom: 15 }}>
                    <Paper style={{ textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 5, padding: 15 }} >

                        <Grid style={{ paddingLeft: 5, marginTop: -8 }} container direction="row" alignItems="center" justifyContent="space-between">
                            <Grid item style={{ marginRight: 10 }}>
                                <Grid container direction="row" alignItems="center">
                                    <Avatar style={{ width: 35, height: 35, border: `2px solid ${this.props.color}`, backgroundColor: 'white' }}>{icon}</Avatar>
                                    <span style={{ fontSize: 22, fontColor: '#666666', paddingLeft: 15 }}>{T.translate('vaccinations')}</span>
                                </Grid>
                            </Grid>
                            <Grid item >
                                <Grid container direction="row" alignItems="center">
                                    {loading ? <CircularProgress /> : ''}
                                    <EnhancedTableToolbar
                                        onAdd={this.handleOnAdd}
                                        title=''
                                        onDelete={() => { }}
                                        numSelected={0}
                                        disableDelete
                                    />
                                </Grid>
                                { /* {daysRemaining ? <T.span style={{ fontSize: 12, color: '#b81233'}} text={{key: 'clearanceDaysRemaining', daysRemaining }} /> : null} */}
                            </Grid>
                        </Grid>

                        {loading ? '' :
                            <Grid item xs={12} style={{ overflowX: 'scroll' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>{T.translate('vaccine')}</TableCell>
                                            <TableCell>{T.translate('date')}</TableCell>
                                            <TableCell>{T.translate('vacimage')}</TableCell>
                                            <TableCell>{T.translate('dose')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {vaccinationRows}
                                    </TableBody>
                                </Table>
                            </Grid>
                        }
                    </Paper>
                    {editCreateModal}
                    {viewImageModal}
                    {confirmDeleteModal}
                </Grid>
            </Grid>
        )
    }
}


const styles = {
    button: {
        margin: 5
    },
    buttonSection: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 10
    }
}
