import React from 'react';

import {
    CircularProgress,
    Grid,
    Button,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField,
    IconButton
} from '@material-ui/core/';

import DeleteIcon from '@material-ui/icons/Delete';

import T from 'i18n-react';
import EmailValidator from 'email-validator';

import { getPatientDelegates, addPatientDelegate, deletePatientDelegate } from '@apricityhealth/web-common-lib/utils/Services';
import { Logger } from '@apricityhealth/web-common-lib/utils/Logger';

import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

const log = new Logger();

class AddDelegateDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            firstName: '',
            lastName: '',
            email: ''
        }
    }

    render() {
        let disableOk = !EmailValidator.validate( this.state.email );
        return <Dialog open={true}>
            <DialogTitle><T.span text='addNewDelegate' /></DialogTitle>
            <DialogContent>
                <T.div text='addNewDelegateText' />
                <TextField id="delegate-firstName" label={<T.span text='firstName' />} style={{width: 200, margin: 5}} value={this.state.firstName} onChange={(e) => this.setState({firstName: e.target.value})} />
                <TextField id="delegate-lastName" label={<T.span text='lastName' />} style={{width: 200, margin: 5}} value={this.state.lastName} onChange={(e) => this.setState({lastName: e.target.value})} />
                <TextField id="delegate-email" label={<T.span text='email' />} style={{width: 400, margin: 5}} value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} />
            </DialogContent>
            <DialogActions>
                <Button id="delegate-cancel" color='primary' variant='contained' onClick={this.props.onCancel}><T.span text='cancel' /></Button>
                <Button id="delegate-ok" color='primary' disabled={disableOk} variant='contained' onClick={() => this.props.onDone(this.state)}><T.span text='ok' /></Button>
            </DialogActions>
        </Dialog>
    }
};

export class DelegatesView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            delegates: [],
            progress: null,
            dialog: null,
            error: null
        };
    }

    componentDidMount() {
        this.getContent();
    }

    getContent() {
        const { appContext } = this.props;
        const { patientId } = appContext.state;

        this.setState({progress: <CircularProgress size={20} />, error: null });
        getPatientDelegates(appContext, patientId).then((delegates) => {
            this.setState({progress: null, delegates });
        }).catch((err) => {
            this.setState({progress: null, error: getErrorMessage(err)});
        })
    }

    onAddDelegateDialog() {
        const { appContext } = this.props;
        const { patientId } = appContext.state;

        log.debug('onAddDelegateDialog');
        this.setState({dialog: <AddDelegateDialog onCancel={() => this.setState({dialog: null})} 
            onDone={(delegate) => {
                this.setState({dialog: null, progress: <CircularProgress size={20} />});
                addPatientDelegate(appContext, patientId, { ...delegate, groups: ['patients'] } ).then(() => {
                    this.getContent();
                }).catch((err) => {
                    this.setState({progress: null, error: getErrorMessage(err)});
                })
            }}
        />})
    }

    onDeleteDelegateDialog(user) {
        const { appContext } = this.props;
        const { patientId } = appContext.state;

        log.debug('onDeleteDelegateDialog:', user );
        this.setState({dialog: <Dialog open={true}>
            <DialogTitle><T.span text='confirmDeleteDelegate' /></DialogTitle>
            <DialogContent>
                <T.span text={{key: 'deleteDelegateConfirmation', firstName: user.firstName, lastName: user.lastName, email: user.email}} />
            </DialogContent>
            <DialogActions>
                <Button id="delegate-deleteCancel" color='primary' variant='contained' onClick={() => this.setState({dialog: null})}><T.span text='cancel' /></Button>
                <Button id="delegate-deleteConfirm" color='primary' variant='contained' onClick={() => {
                    this.setState({dialog: null, progress: <CircularProgress size={20} />, error: null});
                    deletePatientDelegate( appContext, patientId, user.username ).then(() => {
                        this.getContent();
                    }).catch((err) => {
                        this.setState({progress: null, error: getErrorMessage(err)});
                    })
                }}><T.span text='confirm' /></Button>
            </DialogActions>
        </Dialog>});
    }

    render() {
        let { progress, delegates, dialog, error } = this.state;

        let gridItems = [];
        if ( delegates ) {
            for(let i=0;i<delegates.length;++i) {
                let user = delegates[i].user;

                gridItems.push(<div key={i} style={{margin: 5}}>
                    <Grid container wrap="nowrap" direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item>
                            <Tooltip title={<T.span text='deleteDelegate' />}><IconButton id="remove-delegate" onClick={this.onDeleteDelegateDialog.bind(this,user)}><DeleteIcon /></IconButton></Tooltip>
                        </Grid>
                        <Grid item>
                            {(user.firstName || user.lastName) && <b>{`${user.firstName} ${user.lastName}, `}</b>}
                            {user.email}
                        </Grid>
                    </Grid>
                </div>);
            }
        }

        return ( 
            <div id={this.props.id}>
                {gridItems}
                <div>
                    <Button id = "add-delegate" color="primary" variant="contained" onClick={this.onAddDelegateDialog.bind(this)} style={{margin: 5}}>
                        <T.span text="addDelegate" />
                    </Button>
                    {progress}
                </div>
                {dialog}
                <span style={{color: 'red'}}>{error}</span>
            </div>
        )
    }
}

export default DelegatesView;