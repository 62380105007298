import React, { Component } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Grid,
    Avatar,
    TextField,
} from '@material-ui/core/';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

import Moment from 'moment-timezone';
import DateFnsUtils from '@date-io/date-fns';

import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import T from 'i18n-react';

export class RequestAppointmentDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appointmentDate: undefined,
            appointmentTime:'08:30',
            progress:null
        };
    }

    send() {
        //send message 
        const { appContext } = this.props;
        let {appointmentDate, appointmentTime} = this.state
        console.log(appContext.state.timezone)

        let formattedTime = Moment(appointmentTime, 'HH:mm').tz(appContext.state.timezone).format('h:mm A z'); 
        const patientName = appContext.state.patient.firstName + ' ' + appContext.state.patient.lastName 
        const formattedRequestDate = appointmentDate.format('MM/DD/YYYY') + ' ' + formattedTime 

        //TODO - these don't work  -  make custom parser
        //let message = T.translate('requestAppointmentMessage', {patientName: patientName, formattedRequestDate:formattedRequestDate })   
        //let message2 = T.translate({key:'requestAppointmentMessage',  patientName: patientName, formattedRequestDate:formattedRequestDate })   

        let message = T.translate('requestAppointmentMessage')
        message = patientName + " " + message + " " + formattedRequestDate 
        this.setState({progress: <CircularProgress style={{margin:'auto'}}/>});

        let data = {
            patientId : appContext.state.patient.patientId,
            providerId: appContext.state.provider.providerId,
            to: appContext.state.provider.providerId,
            subject: T.translate('requestAppointment'),
            message: message
        }

        const request = {
            data: data,
            url: Config.baseUrl + `${Config.pathPrefix}messaging/message`,
            method: 'POST',
            headers: { "Authorization": appContext.state.idToken }
        }

        AxiosRequest(request).then((response) => {
            this.setState({progress: null, });
            if (this.props.onDone){
                this.props.onDone()
            }
        }).catch( (err) => {
            console.error("sendMessage error:", err );
            this.setState({progress: null, });
            if (this.props.onDone){
                this.props.onDone()
            }
        })
    }

    cancel() {
        if (this.props.onCancel)
            this.props.onCancel()
    }

    render() {
        let self = this;
        let {progress, appointmentDate, appointmentTime} = this.state
        let submitDisabled = appointmentDate === undefined || appointmentTime === undefined;
        let icon = React.cloneElement( this.props.icon, {fontSize: 'small', style: {fill: this.props.color} } );
        
        return <Dialog open={true} fullWidth={true} maxWidth={'xs'} style={{background: 'rgba(0,0,0,0.6)'}}>
            <DialogTitle>
                <Grid container direction="row" alignItems="center">
                    <Grid item style={{ marginRight: 10 }}>
                        <Avatar style={{ width: 35, height: 35, border: `2px solid ${this.props.color}`, backgroundColor: 'white' }}>{icon}</Avatar>
                    </Grid>
                    <Grid item >
                        <T.span text="requestAppointment"/>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent >
                <div >
                    <T.span text="requestAppointmentInstructions"/>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <br/>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            minDate={new Date()}
                            format="MM/dd/yyyy"
                            margin="normal"
                            style={styles.text}
                            id="date-picker-inline"
                            label={T.translate('appointmentDateLabel')}
                            value={appointmentDate ? appointmentDate  : null}
                            onChange={(appointmentDate) => { let aDate=Moment(appointmentDate); self.setState({ appointmentDate:aDate}); }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            />
                        <br/>
                        <TextField
                            id="time"
                            label={T.translate('appointmentTimeLabel')}
                            style={styles.text}
                            type="time"
                            defaultValue="08:30"
                            margin="normal"
                            onChange={(event) => {let aTime=Moment(event.target.value, 'HH:mm'); self.setState({appointmentTime:aTime})}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                        />
                </MuiPickersUtilsProvider>
                    {progress}
                </div>
            </DialogContent>
            <DialogActions style={{justifyContent:'space-between', margin:'10px'}}>
                <Button color="primary" variant="contained" onClick={this.cancel.bind(this)}><T.span text='cancel'/></Button>
                <Button disabled={submitDisabled} color="primary" variant="contained" onClick={this.send.bind(this)}><T.span text='confirm'/></Button>
            </DialogActions>
        </Dialog>

    }
};


const styles = {
    text:{
        minWidth:'200px'
    }
}

export default RequestAppointmentDialog ;

