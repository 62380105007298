import { createTheme } from '@material-ui/core/styles';
import { blueGrey, red, grey } from '@material-ui/core/colors';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: blueGrey,
    secondary: grey,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  overrides: {
    MuiButton: {
      root: {
        background: 'linear-gradient(45deg, #CFD8DC 30%, #B0BEC5 90%)',
        borderRadius: 3,
        border: 0,
        height: 48,
        color: 0xFFF,
        padding: '0 30px',
        boxShadow: '0 3px 5 px 2px rgba(255, 105, 135, .30)',
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: blueGrey[800],
        color: 0xFFF,
      },
    },
    MuiTable: {
      root: {
        background: blueGrey[800],
        color: 0xFFF,
      },
    },
    MuiTypography: {
      root: {
        color: 0xFFF,
      }
    },
    MuiToolbar: {
      root: {
        background: blueGrey[300],
        color: 0x000,
      }
    },
    MuiFormLabel: {
      root: {
        color: blueGrey[400],
      }
    },
    MuiInput: {
      root: {
        color: blueGrey[100],
      }
    },
  }
});
export default theme;