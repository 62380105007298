import React from 'react';
import {
    Paper
} from '@material-ui/core';

function getQuery() {
    return new URLSearchParams(window.location.search);
}

export class WebView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            url: props.url || '',
            height: window.innerHeight,
            attempts: 0
        };
    }

    componentDidMount() {
        let query = getQuery();
        this.setState({url: query.get('url') });
    }

    onLoad() {
        console.log("WebView.onLoad:", this.iFrame.src );
    }

    render() {
        const { url, height, attempts } = this.state;
        if ( url ) {
            return <Paper style={{width: '100%', marginTop: 50}}>
                <iframe ref={(ref) => this.iFrame = ref} title='WebView' src={url} style={{width: '99%', height, border: 0}} 
                    onLoad={this.onLoad.bind(this)} 
                    onErrorCapture={(err) => {
                        console.error("onErrorCapture:", err );
                    }}
                    onError={(err) => {
                        console.error("onError:", err );
                        if ( attempts < 3) {
                            console.log(`Retrying to load iframe attempts at ${attempts} of 3`);
                            setInterval(() => {
                                this.setState({attempts: attempts + 1, url });
                            }, 250 )
                        }
                    }}
                />;
            </Paper>
        }
        
        return null;
    }
}

export default WebView;
