import React, { useEffect, useCallback } from 'react';
import { useSetState } from 'react-use';
import {
    CircularProgress,
    Grid,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Avatar
} from '@material-ui/core';

import T from 'i18n-react';
import EditIcon from '@material-ui/icons/Edit';
import ImageIcon from '@material-ui/icons/Image';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import {v4 as uuid } from 'uuid'
import uuidValidate from 'uuid-validate';
import Moment from 'moment-timezone';

import EnhancedTableToolbar from '@apricityhealth/web-common-lib/components/EnhancedTableToolbar';
import PatientImage from '@apricityhealth/web-common-lib/components/PatientImage';
import defaultImage from '@apricityhealth/web-common-lib/uploadImage.jpg';
import { deletePatientData, getPatientData, savePatientData, runPatientDetect } from '@apricityhealth/web-common-lib/utils/Services';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

import CovidTestDialog from '../dialogs/CovidTestDialog'

const RTW_PLANS = [
    "63575a2f-2c1e-4e2f-a0e2-a908a1ae0354",
    "f318684a-71c9-4e52-af2b-f58205c43f55"
];

// const testSampleTypes = [
//     {value: 1, label: 'noseOrThroatSwab', hidden: true},
//     {value: 2, label: 'saliva', hidden: true},
//     {value: 3, label: 'pcrMolecularTesting'},
//     {value: 4, label: 'rapidAntigenTesting'},
// ]

const testResultTypes = [
    {value: 0, label: 'negative'},
    {value: 1, label: 'positive'},
    {value: 'NaN', label: 'indeterminant'}
]

export default function CovidTestsView({
    appContext,
    id,
    icon,
    color,
}) {
    const { patient } = appContext.state
    let plan = patient.plans.find((k) => RTW_PLANS.indexOf(k.planId) >= 0 );
    if (!plan ) throw new Error("Patient not on a RTW plan!");

    const [state, setState] = useSetState({
        tests: [],
        userId: appContext.state.userId,
        patientId: appContext.state.patientId,
        planId: plan.planId,
        loading: false,
        dialog: null,
    })

    const loadData = useCallback(async () => {
        const { patientId } = state;

        let dataIds = ['positiveForCovid19'];

        setState({ loading: true, error: null })

        try {
            let { data: tests } = await getPatientData(appContext, patientId, [ `dataId=${dataIds.join(',')}`])
            tests = tests.filter((e) => e.status !== 'preliminary');          // filter our any preliminary data
            console.debug("tests:", tests );
            setState({ tests, loading: false });
        } catch (err) {
            console.debug("getPatientData error:", err);
            setState({loading: false, error: getErrorMessage(err)});
        }
    }, [])

    useEffect(() => {
        loadData()
    }, [])

    const onCloseDialog = () => {
        setState({ dialog: null });
    }

    const onViewImage = ( blobId ) => {
        let dialog = <Dialog onClose={onCloseDialog} aria-labelledby="customized-dialog-title" open={true}>
            <DialogContent>
                <PatientImage
                    key={0}
                    appContext={appContext}
                    blobId={blobId}
                    defaultImage={defaultImage}
                    size={440}
                    alt={'covid test label'}
                    square={false}
                    enableDelete={false}
                />
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='primary' onClick={onCloseDialog}><T.span text='ok' /></Button>
            </DialogActions>
        </Dialog>;

        setState({ dialog });
    }

    const onDeleteTestConfirmed = ( test ) => {
        const { patientId } = state
        console.debug("onDeleteTestConfirmed:", test)

        setState({ dialog: null, loading: true, error: null })

        deletePatientData(appContext, patientId, '*', test._id).then(() => {
            loadData()
        }).catch((err) => {
            setState({ loading: false, error: getErrorMessage(err) })
        });
    }

    const onDeleteTest = ( test ) => {
        let dialog = <Dialog onClose={onCloseDialog} open={true}>
            <DialogTitle id="confirm-delete">
                <T.span text='deleteCovidTest' />
            </DialogTitle>
            <DialogContent>
                <T.span text='deleteCovidTestDetails' />
            </DialogContent>
            <div style={styles.buttonSection}>
                <Button style={styles.button} variant="contained" onClick={onCloseDialog}><T.span text="cancel" /></Button>
                <Button style={styles.button} variant="contained" onClick={() => onDeleteTestConfirmed(test)}><T.span text="delete" /></Button>
            </div>
        </Dialog>;

        setState({ dialog });
    }

    const onDone = async (test) => {
        console.log("onDone:", test)
        
        const { sessionId, date, blobId, sample, testResult } = test;
        const { tests, userId, patientId, planId } = state;

        let saveData = tests.find((k) => k.sessionId === test.sessionId);
        if (! saveData ) {
            saveData = {
                data: [],
                dataId: 'positiveForCovid19',
                patientId,
                planId,
                sessionId,
                source: [ { origin: 'patient-web-client' }, { userId, userType: 'patient' } ],
                status: 'submitted'
            }
        } 
        saveData.eventTime = Moment(date).startOf('day').toDate();
        saveData.data[0] = testResult;
        saveData.data[1] = date;
        saveData.data[2] = sample;
        saveData.data[3] = blobId;
        saveData.data[4] = '';
        saveData.data[5] = '';

        setState({ loading: true, dialog: null, error: null });

        try {
            await savePatientData(appContext, patientId, saveData);
            await runPatientDetect(appContext, patientId);
            await loadData();
        } catch (err) {
            setState({ loading: false, error: getErrorMessage(err) });
        }
    }

    const onEditTest = (test) => {
        console.debug("onEditTest:", test );
        let testResult = Number(test.data[0]);
        let testDate = test.data[1];
        let testSample = Number(test.data[2]);
        let testImage = test.data[3];

        let dialog = <CovidTestDialog appContext={appContext} 
            test={{
                sessionId: test.sessionId,
                blobId: testImage && uuidValidate(testImage) ? testImage : '', 
                testResult, 
                date: testDate || '',
                sample: testSample
            }}
            onDone={onDone}
            onCancel={onCloseDialog}
        />;
        setState({dialog});
    }

    const onAddTest = () => {
        console.debug("onAddTest");
        let dialog = <CovidTestDialog appContext={appContext} 
            test={{ sessionId: uuid(), blobId: '', testResult: 0, date: '', sample: 0 }}
            onDone={onDone}
            onCancel={onCloseDialog}
        />;
        setState({dialog});
    }

    const { tests, loading, dialog, error } = state;

    let testsRows = tests.map((test, index) => {
        let testResult = Number(test.data[0]);
        let testDate = test.data[1];
        let testImage = test.data[3];
        let isValidated = !!test.data[4];

        if ( isNaN(testResult) ) testResult = 'NaN';
        testResult = testResultTypes.find(item => item.value === testResult)?.label || 'Unknown';

        return (<TableRow hover tabIndex={index} key={index}>
            <TableCell>
                <IconButton
                    aria-label="Edit"
                    onClick={() => onEditTest(test)}
                    disabled={isValidated}
                >
                    <EditIcon />
                </IconButton>
                <IconButton
                    aria-label="Delete"
                    onClick={() => onDeleteTest(test)}
                    disabled={isValidated}
                >
                    <DeleteIcon />
                </IconButton>
            </TableCell>
            <TableCell>
                <T.span text={testResult} />
            </TableCell>
            <TableCell>
                {testDate ? Moment(testDate).utc(false).format('MM/DD/YYYY') : ''}
            </TableCell>
            <TableCell>
                {testImage && uuidValidate(testImage) && <ImageIcon
                    onClick={() => onViewImage(testImage)} />}
            </TableCell>
        </TableRow>
        )
    })

    const avatarIcon = React.cloneElement(icon, { fontSize: 'small', style: { fill: color } });

    return (
        <Grid id={id} container align="center" style={{ marginTop: 50 }}>
            <Grid item style={{ width: '100%', marginTop: 15, marginBottom: 15 }}>
                <Paper style={{ textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 5, padding: 15 }} >

                    <Grid style={{ paddingLeft: 5, marginTop: -8 }} container direction="row" alignItems="center" justifyContent="space-between">
                        <Grid item style={{ marginRight: 10 }}>
                            <Grid container direction="row" alignItems="center">
                                <Avatar style={{ width: 35, height: 35, border: `2px solid ${color}`, backgroundColor: 'white' }}>{avatarIcon}</Avatar>
                                <span style={{ fontSize: 22, fontColor: '#666666', paddingLeft: 15 }}><T.span text="covidTests" /></span>
                            </Grid>
                        </Grid>
                        <Grid item >
                            <Grid container direction="row" alignItems="center">
                                {loading ? <CircularProgress /> : ''}
                                <EnhancedTableToolbar
                                    onAdd={onAddTest}
                                    title=''
                                    onDelete={() => { }}
                                    numSelected={0}
                                    disableDelete
                                />
                            </Grid>
                            { /* {daysRemaining ? <T.span style={{ fontSize: 12, color: '#b81233'}} text={{key: 'clearanceDaysRemaining', daysRemaining }} /> : null} */}
                        </Grid>
                    </Grid>

                    {loading ? '' :
                        <Grid item xs={12} style={{ overflowX: 'scroll' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>{T.translate('testResult')}</TableCell>
                                        <TableCell>{T.translate('date')}</TableCell>
                                        <TableCell>{T.translate('photo')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {testsRows}
                                </TableBody>
                            </Table>
                        </Grid>
                    }
                </Paper>
                {dialog}
                <span style={{color: 'red'}}>{error}</span>
            </Grid>
        </Grid>
    )
}


const styles = {
    button: {
        margin: 5
    },
    buttonSection: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 10
    }
}
