import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core';

import T from 'i18n-react';

import SubscriptionView from '../views/SubscriptionView';

export class SubscriptionDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return <Dialog open={true}>
            <DialogTitle><T.span text="subscription" /></DialogTitle>
            <DialogContent>
                <SubscriptionView appContext={this.props.appContext} onDone={this.props.onClose} />
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='primary' onClick={this.props.onClose}><T.span text='close' /></Button>
            </DialogActions>
        </Dialog>;
    }
};