import React, { Component } from 'react';
import {
    Tabs,
    Tab,
    Paper,
    FormControl,
    InputLabel,
    CircularProgress,
    TextField,
    Select,
    MenuItem,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Grid,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Avatar
} from '@material-ui/core/';

import CreateEmailIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';

import Moment from 'moment';

import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import T from 'i18n-react';

export class MessagesView extends Component {
    constructor(props) {
        super(props);
        const { appContext } = this.props
        this.state = {
            messages: [],
            messagesSent: [],
            patientProviders: [],
            selectedProvider: '',
            viewState: 'read',
            progress: null,
            messageSubject: '',
            messageBody: '',
            inboxOrSent: 0
        };
        if (appContext.state.patient && appContext.state.patient.primaryProviderId)
            this.state.selectedProvider = appContext.state.patient.primaryProviderId
    }

    componentDidMount() {
        this.loadMessages();
    }

    getProviderName(providerId) {
        //TODO  - get patient providers from the app context if it exists, 
        //otherwise use your patient providers
        let { patientProviders } = this.state
        let name = ''

        let provider = patientProviders.find((providerItem) => {
            return providerItem.provider && providerItem.provider.providerId === providerId
        })
        if (provider) {
            provider = provider.provider
            if (provider.firstName && provider.lastName) {
                name = provider.firstName + ' ' + provider.lastName
                if (provider.title) {
                    name = provider.title + ' ' + name
                }
                if (provider.suffix) {
                    name += ' ' + provider.suffix;
                }
                if (provider.credentials) {
                    name += ', ' + provider.credentials
                }
            }
        }
        return name
    }


    loadMessages() {
        const self = this;
        const { appContext } = this.props
        const { patientId } = appContext.state;

        let getPatientProviders = {
            url: Config.baseUrl + `${Config.pathPrefix}providers/patient/${patientId}?includeOrg=true&inactive=false`,
            method: 'GET',
            headers: { "Authorization": appContext.state.idToken },
        }
        console.log("getPatientProviders request:", getPatientProviders);
        let getPatientMessages = {
            url: Config.baseUrl + `${Config.pathPrefix}messaging/message/patient/${patientId}?markRead=false`,
            method: 'GET',
            headers: { "Authorization": appContext.state.idToken },
        }
        console.log("getPatientMessages request:", getPatientMessages);
        let getPatientSentMessages = {
            url: Config.baseUrl + `${Config.pathPrefix}messaging/message/patient/${patientId}?getSent=true`,
            method: 'GET',
            headers: { "Authorization": appContext.state.idToken },
        }
        console.log("getPatientSentMessages request:", getPatientSentMessages);
        self.setState({ progress: <CircularProgress size={30} /> });
        Promise.all([AxiosRequest(getPatientProviders), AxiosRequest(getPatientMessages), AxiosRequest(getPatientSentMessages)]).then((results) => {
            console.log("getPatientProviders result:", results[0].data);
            const providers = results[0].data.providers.filter((e) => e.provider).sort((a, b) => {
                function validate(prop) {
                    if (a.provider[prop] === undefined) a.provider[prop] = '';
                    if (b.provider[prop] === undefined) b.provider[prop] = '';
                }
                validate('title');
                validate('firstName');
                validate('lastName');

                if (a.provider.title !== b.provider.title) return a.provider.title.toLowerCase().localeCompare(b.provider.title.toLowerCase());
                if (a.provider.firstName !== b.provider.firstName) return a.provider.firstName.toLowerCase().localeCompare(b.provider.firstName.toLowerCase());
                if (a.provider.lastName !== b.provider.lastName) return a.provider.lastName.toLowerCase().localeCompare(b.provider.lastName.toLowerCase());
                return 0;
            });
            const messages = results[1].data.messages
            console.log("getPatientMessages result:", messages);
            const messagesSent = results[2].data.messages
            console.log("getPatientSentMessages result:", messagesSent);
            self.setState({ progress: null, messages, messagesSent, patientProviders: providers });
        }).catch((err) => {
            console.error("loadContent error:", err);
            self.setState({ progress: null, });
        });
    }

    onProviderSelected(e) {
        this.setState({ selectedProvider: e.target.value })
    }
    onTabSelected(e) {
        let { inboxOrSent } = this.state
        if (inboxOrSent === 0) {
            inboxOrSent = 1
        } else {
            inboxOrSent = 0
        }
        this.setState({ inboxOrSent })
    }

    replyPressed(message) {
        //selectedProvider
        let selectedProvider = ''
        if (message.provider && message.provider.providerId)
            selectedProvider = message.provider.providerId
        let messageSubject = 'Re: ' + message.subject
        const lines = message.message.split('\n').map(line => `> ${line}`);
        const formatted = lines.join('\n');

        this.setState({
            selectedProvider: selectedProvider,
            messageSubject: messageSubject,
            messageBody: `\n\n${formatted}`,
            viewState: 'write',
            dialog: null
        })
    }

    showReplyButton(message) {
        const self = this
        const { patientProviders } = this.state
        //only reply to available providers
        if (message.provider && message.provider.providerId) {
            let providerId = message.provider.providerId
            let provider = patientProviders.find((providerItem) => {
                return providerItem.provider && providerItem.provider.providerId === providerId
            })
            if (provider) {
                return (
                    <Button color="primary" variant="contained" style={styles.button} onClick={(e) => { self.replyPressed(message); }} ><T.span text="reply"></T.span></Button>
                )
            } else {
                return null
            }
        }
    }

    markAsRead(message) {
        let { inboxOrSent, messages } = this.state
        let { appContext } = this.props

        //ensure we are in inbox and message read is false
        if (inboxOrSent === 0 && message.read === false) {
            //update local message to reflect read
            messages.forEach((messageItem) => {
                if (messageItem._id === message._id) {
                    messageItem.read = true
                }
            })
            this.setState({ messages })

            //tell server message has been read
            let markRead = {
                url: Config.baseUrl + `${Config.pathPrefix}messaging/message/${message._id}/updateMessageReadFlag/true`,
                method: 'POST',
                headers: { "Authorization": appContext.state.idToken },
            }

            AxiosRequest(markRead).then((response) => {
                console.log("markRead result:", response.data);
            }).catch((err) => {
                console.error("markRead  error:", err);
            })
        }
    }

    deleteMessage(message) {
        const self = this;
        const { appContext } = this.props
        if (message._id) {
            //{{base_url}}/{{pathPrefix}}messaging/message/{{messageId}}
            let deletePatientMessage = {
                url: Config.baseUrl + `${Config.pathPrefix}messaging/message/${message._id}`,
                method: 'DELETE',
                headers: { "Authorization": appContext.state.idToken },
            }
            self.setState({ progress: <CircularProgress size={30} /> });
            AxiosRequest(deletePatientMessage).then((response) => {
                this.loadMessages()
                this.setState({ dialog: null, viewState: 'read' })
            }).catch((err) => {
                console.error("listRooms error:", err);
                self.setState({ room: null });
            });
        }

    }


    openMessage(index) {
        let { inboxOrSent, messages, messagesSent } = this.state
        const self = this
        let message = {}
        if (inboxOrSent === 0) {
            message = messages[index]
        } else {
            message = messagesSent[index]
        }
        //mark as read
        const timestamp = Moment(message.createDate).format("MM-DD-YYYY h:mm A")

        let from = ''
        if (message.provider && message.provider.providerId) {
            from = this.getProviderName(message.provider.providerId)
            if (!from) {
                from = message.provider.firstName + ' ' + message.provider.lastName
            }
        }

        let toFrom = <T.span text="From"></T.span>
        if (inboxOrSent === 1) {
            toFrom = <T.span text="To"></T.span>
        }
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true} onClose={() => self.setState({ dialog: null })}
                    fullWidth={true} maxWidth={'xs'} style={{ background: 'rgba(0,0,0,0.6)' }}
                >
                    <DialogTitle style={{ paddingRight: '20px' }}>
                        {message.subject}
                        <IconButton style={styles.deleteButton} onClick={() => { this.deleteMessage(message) }}>
                            <DeleteIcon />
                        </IconButton>

                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ minWidth: 200 }}>
                            <br />
                            {toFrom}: {from}
                            <br />
                            Date: {timestamp}
                            <br />
                            Subject: {message.subject}
                            <br />
                            <br />
                            <span style={{ fontSize: 16, whiteSpace: 'pre-line' }}>{message.message}</span>
                            <br />
                            <br />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'space-between', margin: '10px' }}>
                        <Button color="primary" variant="contained" style={styles.button} onClick={(e) => { self.setState({ dialog: null }); }} ><T.span text="close"></T.span></Button>
                        {this.showReplyButton(message)}
                    </DialogActions>
                </Dialog>
            </div>
        });
        self.markAsRead(message)


    }
    sendMessage() {
        const { selectedProvider, messageSubject, messageBody } = this.state
        const { appContext } = this.props
        const { patientId } = appContext.state;

        //TODO - ensure goods are here, also function to disable send button
        //TODO - discontinue if progress isn't null
        let data = {
            patientId: patientId,
            providerId: selectedProvider,
            to: selectedProvider,
            subject: messageSubject,
            message: messageBody
        }
        //{{base_url}}/{{pathPrefix}}messaging/message
        const request = {
            data: data,
            url: Config.baseUrl + `${Config.pathPrefix}messaging/message`,
            method: 'POST',
            headers: { "Authorization": appContext.state.idToken }
        }
        console.log(`sendMessage request `, request);

        AxiosRequest(request).then((response) => {
            this.loadMessages()
            this.setState({ messageBody: '', messageSubject: '', viewState: 'read' })
        }).catch((err) => {
            console.error("sendMessage error:", err);
            this.setState({ progress: null, });
        })
    }
    render() {
        const { appContext } = this.props;
        const { patient } = appContext.state;
        const { dialog, viewState, messageSubject, messageBody, inboxOrSent, messages, messagesSent, progress } = this.state
        const self = this

        const selectProvider = () => {
            const { patientProviders, selectedProvider } = this.state
            const patientProviderItems = []
            const self = this
            patientProviders.forEach((providerObj) => {
                let provider = providerObj.provider
                if (provider) {
                    let providerName = this.getProviderName(provider.providerId)
                    patientProviderItems.push(<MenuItem key={provider.providerId} value={provider.providerId}>{providerName}</MenuItem>);
                }
            })
            return (
                <FormControl >
                    <InputLabel><T.span text="to"></T.span>:</InputLabel>
                    <Select
                        style={{ width: 200 }}
                        value={selectedProvider}
                        onChange={self.onProviderSelected.bind(self)}
                    >
                        {patientProviderItems}
                    </Select>
                </FormControl>
            )
        }
        const messagesHeader = () => {
            if (viewState === 'read') {
                return (
                    <div style={styles.inboxHeader}>
                        <Tabs
                            onChange={this.onTabSelected.bind(this)}
                            value={inboxOrSent}
                        >
                            <Tab
                                label={<T.span text="inbox"></T.span>}>
                            </Tab>
                            <Tab
                                label={<T.span text="sent"></T.span>}>
                            </Tab>
                        </Tabs>
                        <div>{progress}</div>
                        <div>
                            <IconButton disabled={!patient || patient.inactive === true} onClick={() => { this.setState({ viewState: 'write' }) }} >
                                <CreateEmailIcon />
                            </IconButton>
                        </div>
                    </div>
                )
            }
        }

        const getInboxOrSent = () => {
            const emails = []
            if (inboxOrSent === 0) {
                messages.forEach((message, index) => {

                    const timestamp = Moment(message.createDate).format("MM-DD h:mm A")

                    let from = ''
                    if (message.provider) {
                        from = this.getProviderName(message.provider.providerId)
                        if (!from) {
                            from = message.provider.firstName + ' ' + message.provider.lastName
                        }
                    }

                    if (!message.deleted) {
                        let row = (
                            <TableRow
                                hover
                                onClick={event => this.openMessage(index)}
                                tabIndex={index}
                                key={index}
                                selected={!message.read}
                            >
                                <TableCell style={styles.cellItems}>
                                    {from}
                                </TableCell>
                                <TableCell style={styles.cellItems}>
                                    {message.subject}
                                </TableCell>
                                <TableCell style={styles.cellItems}>
                                    {timestamp}
                                </TableCell>
                            </TableRow>
                        )
                        emails.push(row)
                    }
                })

                return (
                    <Paper style={styles.inboxSentTable}>
                        <Table
                            size={'small'}
                            padding={'none'}
                        >
                            <TableHead>
                                <TableRow >
                                    <TableCell style={styles.cellItems}><T.span text="from"></T.span></TableCell>
                                    <TableCell style={styles.cellItems}><T.span text="subject"></T.span></TableCell>
                                    <TableCell style={styles.cellItems}><T.span text="date"></T.span></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {emails}
                            </TableBody>
                        </Table>
                    </Paper>
                )
            } else {
                messagesSent.forEach((message, index) => {
                    const timestamp = Moment(message.createDate).format("MM-DD-YYYY h:mm A")
                    let from = ''
                    if (message.provider) {
                        from = this.getProviderName(message.provider.providerId)
                        if (!from) {
                            from = message.provider.firstName + ' ' + message.provider.lastName
                        }
                    }
                    if (!message.deleted) {
                        let row = (
                            <TableRow
                                hover
                                onClick={event => this.openMessage(index)}
                                tabIndex={index}
                                key={index}
                            >
                                <TableCell style={styles.cellItems}>
                                    {from}
                                </TableCell>
                                <TableCell style={styles.cellItems}>
                                    {message.subject}
                                </TableCell>
                                <TableCell style={styles.cellItems}>
                                    {timestamp}
                                </TableCell>
                            </TableRow>
                        )
                        emails.push(row)
                    }
                })

                return (
                    <Paper style={styles.inboxSentTable}>
                        <Table
                            padding={'none'}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell style={styles.cellItems}><T.span text="to"></T.span></TableCell>
                                    <TableCell style={styles.cellItems}><T.span text="subject"></T.span></TableCell>
                                    <TableCell style={styles.cellItems}><T.span text="date"></T.span></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {emails}
                            </TableBody>
                        </Table>
                    </Paper>
                )
            }
        }

        if (viewState === 'read') {
            let icon = React.cloneElement(this.props.icon, { fontSize: 'small', style: { fill: this.props.color } });
            return (
                <Grid id={this.props.id} align="center" className='messages' style={{ marginTop: 70 }}>
                    <Grid item style={{ width: '100%', marginTop: 15, marginBottom: 15 }}>
                        <Paper style={{ textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 5, padding: 15 }} >
                            <div>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item style={{ marginRight: 10 }}>
                                        <Avatar style={{ width: 35, height: 35, border: `2px solid ${this.props.color}`, backgroundColor: 'white' }}>{icon}</Avatar>
                                    </Grid>
                                    <Grid item >
                                        <span style={{ fontSize: 22, fontColor: '#666666' }}>{this.props.label}</span>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                    {messagesHeader()}
                    {getInboxOrSent()}
                    {dialog}
                </Grid>
            )
        } else {
            //select provider
            //
            let icon = React.cloneElement(this.props.icon, { fontSize: 'small', style: { fill: this.props.color } });
            return (
                <Grid id={this.props.id} align="center" className='messages' style={{ marginTop: 70 }}>
                    <Grid item style={{ width: '100%', marginTop: 15, marginBottom: 15 }}>
                        <Paper style={{ textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 5, padding: 15 }} >
                            <div>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item style={{ marginRight: 10 }}>
                                        <Avatar style={{ width: 35, height: 35, border: `2px solid ${this.props.color}`, backgroundColor: 'white' }}>{icon}</Avatar>
                                    </Grid>
                                    <Grid item >
                                        <span style={{ fontSize: 22, fontColor: '#666666' }}><T.span text="newMessage" /></span>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid container justifyContent='center' spacing={8} >
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <Paper style={styles.composeBody}>
                                <Grid container justifyContent='flex-start' spacing={8} >
                                    <Grid item xs={12}>
                                        {selectProvider()}
                                        <br />
                                        <FormControl >
                                            <InputLabel><T.span text="subject"></T.span>:</InputLabel>
                                            <Select
                                                label="Message Subject"
                                                value={messageSubject}
                                                placeholder="Message Subject"
                                                onChange={(e) => { let subject = e.target.value; self.setState({ messageSubject: subject }) }}
                                                style={{
                                                    minWidth: '200px',
                                                    marginBottom: '10px'
                                                }}
                                            >
                                                <MenuItem
                                                    key={0}
                                                    value={T.translate("messageSubjectAppointment")}
                                                >
                                                    <T.span text="messageSubjectAppointment"></T.span>
                                                </MenuItem>
                                                <MenuItem
                                                    key={1}
                                                    value={T.translate("messageSubjectMedication")}
                                                >
                                                    <T.span text="messageSubjectMedication"></T.span>
                                                </MenuItem>
                                                <MenuItem
                                                    key={2}
                                                    value={T.translate("requestAppointment")}
                                                >
                                                    <T.span text="requestAppointment"></T.span>
                                                </MenuItem>
                                                <MenuItem
                                                    key={3}
                                                    value={T.translate("messageSubjectOther")}
                                                >
                                                    <T.span text="messageSubjectOther"></T.span>
                                                </MenuItem>
                                            </Select>
                                        </FormControl >
                                        <br />

                                        <TextField
                                            id="outlined-multiline-flexible"
                                            label={<T.span text='message'></T.span>}
                                            multiline
                                            fullWidth
                                            rows="6"
                                            style={styles.messageField}
                                            value={messageBody}
                                            onChange={(e) => { let body = e.target.value; self.setState({ messageBody: body }); }}
                                            variant="outlined"
                                        />
                                        <br />
                                        <div style={styles.sendButton}>
                                            <Button color="primary" variant="contained" style={styles.cancelButton} onClick={() => { self.setState({ viewState: 'read' }) }}><T.span text="cancel"></T.span></Button>
                                            <Button color="primary" variant="contained" style={styles.sendButton} onClick={self.sendMessage.bind(self)}><T.span text="send"></T.span></Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            )

        }

    }

}

const styles = {
    subjectField: {
        width: '100%'
    },
    inboxSentTable: {
        margin: 20
    },
    cellItems: {
        paddingLeft: 5,
        paddingRight: 5
    },
    newMessage: {
        textAlign: 'left',
        marginLeft: 20
    },
    inboxHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 20
    },
    composeHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 20
    },
    composeBody: {
        textAlign: 'left',
        justifyContent: 'space-between',
        margin: 20,
        padding: 20
    },
    sendButton: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'space-between'
    },
    cancelButton: {
        marginTop: 10,
        marginRight: 10
    },
    deleteButton: {
        position: 'absolute',
        right: 10,
        top: 10
    }
} 