import React from 'react';
import { 
    Grid,
    Paper,
    Avatar
} from '@material-ui/core';

export class ContentView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            content: props.content
        }
    }

    componentDidUpdate(oldProps) {
        if ( oldProps.content !== this.props.content) {
            this.setState({content: this.props.content});
        }
    }

    render() {
        const { appContext } = this.props;
        const { content } = this.state;

        console.log("ContentView.render: ", content );
        function hookDiv(ref) {
            if ( ref ) {
                ref.addEventListener('click',(e) => {
                    if ( appContext.onURL && e.target.tagName === 'A' ) {
                        appContext.onURL({ href: e.target.href} );
                        e.preventDefault();
                    }
                });
            }
        }

        let text = []
        if ( Array.isArray(content)) {
            for (let i = 0; i < content.length; i++) {
                text.push(<div ref={hookDiv} style={styles.div} key={i} 
                    dangerouslySetInnerHTML={{ __html: content[i].text }} />)
                text.push(<br key={`br${i}`} />);
            }
        }
        let icon = React.cloneElement( this.props.icon, {fontSize: 'small', style: {fill: this.props.color} } );
        return (
            <Grid id={this.props.id} container align="center" style={{marginTop:50}}>
                <Grid item style={{width: '100%', marginTop: 15, marginBottom: 15}}>
                    <Paper style={{textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 5, padding: 15}} >
                        <div style={styles.div}>
                            <div style={{ marginBottom: 15, paddingBottom: 15, borderBottom: `1px solid grey` }}>
                            <Grid container direction="row" alignItems="center">
                                <Grid item style={{ marginRight: 10 }}>
                                    <Avatar style={{ width: 35, height: 35, border: `2px solid ${this.props.color}`, backgroundColor: 'white' }}>{icon}</Avatar>
                                </Grid>
                                <Grid item >
                                    <span style={{ fontSize: 22, fontColor: '#666666' }}>{this.props.label}</span>
                                </Grid>
                            </Grid>
                            </div>
                            {text.length > 0 ? text : "Content not found!"}
                        </div >
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

const styles = {
    div: {
        margin: 5,
        textAlign: 'left'
    }
};

export default ContentView;
