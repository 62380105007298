import React from "react"

export class VideoItemS3 extends React.Component {
    render() {
        const { height } = this.props;
        return (
            <div style={{ height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                    <div style={{ color: '#474747', fontFamily: 'Arial,Helvetica,sans-serif', alignContent: 'center', fontSize: '12px' }}>
                        <i>{this.props.description}</i>
                    </div>
                    <br />
                    <video className='s3-video-container' onPlay={this.props.onVideoInteraction}
                        style={{
                            maxHeight: height - 50, maxWidth: '100%',
                            top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'
                        }}
                        poster={`https://s3.amazonaws.com/${this.props.thumbnail}`} controls>
                        <source
                            src={`https://s3.amazonaws.com/${this.props.content}`}
                            type="video/mp4"
                        ></source>
                    </video>
                </div>
            </div>
        )
    }
}
