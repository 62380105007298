import React from "react";

import T from 'i18n-react';
import Moment from 'moment-timezone';
import { PieChart, Pie, Cell } from 'recharts';

import "react-circular-progressbar/dist/styles.css";

export class StatusPieChart extends React.Component {
    render() {
        const scale = (this.props.height || 240) / 240;
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column',margin:'auto', minHeight: this.props.height || 240, height: this.props.height || 450 }} 
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.onClick(true) }}>
                <PieChart style={{ margin: 'auto', paddingTop: 20 }} width={120 * scale} height={120 * scale}>
                    <Pie data={this.props.graphData}
                        isAnimationActive={false}       // BUG in the library, labels will not display at all times if the animations are enabled
                        dataKey='value'
                        label={(e) => e.name}
                        labelLine={false}
                        innerRadius={37}
                        outerRadius={52}
                        fill='#8884d8'
                        paddingAngle={5}>
                        {
                            this.props.graphData.map((entry, index) => <Cell key={`Cell.${index}`} fill={entry.color} />)
                        }
                    </Pie>
                </PieChart>
                <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20, display: 'flex', alignContent: 'space-between', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ width: 20 }}></div>
                    <div>
                        <T.div style={{ color: '#333333', fontSize: 13 }} text={{ key: 'weekOf', startTime: Moment(this.props.startTime).format("M/D/YYYY") }} />
                        {this.props.timeSince ? <T.div style={{ color: '#888888', fontSize: 11 }} text={{ key: 'lastCheckIn', timeSince: this.props.timeSince }} /> : <T.div text='noRecentCheckIn' />}
                    </div>
                    <div style={{ width: 20 }}></div>
                </div>
            </div>
        )
    }
}
