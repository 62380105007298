import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Paper,
    Button,
    CircularProgress,
    DialogActions
} from '@material-ui/core';

import T from 'i18n-react';

import CheckedIcon from '@material-ui/icons/CheckBox';
import UncheckedIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import changeSymptomSound from '../sounds/changeSymptom.wav';

import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

export class MedicationsDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            patient: props.appContext.state.patient,
            track: []
        }
    }

    submitMedications() {
        const self = this;
        const { track } = this.state;
        const { appContext } = this.props;
        const { patientId } = appContext.state;

        this.playNotification();

        console.log("submitMedications:", track );
        if ( track.length > 0 ) {
            let data = [];
            for(let i=0;i<track.length;++i) {
                const medication = track[i];
                data.push( {
                    dataId: 'medication',
                    data: [ medication.medicationId, medication.name ],
                    status: 'submitted',
                    baseline: false,
                    source: [ 
                        { origin: 'patient-web-client' }
                    ]
                } );
            }
            let saveData = {
                url: Config.baseUrl + `${Config.pathPrefix}patients/${patientId}/data`,
                method: 'POST',
                headers: { "Authorization": appContext.state.idToken },
                data
            };

            this.setState({ progress: <CircularProgress size={30} />, error: null });
            console.log("saveData Request:", saveData);
            AxiosRequest(saveData).then((result) => {
                console.log("saveData Result:", result.data);
                this.props.onClose();
            }).catch((err) => {
                console.error("saveData error:", err);
                self.setState({progress: null, error: getErrorMessage(err)});
            });
        }
        else {
            this.props.onClose();
        }
    }

    onTrackMedication( medicationId, name ) {
        let { track } = this.state;

        let index = track.findIndex((e) => e.medicationId === medicationId );
        if ( index >= 0 ) {
            track.splice( index, 1 );
        }
        else {
            track.push({ medicationId, name });
        }
        console.log("onTrackMedications:", track );
        this.setState({track});
        this.playNotification();
    }

    onClearAll() {
        this.setState({track: [] });
        this.playNotification();
    }

    playNotification() {
        try {
            const readyAudio = new Audio(changeSymptomSound);
            readyAudio.play();
        }
        catch (err) {
            console.log("Failed to play sound:", err);
        }
    }

    render() {
        const { track, patient } = this.state;

        let gridItems = [];
        if ( patient && patient.medications.length > 0 ) {
            let { medications, medicationTypes } = patient;
            for(let i=0;i<medications.length;++i) {
                let medication = medications[i];
                if (! medication.tracked ) continue;
                let medicationType = (medicationTypes || []).find((e) => e.medicationId === medication.medicationId );
                if (! medicationType ) continue;

                let items = [];
                if ( track.find((e) => e.medicationId === medication.medicationId ) )  {
                    items.push( <CheckedIcon key={items.length} />);
                } 
                else {
                    items.push( <UncheckedIcon key={items.length} /> );
                }

                gridItems.push( 
                    <Paper key={i} style={styles.paper} onClick={this.onTrackMedication.bind(this, medicationType.medicationId, medicationType.name)}>
                        <Grid container direction='row' alignItems='center' justifyContent='space-between'>
                            <Grid item>
                                {medicationType.name}
                            </Grid>
                            <Grid item>
                                <div style={{ display:'flex', alignItems:'center'}}>
                                    {items}
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>);
            }
        }
        else {
            gridItems.push( 
                <Paper key={1} style={styles.paper}>
                    <div align='center'><T.span text='noTrackedMedications' /></div>
                </Paper>);
        }

        let icon = React.cloneElement( this.props.icon, {style: {fill: this.props.color, marginRight:10} } );
        return (
            <Dialog open={true} maxWidth='lg' onClose={this.props.onClose}>
                <DialogTitle>
                    <div style={{ display:'flex', alignItems:'center'}}>{icon}<T.span text='medicationsTitle' /></div>
                </DialogTitle>
                <DialogContent>
                    <T.span text='medicationsContent' />
                    <Grid container style={styles.gridContainer}>
                        {gridItems}
                    </Grid>
                    <Button onClick={this.onClearAll.bind(this)}><T.span text='clearAll' /></Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.submitMedications.bind(this)}><T.span text='done' /></Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const styles = {
    gridItem: {
    },
    gridContainer: {
        width: '100%', 
        marginTop: 15, 
        marginBottom: 15
    },
    paper: {
        width: '100%',
        padding: 15,
        margin: 5
    }
}

export default MedicationsDialog;
