import React from "react";
import {
    Grid,
    Paper,
    CircularProgress,
    IconButton,
} from '@material-ui/core';

import Carousel from 'react-material-ui-carousel'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import T from 'i18n-react';
import Moment from 'moment-timezone';
import _ from 'lodash';

import getTimeSince from '../utils/getTimeSince';
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import { Logger } from '@apricityhealth/web-common-lib';
import Config from '@apricityhealth/web-common-lib/Config';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import { EodDialog } from '../dialogs/EodDialog';
import { StatusPieChart } from '../components/StatusPieChart';
import { findPeakAlert, findLastAlerts, findLastAlert, pushUnique } from '../utils/utils'; 
import { processEducationContent } from "./EducationView";


import "react-circular-progressbar/dist/styles.css";

const log = new Logger();

export class HomeStatusView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            overallAlertId: props.settings.overallAlertId || 'iraeOverallAlert',
            checkinAlertId: props.settings.checkinAlertId || 'iraeOverallAlert',
            overrideAlertIds: this.props.settings.overrideAlertIds || 'iraeOverallAlert',
            policyAlertId: props.settings.policyAlertId || 'iraeOverallAlert',
            clearanceId: props.settings.clearanceId || 'clearanceProtocol',
            permittedOverrideId: props.settings.permittedOverrideId || 'permittedOverride',
            permittedOverrideDays: _.get(props.settings, 'permittedOverrideDays') || 5,
            dialog: null,
            lastAlert: null,
            days: [],
            data: [],
            startTime: null,
            endTime: null,
            clearanceProtocol: 0,
            clearanceDuration: 0,
            autoPlayEnabled:true,
            progress: null,
            error: null,
            currentIndex: 0,
            currentTitle: ''
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(oldProps) {
        if ( JSON.stringify(oldProps.settings) !== JSON.stringify(this.props.settings) ) {
            this.setState( {
                overallAlertId: this.props.settings.overallAlertId || 'iraeOverallAlert',
                checkinAlertId: this.props.settings.checkinAlertId || 'iraeOverallAlert',
                overrideAlertIds: this.props.settings.overrideAlertIds || 'iraeOverallAlert',
                clearanceId: this.props.settings.clearanceId || 'clearanceProtocol',
                permittedOverrideId: this.props.settings.permittedOverrideId || 'permittedOverride',
            });
        }
    }

    loadContent(retries = 0) {
        const self = this;
        const loadContentTime = Date.now();
        const { appContext } = this.props;
        const { patientId, alert_levels, alertLevelsLoaded, configLoaded, cache, language } = appContext.state;
        const { overallAlertId, overrideAlertIds, policyAlertId, checkinAlertId, clearanceId, permittedOverrideId, permittedOverrideDays } = this.state;

        if ( !alertLevelsLoaded || !configLoaded) {
            log.debug(`Waiting for alerts: ${alertLevelsLoaded}, config: ${configLoaded}`);
            // not all data loaded by the main app yet, wait until we have a config and alert levels
            this.setState({progress: <CircularProgress size={30} />});
            return setTimeout( () => self.loadContent(), 250 );
        }

        let endTime = Moment().toDate();
        let startTime = Moment().startOf('week').toDate();
        let dataIds = [];
        pushUnique( dataIds, overallAlertId );
        pushUnique( dataIds, policyAlertId );
        pushUnique( dataIds, permittedOverrideId );
        pushUnique( dataIds, overrideAlertIds );
        pushUnique( dataIds, checkinAlertId );
        pushUnique( dataIds, clearanceId );

        const getData = {
            url: Config.baseUrl + `${Config.pathPrefix}patients/${patientId}/data?dataId=${dataIds.join(',')}&days=14`,
            method: 'GET',
            headers: { "Authorization": appContext.state.idToken },
        };
        const getDataCacheKey = "HomeStatusView.getData." + patientId + "." + dataIds.join(',');
        log.debug("getData Request:", getData);

        const getPatientEducation = {
            url: Config.baseUrl + `${Config.pathPrefix}content/education/${patientId}?conditional=true&language=${language}&tutorial=false`,
            method: 'GET',
            headers: { "Authorization": appContext.state.idToken },
        }
        const getPatientEducationKey = "HomeStatusView.getEducation." + patientId;
        log.debug("getPatientEducation request:", getPatientEducation );

        const getJournals = {
            url: Config.baseUrl + `${Config.pathPrefix}journal/${patientId}?&days=14`,
            method: 'GET',
            headers: { "Authorization": appContext.state.idToken },
        };
        const getJournalsKey = "HomeStatusView.getJournals." + patientId;
        log.debug("getJournals request:", getJournals );

        this.setState({ progress: <CircularProgress style={{marginTop:'10px'}} size={30} />, error: null });
        Promise.all([
            cache[getDataCacheKey] ? Promise.resolve(cache[getDataCacheKey]) : AxiosRequest(getData),
            cache[getPatientEducationKey] ? Promise.resolve(cache[getPatientEducationKey]) : AxiosRequest(getPatientEducation),
            cache[getJournalsKey] ? Promise.resolve(cache[getJournalsKey]) : AxiosRequest(getJournals)
        ]).then(([dataResponse, educationResponse, journalsResponse]) => {
            cache[getDataCacheKey] = dataResponse;
            cache[getPatientEducationKey] = educationResponse;
            cache[getJournalsKey] = journalsResponse;

            const data = dataResponse.data.data;
            log.debug("getData Result:", dataResponse);
            const journals = _.get(journalsResponse, "data.records", [] );
            log.debug("getJournals Response:", journals);
            const educationItems =  _.get(educationResponse, "data", [] );
            log.debug("getPatientEducation Response:", educationResponse);

            const dataWithJournals = data.filter(i => journals.some(journal => journal.sessionId === i.sessionId));
            log.debug("dataWithJournals Result:", dataWithJournals);

            let clearanceProtocol = 0;
            let clearanceDuration = null;
            if ( clearanceId ) {
                let protocol = data.find((e) => e.dataId === clearanceId);
                if ( protocol ) {
                    log.debug("clearanceProtocol:", protocol );
                    clearanceProtocol = Number(protocol.data[0]);
                    clearanceDuration = Number(protocol.data[2]);
                }
            }
            let permittedOverride = null;
            if( permittedOverrideId ) {
                let override = data.find((e) => e.dataId === permittedOverrideId);
                if ( override ) {
                    let days = override.data[1] || permittedOverrideDays;
                    let diff = Moment().diff(Moment(override.eventTime),'days', true);
                    if ( diff <= days ) {
                        log.debug(`permittedOverride, diff: ${diff}:`, override );
                        permittedOverride = override.data[0];
                    }
                }
            }

            let days = [];
            for(let i=0;i<7;++i) {
                let dayStart = Moment(startTime).add(i, 'days');
                days[i] = { data: [], dayStart, color: dayStart < Moment() ? '#BBB' : '#EEEEEE' };
            }

            for(let i=0;i<dataWithJournals.length;++i) {
                let day = Moment(dataWithJournals[i].eventTime).startOf('day').diff( startTime, 'days');
                if( day >= 0 && day < 7 )
                    days[day].data.push( dataWithJournals[i] );
            }
            log.debug("days:", days );

            for(let i=0;i<7;++i) {
                let day = days[i];
                day.alert = findPeakAlert( day.data, checkinAlertId, alert_levels );
                if ( day.alert.peakLevel ) {
                    day.color = day.alert.peakLevel.color;
                    day.alertName = day.alert.peakLevel.name;
                }
            }

            let lastAlert = findLastAlert( dataWithJournals, permittedOverride ? overrideAlertIds : overallAlertId, alert_levels ); // data.find((e) => e.dataId === overallAlertId);
            let lastCheckIn = lastAlert ? lastAlert.eventTime : null;
            let timeSince = lastAlert ? getTimeSince(lastAlert.eventTime) : '';
            let currentStatus = lastAlert ? lastAlert.data[0] : null;
            let currentStatusColor = lastAlert ? lastAlert.level.color : '#BBB';
            let currentStatusText = lastAlert ? T.translate(currentStatus + 'AlertStatus') : T.translate("noRecentCheckIn");

            let org = _.get(appContext,"state.org");
            let badgeExpireTime = _.get(org,"config.badgeExpireTime") || 24;
            let badgeExpiresWhen = lastAlert ? Moment(lastAlert.eventTime).add( badgeExpireTime, 'hours') : Moment().subtract(1, 'minutes');
            let enablePolicyText = this.props.settings.policy !== undefined;

            let policyTextId = null;
            if ( enablePolicyText && policyAlertId ) {
                let policyAlerts = findLastAlerts(data, policyAlertId );
                for(let k in policyAlerts ) {
                    let alert = policyAlerts[k];
                    let policyId = `${alert.data[0]}_${alert.dataId}_policy`;
                    policyTextId = (org && org.config && _.get(org.config, policyId)) || _.get(this.props.settings.policy, policyId) || "notPermitted";
                    // if notPermitted, then stop right here, no need to look at the other alerts                    
                    if ( policyTextId === 'notPermitted' ) break;       
                }

                // if we have an override, then override the policy based on the alerts 
                if ( permittedOverride ) {
                    policyTextId = permittedOverride;
                }
                log.debug(`policyTextId: ${policyTextId}, policyAlerts:`, policyAlerts );
            }

            self.setState({ progress:null, data, days, startTime, endTime, 
                clearanceProtocol, clearanceDuration, lastAlert, timeSince,
                currentStatus, currentStatusColor, currentStatusText, permittedOverride,
                lastCheckIn, org, badgeExpireTime, badgeExpiresWhen, policyTextId, educationItems }, () => {
                    log.debug(`loadContent done, ${Date.now() - loadContentTime} ms:`, this.state );
                });
        }).catch((err) => {
            log.error("loadContent error:", err);
            if (retries < 3) {
                self.loadContent(retries + 1);
            }
            else
                self.setState({ progress: null, error: getErrorMessage(err) });
        });
    }

    closeDialog() {
        debugger
        this.setState({dialog: null});
    }

    onDisplayDialog() {
        const { dialog } = this.state;
        if (!dialog) {
            const { appContext, settings } = this.props;
            const { lastAlert, currentStatus, currentStatusText, currentStatusColor, lastCheckIn } = this.state;
            this.setState({
                dialog: <EodDialog appContext={appContext} lastAlert={lastAlert} 
                    currentStatus={currentStatus} currentStatusText={currentStatusText} 
                    currentStatusColor={currentStatusColor} lastCheckIn={lastCheckIn} settings={settings} onClose={ ()=> this.setState({dialog:null})}/>
            })
        }
        else {
            this.closeDialog();
        }
    }

    onVideoInteraction(){
        this.setState({autoPlayEnabled:false})
    }

    render() {
        const { appContext } = this.props;
        let { redirect, progress, error, dialog, educationItems } = this.state;
        if ( redirect ) {
            return redirect;
        }
        else if (progress) {
            return <Grid item style={styles.gridItem}>
                <Paper style={{...styles.paper, minHeight: (styles.height + 10) }}>
                    <div style={{height: 150, paddingTop: 100 }}>
                        {progress}
                    </div>
                </Paper>
            </Grid>;
        }
        else {
            const { days, currentStatusText, currentStatusColor, 
                timeSince, startTime, currentIndex, currentTitle } = this.state;

            let graphData = [];
            for(let i=0;i<7;++i) {
                let day = days[i];
                let name = T.translate(`day${i}`);
                if ( day ) {
                    name = day.dayStart.format('ddd').replace('.', '');
                    graphData.unshift( { name, value: 1, color: day.color, alert: day.alert })
                }
                else {
                    graphData.unshift( { name, value: 1, color: '#8884d8' });
                }
            }

            
            let carouselItems =  [
                <StatusPieChart
                    height={styles.height}
                    title={currentStatusText}
                    key='status'
                    currentStatusText={currentStatusText}
                    currentStatusColor={currentStatusColor}
                    graphData={graphData}
                    startTime={startTime}
                    onClick={this.onDisplayDialog.bind(this)}
                    timeSince={timeSince}
                />,
                ...processEducationContent(educationItems, appContext, { height: styles.height, onVideoInteraction: this.onVideoInteraction.bind(this) } )
            ]

            return <Grid item id="view" xs={12} sm={6} style={styles.gridItem}>
                <Paper style={styles.paper}>
                    <div style={{ paddingBottom: '5px' }}>
                        <Paper style={{ background: currentStatusColor, color: '#FFFFFF', padding: 10, left: 0, right: 0 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                <IconButton style={{ margin: -15, height: 50, width: 50}} onClick={() => {
                                    let newIndex = currentIndex - 1;
                                    if ( newIndex < 0 ) {
                                        newIndex = carouselItems.length - 1;
                                    }
                                    this.setState({currentIndex: newIndex, currentTitle: carouselItems[newIndex].props.title})
                                }}>
                                    <NavigateBeforeIcon style={{ margin: -15, height: '50px', width: '50px', color: '#dddddd' }} />
                                </IconButton>
                                <div style={{ textAlign: 'center', minHeight: 18, width: '100%' }}>{currentTitle}</div>
                                <IconButton style={{ margin: -15, height: 50, width: 50}} onClick={(e) => {
                                    let newIndex = currentIndex + 1;
                                    if ( newIndex >= carouselItems.length ) {
                                        newIndex = 0;
                                    }
                                    this.setState({currentIndex: newIndex, currentTitle: carouselItems[newIndex].props.title})
                                }}>
                                    <NavigateNextIcon style={{ margin: -15, height: '50px', width: '50px', color: '#dddddd' }} />
                                </IconButton>
                            </div>
                        </Paper>
                        <div style={{ margin: 5 }}>
                            <Carousel
                                navButtonsAlwaysInvisible={true}
                                index={currentIndex}
                                changeOnFirstRender={true}
                                onChange={(currentIndex) => {
                                    this.setState({ currentIndex, currentTitle: carouselItems[currentIndex].props.title });
                                }}
                                indicators={(carouselItems.length > 1)}
                                navButtonsAlwaysVisible={carouselItems.length > 1}
                                fullHeightHover={false}
                                // navButtonsWrapperProps={{  
                                //     style: {
                                //         top: -50,
                                //         bottom: 'unset'
                                //     }
                                // }}
                                // navButtonsProps={{
                                //     style: {
                                //         backgroundColor: 'transparent',
                                //         borderRadius: 0,
                                //     }
                                // }}
                                // NextIcon={<NavigateNextIcon style={{ height: '50px', width: '50px', color: '#8f8f8f' }} />}
                                // PrevIcon={<NavigateBeforeIcon style={{ height: '50px', width: '50px', color: '#8f8f8f' }} />}
                                swipe={true}
                                animation={'slide'}
                                interval={10000}
                                stopAutoPlayOnHover={true}
                                autoPlay={this.state.autoPlayEnabled}>
                                {carouselItems}
                            </Carousel>
                        </div>
                    </div>
                </Paper>
                {dialog}
                {error}
            </Grid>;
        }
    }
}

const styles = {
    height: 450,
    gridItem: {
        width: 350,
        minWidth: 340,
        cursor: 'pointer',
        position:'relative'
    },
    paper: {
        position:'relative',
        paddingBottom: 10,
        marginLeft: 15,
        marginRight: 15,
        marginTop: 5,
        marginBottom: 5,
        textAlign: 'center',
        alignItems: 'center'
    }
};
