import React, { Component } from 'react';
import {
    Button, 
    Dialog,
    DialogContent,
    DialogActions,
    CircularProgress,
    DialogTitle,
    Checkbox
} from '@material-ui/core/'

import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import { Logger } from '@apricityhealth/web-common-lib';
import T from 'i18n-react';

const log = new Logger();

export class TermsDialog extends Component {
    constructor(props) {
        super(props);

        if (! props.planIds ) throw new Error("planIds property is required");
        if (! props.termIds ) throw new Error("termIds property is required");

        this.state = {
            acceptedTerms: null,
            terms: null,
            textItems: {},
            showDialog: false,
            scrolledToBottom: false,
            buttonsDisabled: false,
            termDialog:null
        };
    }

    componentDidMount() {
        this.loadContent();
    }

    componentWillUnmount() {
    }

    getTerms() {
        const startTime = Date.now();
        const { appContext, termIds, planIds, language, country } = this.props;
        const { version, org } = appContext.state;
        
        // if the org has no config, just add an empty object..
        if (!org.config) org.config = {};

        let termIdQuery = []
        for(let i=0;i<termIds.length;++i) {
            const term = termIds[i];
            if ( country && term.country !== undefined && term.country !== country ) continue;
            if ( language && term.language !== undefined && term.language !== language ) continue;

            if ( Array.isArray(term.termId) ) {
                for(let j=0;j<term.termId.length;++j)
                    termIdQuery.push( term.termId[j] );
            }
            else if ( term.termId ) {
                termIdQuery.push( term.termId );
            }
        }

        log.info("termIds:", termIds);
        return new Promise((resolve) => {
            let cacheKey = `terms.${termIdQuery.join(',')}.${language}.${planIds.join(',')}`;
            let cached = localStorage.getItem(cacheKey);
            let getTermsPromise = null;
            if ( cached ) {
                cached = JSON.parse(cached);
                if ( cached.version === version ) {
                    getTermsPromise = Promise.resolve(cached.data);
                }
            }
            if (! getTermsPromise ) {
                let queryParams = [
                    "textId=" + termIdQuery.join(','),
                    `language=${language}`,
                    "planId="+ this.props.planIds.join(',')
                ];
                let getTerms = {
                    url: Config.baseUrl + `${Config.pathPrefix}content/text?` + queryParams.join('&'),
                    method: 'GET',
                    headers: { "Authorization": appContext.state.idToken },
                }
                log.debug("getTerms:", getTerms);
                getTermsPromise = AxiosRequest(getTerms).then((result) => {
                    localStorage.setItem(cacheKey, JSON.stringify({ version, data: result.data }));
                    return result.data;
                });
            }
            
            getTermsPromise.then((data) => {
                log.debug(`getTerms result, ${Date.now() - startTime} ms:`, data.text );

                //retain original sort order
                let terms = [];
                if (data && data.text) {
                    for(let z=0;z<termIds.length;++z) {
                        const term = termIds[z];
                        if ( country && term.country !== undefined && term.country !== country ) continue;
                        if ( language && term.language !== undefined && term.language !== language ) continue;
                        const addTerm = {
                            termId: term.termId,
                            name: term.name,
                            text: term.text || undefined,
                            planId: term.planId || '',
                            forcedView: term.forcedView || false,
                            version: term.version || undefined
                        }
                        if ( Array.isArray(addTerm.termId)) {
                            if (! Array.isArray(addTerm.name) || addTerm.name.length !== addTerm.termId.length ) {
                                throw new Error("termId && name array mismatched in length!")
                            }
                            addTerm.text = addTerm.termId.map((termId) => org.config[`${termId}_${language}`] || (data.text.find((e) => e.textId === termId) || {text: ''}).text )
                            addTerm.planId = addTerm.termId.map((termId) => (data.text.find((e) => e.textId === termId) || {planId: ''}).planId )
                            addTerm.version = addTerm.termId.map((termId) => (data.text.find((e) => e.textId === termId) || {version: 0}).version )
                        } else {
                            addTerm.text = org.config[`${addTerm.termId}_${language}`] || (data.text.find((e) => e.textId === addTerm.termId) || {text: ''}).text;
                            addTerm.planId = (data.text.find((e) => e.textId === addTerm.termId) || {planId: ''}).planId;
                            addTerm.version = (data.text.find((e) => e.textId === addTerm.termId) || {version: 0}).version;
                        }
                        terms.push(addTerm);
                    }
                }
                if (org.config['addPatientTerms']) {
                    try {
                        const addPatientTerms = JSON.parse(org.config['addPatientTerms']);
                        log.info("addPatientTerms:", addPatientTerms);
                        for(let k=0;k<addPatientTerms.length;++k) {
                            const term = addPatientTerms[k];
                            if ( country && term.country !== undefined && term.country !== country ) continue;
                            if ( language && term.language !== undefined && term.language !== language ) continue;        // skip other languages

                            const existingItem = terms.findIndex((e) => Array.isArray(e.termId) ? e.termId.indexOf(term.termId) >= 0 : e.termId === term.termId);
                            if ( existingItem >= 0 ) {
                                const updateTerm = terms[existingItem];
                                const textIndex = Array.isArray(updateTerm.termId) ? updateTerm.termId.indexOf(term.termId) : -1;
                                if ( textIndex >= 0 ) {
                                    if ( term.name ) {
                                        updateTerm.name[textIndex] = term.name;
                                    }
                                    if ( term.text ) {
                                        updateTerm.text[textIndex] = term.text;
                                    }
                                    if ( term.version || Number(term.version) === 0 ) {
                                        updateTerm.version[textIndex] = term.version;
                                    }
                                } else {
                                    if ( term.name ) {
                                        updateTerm.name = term.name;
                                    }
                                    if ( term.text ) {
                                        updateTerm.text = term.text;
                                    }
                                    if ( term.version || Number(term.version) === 0 ) {
                                        updateTerm.version = term.version;
                                    }
                                }
                                if (term.forcedView) {
                                    updateTerm.forcedView = true;
                                }
                                log.info("updateTerm:", updateTerm, term)
                            } else {
                                terms.push({
                                    termId: term.termId,
                                    name: term.name,
                                    text: term.text,
                                    planId: term.planId || '',
                                    forcedView: term.forcedView || false,
                                    version: term.version || 0
                                });
                                log.info("addTerm:", terms[terms.length - 1]);
                            }
                        }
                    } catch( err ) {
                        log.error("TermsDialog caught error:", err );
                    }
                }

                this.setState({terms}, resolve);
            }).catch((err) => {
                log.error("getTerms error:", err);
                setTimeout( () => {
                    this.getTerms().then( () => {
                        resolve();
                    });
                }, 1000 );
            })
        });
    }

    getAcceptedTerms() {
        const startTime = Date.now();
        const { appContext } = this.props;
        const { version, userId } = appContext.state;

        return new Promise((resolve) => {
            let cacheKey = `acceptedTerms.${userId}`;
            let cached = localStorage.getItem(cacheKey);
            let getAcceptedTermsPromise = null;
            if ( cached ) {
                cached = JSON.parse(cached);
                if ( cached.version === version ) {
                    getAcceptedTermsPromise = Promise.resolve(cached.data);
                }
            }
            if ( ! getAcceptedTermsPromise ) {
                let getAcceptedTerms = {
                    url: Config.baseUrl + `${Config.pathPrefix}authentication/terms/*`,
                    method: 'GET',
                    headers: { "Authorization": appContext.state.idToken },
                }
                log.debug("getAcceptedTerms:", getAcceptedTerms);
                getAcceptedTermsPromise = AxiosRequest(getAcceptedTerms).then((result) => {
                    localStorage.setItem(cacheKey, JSON.stringify({ version, data: result.data}));
                    return result.data;
                })
            }

            getAcceptedTermsPromise.then((data) => {
                log.debug(`getAcceptedTerms result, ${Date.now() - startTime} ms:`, data.terms );
                this.setState({acceptedTerms: data.terms}, () => {
                    resolve();
                })
            }).catch((err) => {
                log.error("getAcceptedTerms error:", err);
                setTimeout( () => {
                    this.getAcceptedTerms().then( () => {
                        resolve();
                    });
                }, 1000 );
            })
        })
    }

    loadContent() {
        const { readOnly } = this.props;

        this.setState({ progress: <CircularProgress /> });
        Promise.all([ this.getAcceptedTerms(), this.getTerms() ]).then(() => {
            const { terms, acceptedTerms } = this.state;

            // ok, set the accepted flag for each term to true if all the termId's have been accepted.
            for(let i=0;i<terms.length;++i) {
                const term = terms[i];
                if (Array.isArray(term.termId)) {
                    term.accepted = term.termId.every((termId, k ) => {
                        return acceptedTerms.find((j) => j.accepted && j.termId === termId && j.planId === term.planId[k] && Number(j.acceptedVersion) === Number(term.version[k]) );
                    })
                } else {
                    term.accepted = !!acceptedTerms.find((j) => j.accepted && j.termId === term.termId && j.planId === term.planId && Number(j.acceptedVersion) === Number(term.version) );
                }
                if ( term.accepted ) {
                    log.debug(`Term ${term.termId}:${term.planId} already accepted.`);
                    term.accepted = true
                }
                else {
                    term.accepted = false
                    log.debug(`Term ${term.termId}:${term.planId} not accepted yet.`);
                }
            }

            console.log("loadContent done:", terms, acceptedTerms );
            //see if any are not accepted
            if (readOnly || !terms.every( (term) => term.accepted === true )) {
                this.setState({progress: null, showDialog: true}, () => {
                    if (! readOnly ) {
                        let index = terms.findIndex((e) => !e.accepted && e.forcedView );
                        if ( index >= 0 ) {
                            this.onViewTerm( terms[index], index, 0, false, true )
                        }
                    }
                });
            } else {
                // no new terms to accept, just make the callback to end this dialog..
                this.props.onAccepted();
            }
        })
    }


    onAccept() {
        const { appContext  } = this.props;
        const { userId, patient } = appContext.state;
        const { terms } = this.state;

        this.setState({showDialog: false, buttonsDisabled: true, progress: <CircularProgress />});

        let promises = [];
        const acceptTermRequest = (termId, planId, acceptedVersion) => {
            const acceptTerm = {
                url: Config.baseUrl + `${Config.pathPrefix}authentication/terms`,
                method: 'POST',
                headers: { "Authorization": appContext.state.idToken },
                data: {
                    planId,
                    termId,
                    acceptedVersion,
                    accepted: true,
                    acceptedDate: new Date()
                }
            }
            log.debug("acceptTerm request:", acceptTerm);
            promises.push(AxiosRequest(acceptTerm))
        }
        terms.forEach( (term)=> {
            if ( Array.isArray(term.termId)) {
                for(let k=0;k<term.termId.length;++k) {
                    acceptTermRequest( term.termId[k], term.planId[k], term.version[k] );
                }
            } else {
                acceptTermRequest( term.termId, term.planId, term.version );
            }
        })

        if ( promises.length > 0) {
            let update = {
                acceptedTerms: new Date(),
                acceptedTermsBy: userId
            };
            if (! patient.firstAcceptedTerms ) {
                update.firstAcceptedTerms = new Date();
            }
            promises.push(appContext.updatePatient(update));
        }
        
        Promise.all(promises).then((responses) => {
            log.debug("onAccept:", responses.map((e) => e.data));
            localStorage.removeItem(`acceptedTerms.${userId}`);      // clear the cache once any terms are accepted
            this.props.onAccepted();
        }).catch((err) => {
            log.error("onAccept error:", err);
            this.setState({showDialog:true, progress: err.message, buttonsDisabled: false});
        })
    }

    onReject() {
        const { appContext  } = this.props;
        const { userId, patient } = appContext.state;
        const { terms } = this.state;

        const rejectTermRequest = (planId, termId, acceptedVersion) => {
            const rejectTerm = {
                url: Config.baseUrl + `${Config.pathPrefix}authentication/terms`,
                method: 'POST',
                headers: { "Authorization": appContext.state.idToken },
                data: {
                    planId,
                    termId,
                    acceptedVersion: acceptedVersion,
                    accepted: false,
                    acceptedDate: new Date()
                }
            }
            log.debug("rejectTermRequest request:", rejectTerm);
            promises.push(AxiosRequest(rejectTerm))
        }
        this.setState({showDialog: false, buttonsDisabled: true, progress: <CircularProgress />});

        let promises = []
        terms.forEach( (term)=> {
            if (Array.isArray(term.termId)) {
                for(let k=0;k<term.termId.length;++k) {
                    rejectTermRequest(term.planId[k], term.termId[k], term.version[k]);
                }
            } else {
                rejectTermRequest(term.planId, term.termId, term.version)
            }
        })

        // clear the consentGiven from the patient record as well..
        if ( patient.acceptedTerms ) {
            promises.push( appContext.updatePatient({ acceptedTerms: null }) );
        }

        Promise.all(promises).then((responses) => {
            log.debug("onAccept:", responses);
            localStorage.removeItem(`acceptedTerms.${userId}`);      // clear the cache once any terms are accepted
            this.props.onRejected();
        }).catch((err) => {
            log.error("onAccept error:", err);
            this.setState({showDialog:true, progress: err.message, buttonsDisabled: false});
        })        
    }

    onCheckTerm(index){
        const { terms } = this.state;
        const term = terms[index];
        if ( term.forcedView && !term.accepted ) {
            // don't let the user just click on the check-box for a term that has forcedView as true
            return this.onViewTerm(term, index, 0, false, true);
        }
        term.accepted = !term.accepted
        this.setState({terms});
    }

    onViewTerm(term, termIndex, textIndex, scrolledToBottom, enableAccept ){
        console.log("onViewTerm:", term, termIndex, scrolledToBottom );
        const { appContext } = this.props;
        if ( scrolledToBottom === undefined ) {
            scrolledToBottom = this.state.scrolledToBottom;
        }

        function hookDiv(ref) {
            if ( ref ) {
                ref.addEventListener('click',(e) => {
                    if ( appContext.onURL && e.target.tagName === 'A' ) {
                        appContext.onURL({ href: e.target.href} );
                        e.preventDefault();
                    }
                });
            }
        }

        const text = (Array.isArray(term.text) ? term.text[textIndex] : term.text); //.replace(/\n/g, '<br />');
        const termDialog = (
            <Dialog open={true} fullWidth={true}>
                <DialogContent className='content'
                    ref={(r) => {
                        this._termView = r;
                        //console.log("r:", r && r.clientHeight, r && r.scrollHeight );
                        if ( !scrolledToBottom && r && r.scrollHeight === r.clientHeight ) {
                            this.onViewTerm(term, termIndex, textIndex, true, enableAccept )
                        }
                    }}
                    onScroll={(e) => {
                        //console.log("onScroll:", e, e.currentTarget.scrollTop, e.currentTarget.scrollHeight - e.currentTarget.clientHeight )
                        if ( !scrolledToBottom && e.currentTarget.scrollTop >= (e.currentTarget.scrollHeight - e.currentTarget.clientHeight)) {
                            this.onViewTerm(term, termIndex, textIndex, true, enableAccept );
                        }
                    }}>
                    <div ref={hookDiv} style={styles.div} dangerouslySetInnerHTML={{ __html: text }} />
                </DialogContent>
                <DialogActions>
                    {!enableAccept && <Button color='primary' variant='contained' onClick={this.onTermCancel.bind(this,termIndex, false)}><T.span text='ok' /></Button>}
                    {enableAccept && term.forcedView && <Button color='primary' variant='contained' onClick={this.onTermCancel.bind(this, termIndex, true) }><T.span text='cancel' /></Button>}
                    {enableAccept && <Button disabled={term.forcedView && !scrolledToBottom} color="primary" variant="contained" 
                        onClick={this.onTermAccept.bind(this, termIndex, textIndex)}><T.span text='accept' /></Button>}
                </DialogActions>
            </Dialog>
        )
        this.setState({termDialog, scrolledToBottom})
    }

    onTermCancel(termIndex, clearAccept) {
        const { terms } = this.state;
        if ( clearAccept ) {
            terms[termIndex].accepted = false; //forcedView;
        }
        this.setState({ termDialog: null, terms })
    }

    onTermAccept(termIndex, textIndex){
        const { terms } = this.state;
        const term = terms[termIndex];
        if ( Array.isArray(term.termId) && textIndex < (term.termId.length - 1)) {
            if ( this._termView && this._termView.scrollTo ) {
                this._termView.scrollTo(0, 0);
            }
            // we have another termId to accept, so move to the next page.
            return this.onViewTerm(term,termIndex, textIndex + 1, false, true );
        }
        terms[termIndex].accepted = true; 
        this.setState({ termDialog: null, terms}, () => {
            let index = terms.findIndex((e) => !e.accepted && e.forcedView);
            if ( index >= 0 ) {
                this.onViewTerm( terms[index], index, 0, false, true )
            }
        })
    }

    render() {
        let { showDialog } = this.state;
         
        if ( showDialog ) {
            const { readOnly } = this.props;
            const { progress, terms, termDialog } = this.state;
            
            const acceptDisabled = !(terms.every( (term)=> { return (term.accepted === true)}))

            const getLinkItems = (term, termIndex) => {
                //if textIdName is an array we have multiple links
                let links = []
                let names = Array.isArray(term.name) ? term.name : [ term.name ];
                names.forEach( (name, i) => {
                    let linkTranslated = T.translate(name)
                    links.push(
                        <span key={links.length} style={styles.termLink} 
                            onClick={this.onViewTerm.bind(this, term, termIndex, i, false, !readOnly)}>{linkTranslated}</span> 
                    )
                    if (i !== names.length - 1){
                        links.push( <span key={links.length}> <T.span text='and' /> </span>)
                    } 
                })
                return links
            }

            let termItems = []
            terms.forEach( (term, index)=> {
                //if a textIdName is present, we need to show an additional link or links
                termItems.push(
                    <tr valign="top" key={index}>
                        <td>
                            <Checkbox disabled={readOnly} style={{'paddingTop':'0px', 'paddingBottom':'0px'}} checked={term.accepted} onClick={
                                this.onCheckTerm.bind(this, index)
                            }/>
                        </td>
                        <td>
                            {term.name ? 
                                <div>
                                    {getLinkItems(term, index)}
                                </div> :
                                <div>
                                    {term.text}
                                </div>
                            }
                        </td>
                    </tr>
                )
            })
            
            return (<span>
                {termDialog}
                <Dialog open={true} fullWidth={true}>
                    <DialogTitle><T.span text='termsAndConditions'/></DialogTitle>
                    <DialogContent>
                        <table id="terms">
                            <tbody>
                                {termItems}
                            </tbody>
                        </table>
                    </DialogContent>
                    <DialogActions>
                        <table style={{width: '100%'}}><tbody>
                        <tr><td align='left'>
                            {this.props.onRejected ? <Button id="reject" color="primary" variant="contained"  onClick={this.onReject.bind(this)}>{T.translate('reject')}</Button> : null}
                        </td>
                        <td align='right'>
                            {progress}
                            {readOnly ? 
                                <Button color='primary' variant='contained' onClick={this.props.onClose}><T.span text='ok' /></Button> :
                                <Button id="accept" color="primary" variant="contained" disabled={acceptDisabled} onClick={this.onAccept.bind(this)}><T.span text='accept' /></Button>
                            }
                        </td></tr></tbody></table>
                    </DialogActions>
                </Dialog>
                </span>
            )
        }

        return null;
    }
};

const styles = {
    div: {
        margin: 5,
        textAlign: 'left'
    },
    termLink:{
        display:'inline-block',
        cursor:'pointer',
        color:'blue',
        textDecoration:'underline'
    }
};
