import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    CircularProgress,
} from '@material-ui/core/';

import T from 'i18n-react';
import zippo from 'zippo';
import { cloneDeep } from 'lodash';

import SelectCountry from '@apricityhealth/web-common-lib/components/SelectCountry';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
//import { Logger } from '@apricityhealth/web-common-lib/utils/Logger';

//const log = new Logger();

export class ChangeAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: props.address ? cloneDeep(props.address) : [{ addressType: 'home', postalCode: '', country: 'US' }],
            progress: null,
        };
    }
    onCancel() {
        this.props.onDone();
    }

    onDone() {
        const { appContext } = this.props;
        const { address } = this.state;

        this.setState({ progress: <CircularProgress />, error: null });
        appContext.updatePatient({
            address
        }).then(() => {
            this.setState({ progress: null }, this.props.onDone );
        }).catch((err) => {
            this.setState({ progress: null, error: getErrorMessage(err) })
        })
    }

    render() {
        const self = this;
        let { progress, error, address } = this.state;
        if (!Array.isArray(address) || address.length < 1)
            address = [{ addressType: 'home', postalCode: '', country: 'US' }];

        let zip = address[0].postalCode;
        let country = address[0].country || 'US';
        let zipValid = country === 'US' ? zippo.validate(zip) : true;

        return (
            <Dialog open={true} maxWidth='xs' fullWidth>
                <DialogTitle>
                    <T.span text="changeAddress" />
                </DialogTitle>
                <DialogContent align='center'>
                    <SelectCountry
                        style={styles.country}
                        value={country}
                        onChange={(e) => {
                            address[0].country = e;
                            self.setState({ address });
                        }}
                    />
                    <br />
                    <TextField
                        type={country === 'US' ? 'number' : 'text'}
                        style={styles.text}
                        label={<T.span text="postalCode" />}
                        value={zip}
                        onChange={(e) => {
                            address[0].postalCode = e.target.value;
                            self.setState({ address });
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    {progress}
                    <Button color="primary" variant="contained" onClick={this.onCancel.bind(this)} style={{width: 150, margin: 5}}> 
                        <T.span text="cancel" />
                    </Button>
                    <Button
                        style={{width: 150, margin: 5}}
                        disabled={progress !== null || !zipValid}
                        color="primary"
                        variant="contained"
                        onClick={this.onDone.bind(this)}
                    >
                        <T.span text="ok" />
                    </Button>
                    <br />
                    <span style={{color: 'red'}}>{error}</span>
                </DialogActions>
            </Dialog>
        );
    }
}

const styles = {
    text: {
        margin: 5,
        width: '95%',
    },
    country: {
        margin: 5,
        width: '95%',
    },
};

export default ChangeAddress;
