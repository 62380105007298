import React, { useState, useEffect } from 'react';
import T from 'i18n-react';

import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

import { Grid, Paper, Button, CircularProgress } from '@material-ui/core/';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import BaselineHeader from '@apricityhealth/web-common-lib/components/headers/BaselineHeader';
import QuestionProgress from '@apricityhealth/web-common-lib/components/QuestionProgress';
import { SelectQuestion } from '@apricityhealth/web-common-lib/views/ConversationView';

import SurveyIcon from '@material-ui/icons/Poll';
import DocumentIcon from '@material-ui/icons/Description';

export const SurveyView = (props) => {
    const { appContext, patientId, language, timezone, temperatureUnit } = props;

    //const [dialog, setDialog] = useState(null);
    const [surveyModel, setSurveyModel] = useState(null);
    const [questions, setQuestions] = useState(null);
    const [totalQuestions, setTotalQuestions] = useState(null);
    const [currentQuestionIndex, setCurrentQuestion] = useState(null);
    const [currentAnswers, setCurrentAnswers] = useState([]);
    const [completedQuestions, setCompletedQuestions] = useState([]);

    const [lastQuestion, setLastQuestion] = useState(null);
    const [endOfQuestions, setEndOfQuestions] = useState(false);

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [resetQuestion, setResetQuestion] = useState(false);

    const answeredQuestions = endOfQuestions ? currentQuestionIndex + 1 : currentQuestionIndex;
    const subtitle = Number.isInteger(totalQuestions) && surveyModel ? `${surveyModel.name} - ${currentQuestionIndex + 1} of ${totalQuestions}` : '';

    function submitQuestion(answers) {
        const currentQuestion = questions[currentQuestionIndex];
        console.log('Submit question', answers, currentQuestion);
        setResetQuestion(false);
        setCurrentAnswers([...currentAnswers, ...answers]);
        if (currentQuestionIndex === (totalQuestions - 1)) {
            setEndOfQuestions(true);
        } else {
            const nextQuestion = questions[currentQuestionIndex + 1]
            setCurrentQuestion(currentQuestionIndex + 1);
            setCompletedQuestions([nextQuestion, ...completedQuestions]);
        }
        window.scrollTo(0, 0);
    }

    function previousQuestion() {
        if (currentQuestionIndex < 1) throw new Error("currentQuestionIndex is already 0");
        setResetQuestion(true);
        currentAnswers.pop();
        setCurrentAnswers([...currentAnswers]);
        completedQuestions.shift();
        setCompletedQuestions([...completedQuestions]);
        setCurrentQuestion(currentQuestionIndex - 1);
    }

    async function saveAnswers() {
        try {
            setLoading(true);
            setErrorMessage(null);
            const request = {
                url: `${Config.baseUrl}${Config.pathPrefix}dialog/conversation/${patientId}`,
                method: 'POST',
                headers: { "Authorization": appContext.state.idToken },
                data: {
                    models: [surveyModel.modelId],
                    flags: ['survey'],
                    baseline: false,
                    journal: currentAnswers,
                    hardReset: true,
                    language,
                    timezone,
                    temperatureUnit,
                    source: [{ client: 'patient-web-client' }]
                }
            };
            console.log("saveAnswers request:", request);
            const response = await AxiosRequest(request);
            console.log("saveAnswers response:", response.data);
            setLoading(false);
            if (props.onDone) props.onDone();
            setSurveyModel(null);
            getQuestions();
        } catch (error) {
            console.log('saveAnswers error', error);
            setLoading(false);
            setErrorMessage(getErrorMessage(error));
        }
    }

    function restart() {
        getQuestions(true);
    }

    async function getQuestions() {
        try {
            setLoading(true);
            setErrorMessage(null);
            setQuestions(null);
            setTotalQuestions(null);
            setCurrentQuestion(null);
            setCurrentAnswers([]);
            setCompletedQuestions([]);
            setLastQuestion(null);
            setEndOfQuestions(false);

            if (surveyModel) {
                const request = {
                    url: `${Config.baseUrl}${Config.pathPrefix}dialog/patient/${patientId}`,
                    method: 'POST',
                    headers: { "Authorization": appContext.state.idToken },
                    data: {
                        models: [surveyModel.modelId],
                        flags: ['survey'],
                        language: language || 'en-us',
                        conversational: false,
                        limit: 200,
                        baseline: false,
                        hardReset: true
                    }
                };
                console.log("getQuestions request:", request);
                const response = await AxiosRequest(request);
                console.log("getQuestions response:", response.data);

                setLoading(false);
                const data = response.data;
                if (data && data.questions) {
                    const questions = data.questions.filter(question => !question.eod && !question.detect && !question.answers.find((k) => k.detect)).sort((a, b) => {
                        if (a.order > b.order) return 1;
                        if (a.order < b.order) return -1;
                        return 0;
                    });
                    if (questions.length > 0) {
                        setQuestions(questions);
                        setCurrentQuestion(0);
                        setCompletedQuestions([questions[0]]);
                        setTotalQuestions(questions.length);

                        // if there is a detect question, automatically add the answer for yes..
                        const detect = data.questions.find(question => question.detect || question.answers.find((k) => k.detect));
                        if (detect) {
                            let detectAnswer = detect.answers.find((k) => k.detect);
                            currentAnswers.push({
                                planId: detect.planId,
                                questionId: detect.questionId,
                                answers: [
                                    {
                                        answerId: detectAnswer.answerId,
                                        text: detectAnswer.text[0] || 'Yes'
                                    }
                                ]
                            });
                            console.log("Added detect answer:", currentAnswers);
                            setCurrentAnswers([...currentAnswers]);
                        }
                        const eod = data.questions.find(question => question.eod);
                        if (eod) setLastQuestion(eod);
                    } else {
                        console.log("no questions available!");
                        setSurveyModel(null);
                    }
                }
            }
        } catch (error) {
            console.log('SurveyView() fetch error', error);
            setLoading(false);
            setErrorMessage(getErrorMessage(error));
        }
    }

    useEffect(() => {
        getQuestions(true);
    }, [surveyModel]); // similar to componentDidMount

    useEffect(() => {
        console.log('SurveyView()', currentQuestionIndex, completedQuestions);
    });

    if (!surveyModel) {
        const { surveys } = appContext.state;

        return <Grid container style={{ marginTop: 55, width: '100%' }}>
            <Paper style={{ width: '100%', textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 15, marginBottom: 15, padding: 15 }}>
                <BaselineHeader noBottomPadding={true} icon={<SurveyIcon style={{ color: 'rgb(0, 118, 187)' }} />} title={<T.span text='survey' />} subtitle={<T.span text='selectSurvey' />} withBorder={false} />
            </Paper>
            {surveys.length > 0 ?
                surveys.map((e) =>
                    <Paper style={{ width: '100%', textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 5, padding: 15 }} onClick={() => {
                        console.log("Selected survey model:", e);
                        setSurveyModel(e);
                    }}>
                        <Button>
                            <DocumentIcon style={{ marginRight: 10, color: 'rgb(0, 118, 187)' }} /><b>{e.name}</b>
                        </Button>
                    </Paper>
                )
                : <T.span style={{ width: '100%' }} text='noSurveysAvailable' textAlign='center' />}
        </Grid>
    } else {
        return (
            <Grid container style={{ marginTop: 55 }}>
                <Paper style={{ width: '100%', textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 15, marginBottom: 15, padding: 15 }}>
                    <BaselineHeader title={<T.span text='survey' />} subtitle={subtitle} color={'rgb(0, 118, 187)'} withBorder={false} />
                    <QuestionProgress style={{ marginBottom: 10 }} answeredQuestions={answeredQuestions} totalQuestions={totalQuestions} />
                </Paper>
                {errorMessage && <Grid item style={{ color: 'red', justifyContent: 'center', marginTop: 15, marginBottom: 15, marginLeft: 15, marginRight: 15 }}>
                    {errorMessage}
                </Grid>}
                {endOfQuestions && <Paper style={{ width: '100%', textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 5, padding: 15 }}>
                    <p style={{ textAlign: 'center' }}>
                        <ThumbUpIcon color="primary" style={{ fontSize: 100 }} />
                    </p>
                    <React.Fragment>
                        {lastQuestion && lastQuestion.question && lastQuestion.question[0] && lastQuestion.question[0].text ?
                            <p dangerouslySetInnerHTML={{ __html: lastQuestion.question[0].text }}></p> :
                            <div dangerouslySetInnerHTML={{ __html: T.translate('surveyEnd', { surveyName: surveyModel.name }) }}></div>
                        }
                        <p style={{ display: 'flex', flexDirection: 'column', margin: '0 15%' }}>
                            <Button disabled={loading} style={{ marginTop: 15, marginBottom: 15 }} color="primary" variant="contained" onClick={saveAnswers}><T.span text='finishAndSave' /></Button>
                        </p>
                    </React.Fragment>
                </Paper>}
                {completedQuestions.map(question => {
                    const reset = resetQuestion && completedQuestions.indexOf(question) === 0;
                    return <Paper key={question.questionId} style={{ width: '100%', textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 15, marginBottom: 15, padding: 15 }}>
                        <SelectQuestion
                            appContext={appContext}
                            disabled={loading}
                            patientId={patientId}
                            temperatureUnit={temperatureUnit}
                            response={question.question && question.question[0] && question.question[0].text}
                            question={question}
                            onSubmit={submitQuestion}
                            onBack={currentQuestionIndex > 0 && completedQuestions.indexOf(question) === 0 ? previousQuestion : undefined}
                            reset={reset}
                        />
                    </Paper>
                })}
                <Grid container justify="flex-end" style={{ margin: 15 }} >
                    <Button disabled={loading} color="primary" variant="contained" onClick={restart}><T.span text='restartSurvey' /></Button>
                </Grid>
                {loading && <Grid item style={{ width: '100%', textAlign: 'center', marginTop: 15, marginBottom: 15, padding: 15 }}>
                    <CircularProgress size={30} />
                </Grid>}
            </Grid>
        );
    }
}

export default SurveyView;
