import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Paper,
    Button
} from '@material-ui/core';

import T from 'i18n-react';

import CheckedIcon from '@material-ui/icons/CheckBox';
import UncheckedIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import changeSymptomSound from '../sounds/changeSymptom.wav';

const CLOSE_DELAY = 1000;

export class SymptomsDialog extends React.Component {
    constructor(props) {
        super(props);

        const { appContext } = props;
        let { config } = appContext.state;
        if (! Array.isArray(config.symptomTracking) ) throw new Error("symptomTracking missing from config.");

        this.state = {
            tracking: config.symptomTracking,
        }
    }

    onAdd( track ) {
        console.log("onAdd:", track );
        let value = Number( localStorage.getItem(`tracking.${track.dataId}`) || 0 );
        localStorage.setItem(`tracking.${track.dataId}`, value + 1 );
        this.playNotification();
        this.setState({updated: true});
        setTimeout( this.props.onClose, CLOSE_DELAY );
    }

    onReset( track ) {
        console.log("onReset:", track );
        localStorage.setItem(`tracking.${track.dataId}`, '0');
        this.playNotification();
        this.setState({updated: true});
        setTimeout( this.props.onClose, CLOSE_DELAY );
    }

    onResetAll() {
        const { tracking } = this.state;
        for(let i=0;i<tracking.length;++i) {
            let track = tracking[i];
            localStorage.setItem(`tracking.${track.dataId}`, '0');
        }
        this.playNotification();
        this.setState({updated: true});
        setTimeout( this.props.onClose, CLOSE_DELAY );
    }

    playNotification() {
        try {
            const readyAudio = new Audio(changeSymptomSound);
            readyAudio.play();
        }
        catch (err) {
            console.log("Failed to play sound:", err);
        }
    }

    render() {
        const { tracking } = this.state;

        let gridItems = [];
        for(let i=0;i<tracking.length;++i) {
            let track = tracking[i];
            let value = localStorage.getItem(`tracking.${track.dataId}`) || 0;

            let onClick = null;
            let items = [];
            if ( track.type === 'count' ) {
                items.push( <span key='value' style={{fontSize: 25}}>{value}</span> );
                onClick = this.onAdd.bind(this, track );
            }
            else if ( track.type === 'boolean' ) {
                if ( value > 0 )  {
                    onClick = this.onReset.bind(this,track);
                    items.push( <CheckedIcon key={items.length} />);
                } 
                else {
                    onClick = this.onAdd.bind(this,track);
                    items.push( <UncheckedIcon key={items.length} /> );
                }
            }

            gridItems.push( 
                <Paper key={i} style={styles.paper} onClick={onClick}>
                    <Grid container direction='row' alignItems='center' justifyContent='space-between'>
                        <Grid item>
                            <T.span text={track.textId} />
                        </Grid>
                        <Grid item>
                            <div style={{ display:'flex', alignItems:'center'}}>
                                {items}
                            </div>
                        </Grid>
                    </Grid>
                </Paper>);
        }

        let icon = React.cloneElement( this.props.icon, {style: {fill: this.props.color, marginRight:10} } );
        return (
            <Dialog open={true} maxWidth='lg' onClose={this.props.onClose}>
                <DialogTitle>
                    <div style={{ display:'flex', alignItems:'center'}}>{icon}<T.span text='trackSymptomsTitle' /></div>
                </DialogTitle>
                <DialogContent>
                    <T.span text='trackSymptomsContent' />
                    <Grid container style={styles.gridContainer}>
                        {gridItems}
                    </Grid>
                    <Button onClick={this.onResetAll.bind(this)}><T.span text='resetAllSymptoms' /></Button>
                </DialogContent>
            </Dialog >
        )
    }
}

const styles = {
    gridItem: {
    },
    gridContainer: {
        width: '100%', 
        marginTop: 15, 
        marginBottom: 15
    },
    paper: {
        width: '100%',
        padding: 15,
        margin: 5
    }
}

export default SymptomsDialog;
