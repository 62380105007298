import React from 'react';
import {
    Grid,
    Paper,
    CircularProgress,
} from '@material-ui/core';

import HelpIcon from '@material-ui/icons/Help';
import BadgeIcon from '@material-ui/icons/Security';

import T from 'i18n-react';
import Moment from 'moment-timezone';
import _ from 'lodash';
import { PieChart, Pie, Cell } from 'recharts';

import getTimeSince from '../utils/getTimeSince';
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';

import BadgeDialog from "../dialogs/BadgeDialog";
import { EodDialog } from '../dialogs/EodDialog';
import { findPeakAlert, findLastAlerts, findLastAlert, pushUnique } from '../utils/utils'; 

import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

import "react-circular-progressbar/dist/styles.css";

export class EmployeeStatusView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            overallAlertId: props.settings.overallAlertId || 'iraeOverallAlert',
            checkinAlertId: props.settings.checkinAlertId || 'iraeOverallAlert',
            overrideAlertIds: this.props.settings.overrideAlertIds || 'iraeOverallAlert',
            policyAlertId: props.settings.policyAlertId || 'iraeOverallAlert',
            clearanceId: props.settings.clearanceId || 'clearanceProtocol',
            permittedOverrideId: props.settings.permittedOverrideId || 'permittedOverride',
            permittedOverrideDays: _.get(props.settings, 'permittedOverrideDays') || 5,
            badgeEnabled: props.settings.badgeEnabled || false,
            dialog: null,
            lastAlert: null,
            days: [],
            data: [],
            startTime: null,
            endTime: null,
            clearanceProtocol: 0,
            clearanceDuration: 0,
            progress: null,
            error: null
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(oldProps) {
        if ( JSON.stringify(oldProps.settings) !== JSON.stringify(this.props.settings) ) {
            this.setState( {
                overallAlertId: this.props.settings.overallAlertId || 'iraeOverallAlert',
                checkinAlertId: this.props.settings.checkinAlertId || 'iraeOverallAlert',
                overrideAlertIds: this.props.settings.overrideAlertIds || 'iraeOverallAlert',
                clearanceId: this.props.settings.clearanceId || 'clearanceProtocol',
                permittedOverrideId: this.props.settings.permittedOverrideId || 'permittedOverride',
                badgeEnabled: this.props.settings.badgeEnabled || false,
            });
        }
    }

    loadContent(retries = 0) {
        const self = this;
        const { appContext } = this.props;
        const { patientId, alert_levels, alertLevelsLoaded, configLoaded, cache } = appContext.state;
        const { overallAlertId, overrideAlertIds, policyAlertId, checkinAlertId, clearanceId, permittedOverrideId, permittedOverrideDays } = this.state;

        if ( !alertLevelsLoaded || !configLoaded) {
            console.log(`Waiting for alerts: ${alertLevelsLoaded}, config: ${configLoaded}`);
            // not all data loaded by the main app yet, wait until we have a config and alert levels
            this.setState({progress: <CircularProgress size={30} />});
            return setTimeout( () => self.loadContent(), 250 );
        }

        let endTime = Moment().toDate();
        let startTime = Moment().startOf('week').toDate();
        let dataIds = [];
        pushUnique( dataIds, overallAlertId );
        pushUnique( dataIds, policyAlertId );
        pushUnique( dataIds, permittedOverrideId );
        pushUnique( dataIds, overrideAlertIds );
        pushUnique( dataIds, checkinAlertId );
        pushUnique( dataIds, clearanceId );

        const getData = {
            url: Config.baseUrl + `${Config.pathPrefix}patients/${patientId}/data?dataId=${dataIds.join(',')}&days=14`,
            method: 'GET',
            headers: { "Authorization": appContext.state.idToken },
        };
        const getDataKey = 'EmployeeStatusView.getData.' + patientId + "." + dataIds.join(',');

        const getJournals = {
            url: Config.baseUrl + `${Config.pathPrefix}journal/${patientId}?&days=14`,
            method: 'GET',
            headers: { "Authorization": appContext.state.idToken },
        };
        const getJournalsKey = 'EmployeeStatusView.getJournals.' + patientId;

        this.setState({ progress: <CircularProgress size={30} />, error: null });
        console.log("getData Request:", getData);
        Promise.all([
            cache[getDataKey] ? Promise.resolve(cache[getDataKey]) : AxiosRequest(getData),
            cache[getJournalsKey] ? Promise.resolve(cache[getJournalsKey]) : AxiosRequest(getJournals)
        ]).then(([dataResponse, journalResponse]) => {
            cache[getDataKey] = dataResponse;
            cache[getJournalsKey] = journalResponse;
            
            const journals = _.get(journalResponse, "data.records", [] );
            console.log("getJournals Response:", journals);
            let data = _.get(dataResponse, "data.data", [] );
            console.log("getData Result:", data);
            const dataWithJournals = data.filter(i => journals.some(journal => journal.sessionId === i.sessionId));
            console.log("dataWithJournals Result:", dataWithJournals);

            let clearanceProtocol = 0;
            let clearanceDuration = null;
            if ( clearanceId ) {
                let protocol = data.find((e) => e.dataId === clearanceId);
                if ( protocol ) {
                    console.log("clearanceProtocol:", protocol );
                    clearanceProtocol = Number(protocol.data[0]);
                    clearanceDuration = Number(protocol.data[2]);
                }
            }
            let permittedOverride = null;
            if( permittedOverrideId ) {
                let override = data.find((e) => e.dataId === permittedOverrideId);
                if ( override ) {
                    let days = override.data[1] || permittedOverrideDays;
                    let diff = Moment().diff(Moment(override.eventTime),'days', true);
                    if ( diff <= days ) {
                        console.log(`permittedOverride, diff: ${diff}:`, override );
                        permittedOverride = override.data[0];
                    }
                }
            }

            let days = [];
            for(let i=0;i<7;++i) {
                let dayStart = Moment(startTime).add(i, 'days');
                days[i] = { data: [], dayStart, color: dayStart < Moment() ? '#BBB' : '#EEEEEE' };
            }

            for(let i=0;i<dataWithJournals.length;++i) {
                let day = Moment(dataWithJournals[i].eventTime).startOf('day').diff( startTime, 'days');
                if( day >= 0 && day < 7 )
                    days[day].data.push( dataWithJournals[i] );
            }
            console.log("days:", days );

            for(let i=0;i<7;++i) {
                let day = days[i];
                day.alert = findPeakAlert( day.data, checkinAlertId, alert_levels );
                if ( day.alert.peakLevel ) {
                    day.color = day.alert.peakLevel.color;
                    day.alertName = day.alert.peakLevel.name;
                }
            }

            let lastAlert = findLastAlert( dataWithJournals, permittedOverride ? overrideAlertIds : overallAlertId, alert_levels ); // data.find((e) => e.dataId === overallAlertId);
            let lastCheckIn = lastAlert ? lastAlert.eventTime : null;
            let timeSince = lastAlert ? getTimeSince(lastAlert.eventTime) : '';
            let currentStatus = lastAlert ? lastAlert.data[0] : null;
            let currentStatusColor = lastAlert ? lastAlert.level.color : '#BBB';
            let currentStatusText = lastAlert ? T.translate(currentStatus + 'AlertStatus') : T.translate("noRecentCheckIn");

            let org = _.get(appContext,"state.org");
            let badgeExpireTime = _.get(org,"config.badgeExpireTime") || 24;
            let badgeExpiresWhen = lastAlert ? Moment(lastAlert.eventTime).add( badgeExpireTime, 'hours') : Moment().subtract(1, 'minutes');
            let enablePolicyText = this.props.settings.policy !== undefined;

            let policyTextId = null;
            if ( enablePolicyText && policyAlertId ) {
                let policyAlerts = findLastAlerts(data, policyAlertId );
                for(let k in policyAlerts ) {
                    let alert = policyAlerts[k];
                    let policyId = `${alert.data[0]}_${alert.dataId}_policy`;
                    policyTextId = (org && org.config && _.get(org.config, policyId)) || _.get(this.props.settings.policy, policyId) || "notPermitted";
                    // if notPermitted, then stop right here, no need to look at the other alerts                    
                    if ( policyTextId === 'notPermitted' ) break;       
                }

                // if we have an override, then override the policy based on the alerts 
                if ( permittedOverride ) {
                    policyTextId = permittedOverride;
                }
                console.log(`policyTextId: ${policyTextId}, policyAlerts:`, policyAlerts );
            }

            self.setState({ progress: null, data, days, startTime, endTime, 
                clearanceProtocol, clearanceDuration, lastAlert, timeSince,
                currentStatus, currentStatusColor, currentStatusText, permittedOverride,
                lastCheckIn, org, badgeExpireTime, badgeExpiresWhen, policyTextId }, () => {
                    console.log("loadContent done:", this.state );
                });
        }).catch((err) => {
            console.error("loadContent error:", err);
            if (retries < 3) {
                self.loadContent(retries + 1);
            }
            else
                self.setState({ progress: null, error: getErrorMessage(err) });
        });
    }

    closeDialog() {
        this.setState({dialog: null});
    }

    onDisplayDialog(headerPressed = false) {
        const { badgeEnabled , dialog } = this.state;

        if (!dialog) {
            if ( headerPressed && badgeEnabled ) {
                const { appContext, settings } = this.props;
                this.setState({ dialog: <BadgeDialog appContext={appContext}
                    settings={settings}
                    onClose={this.closeDialog.bind(this)} />
                })
            }
            else {
                const { appContext, settings } = this.props;
                const { lastAlert, currentStatus, currentStatusText, currentStatusColor, lastCheckIn } = this.state;
                this.setState({
                    dialog: <EodDialog appContext={appContext} lastAlert={lastAlert} 
                        currentStatus={currentStatus} currentStatusText={currentStatusText} 
                        currentStatusColor={currentStatusColor} lastCheckIn={lastCheckIn} settings={settings} onClose={ ()=> this.setState({dialog:null})} />
                })
            }
        }
        else {
            this.closeDialog();
        }
    }

    render() {
        let { redirect, progress, error, dialog } = this.state;
        if ( redirect ) {
            return redirect;
        }
        else if (progress) {
            return <Grid item style={styles.gridItem}>
                <Paper style={{...styles.paper, minHeight: 250}}>
                    <div style={{height: 150, paddingTop: 100 }}>
                        {progress}
                    </div>
                </Paper>
            </Grid>;
        }
        else {
            const { days, currentStatusText, currentStatusColor, 
                timeSince, startTime, badgeEnabled } = this.state;

            let graphData = [];
            for(let i=0;i<7;++i) {
                let day = days[i];
                let name = T.translate(`day${i}`);
                if ( day ) {
                    name = day.dayStart.format('ddd').replace('.', '');
                    graphData.unshift( { name, value: 1, color: day.color, alert: day.alert })
                }
                else {
                    graphData.unshift( { name, value: 1, color: '#8884d8' });
                }
            }

            return <Grid id="view" item xs={12} sm={6} style={styles.gridItem} onClick={()=>this.onDisplayDialog(false)}>
                <Paper style={styles.paper}>
                    <div align='center'>
                        {currentStatusText ? <Paper onClick={(e)=> {e.preventDefault();e.stopPropagation();this.onDisplayDialog(true)}} style={{ background: currentStatusColor, color: '#FFFFFF', padding: 10 }}>
                            <div style={{paddingLeft:10,paddingRight:10,display:'flex',alignContent:'space-between',justifyContent:'space-between',alignItems:'center'}}>
                                <div style={badgeEnabled ? {textAlign:'left'} : {textAlign:'center',width:'100%'} }><T.span id="status" text={{ key: 'currentStatus', currentStatus: currentStatusText }} /></div>
                                {badgeEnabled && <div id="badge" style={{display:'flex',textAlign:'right',alignItems:'center'}}>
                                    <p style={{
                                        'marginTop':'2px',
                                        'marginBottom':'2px'
                                    }}
                                    ><T.span text={"viewBadge"}/></p>
                                    <div style={{
                                        "position":"relative",
                                        'marginLeft':'10px',
                                        'marginRight':'20px',
                                    }}>
                                        <div style={{
                                            "position":'absolute',
                                            "borderRadius": '50%',
                                            "width": '24px',
                                            "height": '24px',
                                            "top":'-12px',
                                            "backgroundColor":'white',
                                        }}>
                                        </div>
                                        <BadgeIcon style={{
                                            "top":'-10px',
                                            "left":'2px',
                                            "width": '20px',
                                            "height": '20px',
                                            "position":'absolute',
                                            'fill': currentStatusColor
                                        }}/>
                                    </div>
                                </div>}
                            </div>
                            </Paper> : null}
                        <br />
                        <PieChart width={225} height={170}>
                            <Pie data={graphData}
                                isAnimationActive={false}       // BUG in the library, labels will not display at all times if the animations are enabled
                                dataKey='value'
                                label={(e) => e.name}
                                labelLine={false}
                                innerRadius={37}
                                outerRadius={52}
                                fill='#8884d8'
                                paddingAngle={5}>
                                {
                                    graphData.map((entry, index) => <Cell key={`Cell.${index}`} fill={entry.color} />)
                                }
                            </Pie>
                        </PieChart>
                        <div style={{paddingLeft:20,paddingRight:20,display:'flex',alignContent:'space-between',justifyContent:'space-between',alignItems:'center'}}>
                            <div style={{width:20}}></div>
                            <div>
                                <T.div style={{ color: '#333333', fontSize: 13 }} text={{key: 'weekOf', startTime: Moment(startTime).format("M/D/YYYY")}} />
                                {timeSince ? <T.div style={{ color: '#888888', fontSize: 11 }} text={{key: 'lastCheckIn', timeSince}} /> : <T.div text='noRecentCheckIn' />}
                            </div>
                            <div>
                                <HelpIcon style={{color:'#808080'}} fontSize="small" />
                            </div>
                        </div>
                        {dialog}
                        {error}
                    </div>
                </Paper>
            </Grid>;
        }
    }
}

const styles = {
    gridItem: {
        width: 350,
        minWidth: 340,
        cursor: 'pointer',
    },
    paper: {
        paddingBottom: 10,
        marginLeft: 15,
        marginRight: 15,
        marginTop: 5,
        marginBottom: 5,
        textAlign: 'center',
        alignItems: 'center'
    }
};
