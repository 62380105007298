import React from 'react';
import SectionHeader from '@apricityhealth/web-common-lib/components/headers/SectionHeader';
import SubscriptionIcon from '@material-ui/icons/Subscriptions';

export default ({title, subtitle, color}) => {
    return (
        <SectionHeader
            color={color}
            title={title}
            subtitle={subtitle}
            icon={<SubscriptionIcon style={{ color}}/>}
        />
    );
}

