import React, { Component } from 'react';

import {
    CircularProgress,
    Grid,
    Button,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    Avatar
} from '@material-ui/core/';

import T from 'i18n-react';
import { Link } from 'react-router-dom';

import ProviderIcon from '@material-ui/icons/AccountCircle';

import Moment from 'moment';
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import _ from 'lodash';

import CreateIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import RequestAppointmentDialog from '../dialogs/RequestAppointmentDialog'


export class AppointmentsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appointments: [],
            providers: null,
            progress: null,
            dialog:null
        };
    }

    componentDidMount() {
        this.getContent();
    }

    getContent() {
        let self = this;
        const { appContext } = this.props
        const { patientId, idToken } = appContext.state;

        const getPatientProviders = {
            url: Config.baseUrl + `${Config.pathPrefix}providers/patient/${patientId}?includeOrg=true&inactive=false`,
            method: 'GET',
            headers: { "Authorization": idToken }
        };
        console.log("getPatientProviders:", getPatientProviders);

        let currentTime = Moment().toISOString();
        const getAppointments = {
            url: Config.baseUrl + `${Config.pathPrefix}orgs/*/appointments?patientId=${patientId}&endAfter=${currentTime}`,
            method: 'GET',
            headers: { "Authorization": idToken }
        }
        console.log("getAppointments:", getAppointments);

        this.setState({progress: <CircularProgress />});
        Promise.all([AxiosRequest(getPatientProviders), AxiosRequest(getAppointments)]).then((results) => {
            let providers = results[0].data.providers;
            console.log("getPatientProviders result:", providers);
            let appointments = results[1].data.appointments;
            console.log("getAppointments result:", appointments);
            self.setState({ appointments, providers, progress: null });
        }).catch((error) => {
            console.log(error)
            self.setState({ progress: error.message });
        })
    }

    confirmAppointment(appointment) {
        const self = this;
        const { appContext } = this.props
        const { idToken } = appContext.state;

        self.setState({ progress: <CircularProgress /> });

        const request = {
            url: Config.baseUrl + `${Config.pathPrefix}orgs/${appointment.orgId}/appointments/${appointment.appointmentId}/confirm`,
            method: "POST",
            data: appointment,
            headers: { "Authorization": idToken }
        };

        console.log("confirmAppointment:", request);
        AxiosRequest(request).then((response) => {
            console.log("confirmAppointment response:", response);
            self.getContent()
        }).catch((err) => {
            console.log("confirmAppointment error:", err);
            self.setState({ progress: err.message });
        });
    }

    rejectAppointment(appointment) {
        let { rejectNote } = this.state
        rejectNote = (rejectNote || '').substring(0, 800);
        this.setState({ progress: <CircularProgress /> });
        let noteItem = {
            text:rejectNote,
        }
        appointment.note.push(noteItem)
        const request = {
            url: Config.baseUrl + `${Config.pathPrefix}orgs/${appointment.orgId}/appointments/${appointment.appointmentId}/reject`,
            method: "POST",
            data: appointment,
            headers: { "Authorization": this.props.appContext.state.idToken }
        };

        console.log("rejectAppointment:", request);

        AxiosRequest(request).then((response) => {
            console.log("rejectAppointment response:", response);
            this.getContent()
        }).catch((err) => {
            console.log("rejectAppointment error:", err);
            this.setState({ progress: err.message });
        });
    }


    confirmRejectAppointment(appointment){
        let self = this
        let {rejectNote} = self.state
        let dialog =
            <Dialog model="false" open={true} fullWidth={true} maxWidth={'xs'} style={{background: 'rgba(0,0,0,0.6)'}}>
                <DialogContent>
                      <br/>
                        {<T.span text='rejectNote'></T.span>}
                      <br/>
                      <br/>
                        <TextField
                            autoFocus
                            label={<T.span text='note'></T.span>}
                            value={rejectNote}
                            id="rejectNote"
                            name="rejectNote"
                            fullWidth
                            variant="outlined"
                            multiline
                            rows={6}
                            onChange={(e) => { console.log('keystroke '  ,rejectNote, e.target); self.setState({ rejectNote: e.target.value}); }} /> 
                </DialogContent>
                <DialogActions>
                     <Button color="primary" variant="contained" self={self} style={styles.button} onClick={(e) => { self.setState({dialog:null}); }} ><T.span text="cancel"></T.span></Button>
                     <Button color="primary" variant="contained" self={self} style={styles.button} onClick={(e) => { self.rejectAppointment(appointment);self.setState({dialog:null})}} ><T.span text="confirm"></T.span></Button>
                </DialogActions>
              </Dialog>
        this.setState({dialog})
    }

    openRequestDialog() {
        this.setState({dialog:<RequestAppointmentDialog appContext={this.props.appContext} onDone={this.onRequestAppointmentFinish.bind(this)} onCancel={this.onCloseDialog.bind(this)}
        icon={this.props.icon} color={this.props.color} />})
    }
    onRequestAppointmentFinish(){
        let dialog = (
            <Dialog open={true} fullWidth={true} maxWidth={'xs'} style={{background: 'rgba(0,0,0,0.6)'}}>
                <DialogContent ><T.span text="requestAppointmentConfirmed"/></DialogContent>
                <DialogActions>
                    <Button  color="primary" variant="contained" onClick={this.onCloseDialog.bind(this)}><T.span text='confirm'/></Button>
                </DialogActions>
            </Dialog>
        )
        this.setState({dialog}) 
    } 
    onCloseDialog(){
       this.setState({dialog:null}) 
    }

    render() {
        const { progress, providers, dialog } = this.state;
        let { appointments } = this.state;
        const { appContext } = this.props;
        const { patient } = appContext.state;
        
        appointments = appointments.filter( (appointment) => appointment.status !== 'rejected')
 
        let header = progress ? progress : appointments ? <T.span text={{key: 'appointmentsWaiting', numAppointments: appointments.length }} /> : 
            <T.span text='noAppointments' />;

        let gridItems = [];
        if ( appointments ) {
            for(let i=0;i<appointments.length;++i) {
                let appointment = appointments[i];
                let providerRecord = providers.find((e) => e.provider.providerId === appointment.providerId);
                if (! providerRecord || !providerRecord.provider ) {
                    console.error(`Provider ${appointment.providerId} not found!`);
                    continue;      // invalid providerId
                }
                let provider = providerRecord.provider;
                let providerIcon = <ProviderIcon fontSize='large' />;   // TODO: use the provider image if found in the provider record
                let providerName = `${provider.firstName} ${provider.lastName}`;
                if ( provider.title )   
                    providerName = `${provider.title} ` + providerName;

                let now = Moment();                                             // 1585176285664
                let days = Moment(appointment.start).diff(now,'days');
                let hours = Moment(appointment.start).diff(now,'hours');
                let minutes = Moment(appointment.start).diff(now, 'minutes');
                if ( hours > 0 )
                    minutes = minutes % 60;
                console.log(`days: ${days}, hours: ${hours}, minutes: ${minutes}`);

                let apptUpcoming = now > Moment(appointment.start).subtract(30,'minutes');
                let apptNow = now >= Moment(appointment.start);
                let apptDone = now > appointment.end;
                let canVideoChat = appointment.status === 'confirmed' && apptUpcoming && !apptDone;
                console.log(`canVideoChat: ${canVideoChat}, apptNow: ${apptNow}, apptUpcoming: ${apptUpcoming}, apptDone: ${apptDone}`);
                
                let appointmentIn = days > 0 ? <T.span text={{key: 'days', days}} /> : 
                    hours > 0 ? <T.span text={{key: 'hours', hours, minutes }} /> :
                    <T.span text={{key: 'minutes', minutes}} />;
                let appointmentTime = Moment(appointment.start).local().format('MMMM Do YYYY, h:mm a');
                
                let actions = [];
                if ( appointment.status === 'requested') {
                    actions.push(<Button disabled={progress !== null} key='reject' color="primary" variant="contained" style={styles.button} 
                        onClick={()=>{this.confirmRejectAppointment(appointment)}}><T.span text='reject' /></Button> );
                    actions.push(<Button disabled={progress !== null} key='confirm' color="primary" variant="contained" style={styles.button} 
                        onClick={this.confirmAppointment.bind(this,appointment)}><T.span text='confirm' /></Button> );
                }
                if ( appointment.status === 'confirmed') {
                    actions.push( <div key='confirmed' style={{color:'green', display:'flex', alignItems:'center'}}><CheckCircleIcon/><div style={{margin:'0px 5px'}}><T.span text='confirmed'/></div></div>)
                }

                let appointmentKey = apptNow && !apptDone ? 'appointmentNow' : 'appointment';

                gridItems.push(<div key={i} style={styles.appointment}>
                    <Grid container wrap="nowrap" direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item style={{marginRight:'10px'}}>
                            {providerIcon}
                        </Grid>
                        <Grid item style={{marginBottom:'2px'}}>
                        <T.span text={{key: appointmentKey, providerName, appointmentTime, appointmentIn}} />
                        </Grid>
                    </Grid>
                    <Grid container style={{marginLeft:'40px',marginTop:'10px'}} direction="row" justifyContent="flex-start" alignItems="center">
                        {actions}
                    </Grid>
                    { canVideoChat && <div style={{marginLeft:'40px', marginTop:'10px'}}><Button key='videoChat' color="primary" variant="contained" style={styles.button} component={Link} to='/video'>
                        <T.span text='videoConf' /></Button></div>}
                </div>);
            }
        }
        const showRequestNewAppointmentButton = () => {
            //if patient, and patient has a provider to send a request to, show the button
            if (_.get(appContext, "state.provider.providerId")){
                return (
                    <div style={styles.newAppointmentHeader}>
                        <Button disabled={!patient || patient.inactive === true} variant="outlined" onClick={ () => { this.openRequestDialog()}} >
                            <CreateIcon style={{marginRight:'5px'}}/>
                            <T.span text="requestAppointment" />
                        </Button>
                    </div>
                )
            }
        }

        let icon = React.cloneElement( this.props.icon, {fontSize: 'small', style: {fill: this.props.color} } );
        return ( 
            <div id={this.props.id} style={{marginTop:70}}>
                <Paper style={{textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 15, padding: 15}} >
                    <Grid container wrap="nowrap" style={{  marginBottom: 15, paddingBottom: 15, borderBottom: `1px solid grey` }} direction="row" alignItems="center">
                        <Grid item style={{ marginRight: 10 }}>
                            <Avatar style={{ width: 35, height: 35, border: `2px solid ${this.props.color}`, backgroundColor: 'white' }}>{icon}</Avatar>
                        </Grid>
                        <Grid item >
                            <span style={{ fontSize: 22, fontColor: '#666666' }}>{this.props.label}</span>
                        </Grid>
                    </Grid>
                    <Grid container  direction="row" justifyContent="center" style={styles.appointmentsHeader}>
                        <Grid item >
                            {header}
                        </Grid>
                    </Grid>
                    {gridItems}
                    {showRequestNewAppointmentButton()}
                </Paper>
                {dialog}
            </div>
        )
    }
}

const styles = {
    gridContainer: {
        margin: 5,
        width: '95%'
    },
    newAppointmentHeader: {
        display:'flex',
        justifyContent:'center',
        margin:20
    },
    appointmentsHeader: {
        marginTop: 5,
        marginBottom: 15,
    },
    appointment: {
        //margin: '15px 30px 15px 30px',
        paddingBottom: '15px',
        //borderBottom: '1px solid grey'
    },
    button: {
        margin: 5,
    },
    headerPaper: {
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        padding: 10,
        margin: 5,
        width: '95%',
        textAlign: 'center'
    },
    paper: {
        padding: 10,
        margin: 5,
        width: '95%',
        textAlign: 'center'
    }
};