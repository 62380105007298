import React from 'react';
import {
    TextField,
    CircularProgress,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox
} from '@material-ui/core/'

import T from 'i18n-react';

import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import Config from '@apricityhealth/web-common-lib/Config';

export class SearchProvider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            zip: '',
            providers: [],
            selected: -1,
            error: '',
            progress: null,
            requestId: 1,
            lastSearch: {}
        }
    }

    searchProviders() {
        const { firstName, lastName, zip, lastSearch } = this.state;
        if ( firstName.length > 2 || lastName.length > 2 || zip.length >= 5 ) {
            const zipSearch = zip.length === 5 ? zip + '*' : '';
            const searchProviders = {
                url: Config.baseUrl + `${Config.pathPrefix}anon/providers/search`,
                method: 'POST',
                data: {
                    firstName,
                    lastName,
                    zip: zipSearch
                }
            }
    
            if ( JSON.stringify(lastSearch) !== JSON.stringify(searchProviders.data)) {
                console.log("searchProviders request:", searchProviders);
                const requestId = this.state.requestId + 1;
                this.setState({ progress: <CircularProgress />, requestId, lastSearch: searchProviders.data }, () => {
                    AxiosRequest(searchProviders).then((response) => {
                        if ( this.state.requestId === requestId ) {
                            const { providers } = response.data;
                            console.log("searchProviders result:", providers );
                            this.setState({providers, selected: Array(providers.length).fill(false), progress: null});
                        }
                    }).catch((err) => {
                        console.error("searchProviders error:", err);
                        this.setState({progress: null, error: getErrorMessage(err)});
                    });
                });
            }
        }
    }

    onChange(prop, e) {
        let newState = {};
        newState[prop] = e.target.value;

        this.setState(newState, () => {
            this.searchProviders();
        })
    }

    onSelectProvider(i, e, v) {
        const { providers, selected } = this.state;

        let provider = null;
        for(let k=0;k<selected.length;++k) {
            if (k === i) {
                selected[k] = v;
                provider = providers[k];
            } else {
                selected[k] = false;
            }
        }
        console.log("onSelectProvider:", selected );
        this.setState({selected});
        if ( this.props.onChange) {
            this.props.onChange(provider)
        }
    }

    render() {
        const { firstName, lastName, zip, error, providers, selected, progress } = this.state;

        let items = [];
        for(let i=0;i<providers.length;++i) {
            const provider = providers[i];
            if (!provider.standardizedAddressLine1) provider.standardizedAddressLine1 = '';
            if (!provider.standardizedCity) provider.standardizedCity = '';
            if (!provider.standardizedState) provider.standardizedState = '';
            if (!provider.standardizedZip) provider.standardizedZip = '';
            let providerLabel = `${provider.firstName} ${provider.lastName}`;
            if ( provider.clinicName )
                providerLabel += `, ${provider.clinicName}`;
            let providerDetails = `${provider.standardizedAddressLine1}, ${provider.standardizedCity}, ${provider.standardizedState} ${provider.standardizedZip.slice(0,5)}`;
            if ( provider.primaryPhone )
                providerDetails += `, ${provider.primaryPhone}`;

            items.push(<ListItem>
                    <ListItemText primary={providerLabel} secondary={providerDetails} />
                    <ListItemSecondaryAction>
                        <Checkbox checked={selected[i]} onChange={this.onSelectProvider.bind(this,i)} />
                    </ListItemSecondaryAction>
                </ListItem>);
        };

        return <div align='center'>
            <TextField id='firstName' autoFocus={true} fullWidth style={styles.text} label={<T.span text='firstName' />}
                value={firstName} onChange={this.onChange.bind(this, 'firstName')} />
            <br />
            <TextField id='lastName' autoFocus={false} fullWidth style={styles.text} label={<T.span text='lastName' />}
                value={lastName} onChange={this.onChange.bind(this, 'lastName')} />
            <br />
            <TextField id='zip' autoFocus={false} fullWidth style={styles.text} label={<T.span text='postalCode' />}
                value={zip} onChange={this.onChange.bind(this, 'zip')} />
            <br />
            <List style={{ height: 290, overflowY: 'scroll'}}>{progress ? progress : items}</List>
            <br />
            <span style={styles.error}>{error}</span>
        </div>;

    }
}

const styles = {
    error: {
        textAlign: 'center',
        maxWidth: 200,
        marginTop: 10,
        color: 'red',
        fontStyle: 'italic'
    },
    text: {
        margin: 5,
        maxWidth: 350
    }
}

export default SearchProvider;
