import React, { useState, useEffect } from 'react';
import T from 'i18n-react';

import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

import { Grid, Paper, Button, Badge, CircularProgress, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core/';
import BaselineHeader from '@apricityhealth/web-common-lib/components/headers/BaselineHeader';
import SurveyIcon from '@material-ui/icons/Poll';
import DocumentIcon from '@material-ui/icons/Description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const TrialsView = (props) => {
    const { appContext, patientId } = props;

    //const [dialog, setDialog] = useState(null);
    const [loading, setLoading] = useState(false);
    const [trial, setTrial] = useState(null);
    const [patient, setPatient] = useState(null);
    const [trials, setTrials] = useState([]);
    //const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    function restart() {
        setTrial(null);
        getTrials();
    }

    async function getTrials() {
        try {
            setLoading(true);
            setErrorMessage(null);
            setTrials(null);

            const getPatientTrials = {
                url: `${Config.baseUrl}${Config.pathPrefix}trials/patient/${patientId}?all=true`,
                method: 'GET',
                headers: { "Authorization": appContext.state.idToken }
            };
            console.log("getPatientTrials request:", getPatientTrials);
            const response = await AxiosRequest(getPatientTrials);
            console.log("getPatientTrials response:", response.data);

            setTrials( response.data.patientTrials );
            setPatient( response.data.patient );
            setLoading(false);
        } catch (error) {
            console.log('getTrials() error', error);
            setErrorMessage(getErrorMessage(error));
            setLoading(false);
        }
    }

    useEffect(() => {
        getTrials();
    }, [patientId]); // similar to componentDidMount

    useEffect(() => {
        if (trial && patient && !patient.trials.find((e) => e.trialId === trial.trialId)) {
            console.log("Updating patient with new trial:", trial );
            patient.trials.push({trialId: trial.trialId});
            appContext.updatePatient({ trials: patient.trials });
        }
    }, [trial] );

    if (!trial) {
        return <Grid container style={{ marginTop: 55, width: '100%' }}>
            <Paper style={{ width: '100%', textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 15, marginBottom: 15, padding: 15 }}>
                <BaselineHeader noBottomPadding={true} icon={<SurveyIcon style={{ color: 'rgb(0, 118, 187)' }} />} title={<T.span text='trials' />} subtitle={<T.span text='selectTrial' />} withBorder={false} />
            </Paper>
            {errorMessage && <Grid item style={{ color: 'red', justifyContent: 'center', marginTop: 15, marginBottom: 15, marginLeft: 15, marginRight: 15 }}>
                    {errorMessage}
                </Grid>}       
            {trials && trials.length > 0 ?
                trials.map((e) =>
                    <Paper style={{ width: '100%', textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 5, padding: 15 }} 
                        onClick={() => {
                            console.log("Selected trial:", e);
                            setTrial(e);
                        }}
                    >
                        <Button>
                            <Badge invisible={!e.new} color='secondary' overlap="circular" badgeContent={`!`} style={{marginRight: 10}}>
                                <DocumentIcon style={{ color: 'rgb(0, 118, 187)' }} />
                            </Badge>
                            <b>{e.name}</b>
                        </Button>
                    </Paper>
                ) : loading ? <span style={{ width: '100%'}}><CircularProgress /></span> : <T.span style={{ width: '100%' }} text='noTrialsAvailable' textAlign='center' />}
        </Grid>
    } else {
        return (
            <Grid container style={{ marginTop: 55 }}>
                <Paper style={{ width: '100%', textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 15, marginBottom: 15, padding: 15 }}>
                    <BaselineHeader title={<T.span text='trial' />} subtitle={trial.name} color={'rgb(0, 118, 187)'} withBorder={false} noBottomPadding={true} />
                </Paper>
                {errorMessage && 
                    <Grid item style={{ color: 'red', justifyContent: 'center', marginTop: 15, marginBottom: 15, marginLeft: 15, marginRight: 15 }}>
                        {errorMessage}
                    </Grid>}                
                <Grid item style={{margin: 15, textAlign: 'left', whiteSpace: 'pre-wrap'}}>
                    <Paper style={{padding: 5}}>
                        <h3><T.span text='trialDescription' /></h3>
                        <p>{trial.description}</p>
                        <h4><a rel='noreferrer' target='_blank' href={trial.url}><T.span text='trialUrl' /></a></h4>
                        <Accordion style={{margin: 5}}>
                            <AccordionSummary style={{ height: 40}} expandIcon={<ExpandMoreIcon />}>
                                <h3><T.span text='trialCriteria' /></h3>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>{trial.criteria}</p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{margin: 5}}>
                            <AccordionSummary style={{ height: 40}} expandIcon={<ExpandMoreIcon />}>
                                <h3><T.span text='trialIntervention' /></h3>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>{trial.intervention}</p>
                            </AccordionDetails>
                        </Accordion>
                        {trial.contacts.length > 0 ?
                            <Accordion style={{margin: 5}}>
                                <AccordionSummary style={{ height: 40}} expandIcon={<ExpandMoreIcon />}>
                                    <h3><T.span text='trialContacts' /></h3>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div>
                                        {trial.contacts.map((c) => {
                                            return <p>{c.name}, <a href={`tel://${c.number}`}>{c.number}</a>, <a href={`mailto:${c.email}`}>{c.email}</a></p>
                                        })}
                                    </div>
                                </AccordionDetails>
                            </Accordion> : null
                        }
                        {trial.locations.length > 0 ?
                            <Accordion style={{margin: 5}}>
                                <AccordionSummary style={{ height: 40}} expandIcon={<ExpandMoreIcon />}>
                                    <h3><T.span text='trialLocations' /></h3>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div>
                                        {trial.locations.map((l) => {
                                            return <p>{l.name ? `${l.name}, ` : ''}{l.city ? `${l.city}, ` : ''}{l.state ? `${l.state}, ` : ''}{l.postalCode ? `${l.postalCode}, ` : ''}{l.country ? `${l.country}, ` : ''}<br />
                                                <T.span text='trialStatus' />: {l.status}</p>
                                        })}
                                    </div>
                                </AccordionDetails>
                            </Accordion> : null
                        }
                    </Paper>
                </Grid>                    
                <Grid container justify="flex-end" style={{ margin: 15 }} >
                    <Button color="primary" variant="contained" onClick={restart}><T.span text='back' /></Button>
                </Grid>
            </Grid>
        );
    }
}

export default TrialsView;
