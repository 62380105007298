import React from 'react';
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import { 
    Grid,
    Paper,
    CircularProgress,
    Avatar
} from '@material-ui/core';
import T from 'i18n-react';

import { ReportContentItem } from '../components/ReportContentItem';
import { HTMLItem } from '../components/HTMLItem';
import { VideoItemS3 } from '../components/VideoItemS3';
import { VideoItemYT } from '../components/VideoItemYT';

import Carousel from 'react-material-ui-carousel'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export function processEducationContent(educationContent, appContext, { height = 240, outerCarousel = true, onVideoInteraction, onCarouselChange } = {} )
{
    let content = []
    if (Array.isArray(educationContent) && educationContent.length > 0){ 
        content = educationContent.reduce( (prev, item)=> {
            switch (item.type){
                case 's3Video':
                    prev.push(<VideoItemS3 title={item.title} key={item.educationId} height={height} onVideoInteraction={onVideoInteraction} 
                        content={item.content} thumbnail={item.thumbnail} description={item.description}/>)
                    break;
                case 'youTube':
                    prev.push(<VideoItemYT title={item.title} key={item.educationId} height={height} content={item.content} description={item.description} />)
                    break;
                case 'report':
                    prev.push(<ReportContentItem title={item.title} key={item.educationId} height={height} report={item} appContext={appContext} />)
                    break;
                case 'html':
                    prev.push(<HTMLItem title={item.title} key={item.educationId} height={height} content={item.content} description={item.description} />)
                    break;
                case 'slides':
                    {
                        let slides = item.content.split(',').map((image, i) => 
                            <div title={item.title} key={i} style={{minHeight: height, textAlign: 'center'}}>
                                <img style={{ maxHeight: '100%',
                                    height, position: 'absolute', 
                                    top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}}
                                    alt={image} src={`https://s3.amazonaws.com/${image}`} />
                            </div>);
                        if ( outerCarousel ) {
                            prev.push(...slides)
                        } else {
                            prev.push(<div key={item.educationId} align='center' title={item.title} style={{minHeight: height}}>
                                <Carousel
                                    indicators={(slides.length > 1)}
                                    navButtonsAlwaysVisible={slides.length > 1}
                                    fullHeightHover={false}
                                    cycleNavigation={false}
                                    navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                                        style: {
                                            bottom: '0',
                                            top: 'unset'
                                        }
                                    }} 
                                    navButtonsProps={{
                                        style: {
                                            backgroundColor: 'transparent',
                                            borderRadius: 0,
                                        }
                                    }}
                                    NextIcon={<NavigateNextIcon style={{ height: '40px', width: '40px', color: '#8f8f8f' }} />}
                                    PrevIcon={<NavigateBeforeIcon style={{ height: '40px', width: '40px', color: '#8f8f8f' }} />}
                                    swipe={true}
                                    animation={'slide'}
                                    changeOnFirstRender={true}
                                    onChange={(now) => {
                                        if ( onCarouselChange ) {
                                            onCarouselChange( slides[now], now, slides.length );
                                        }
                                    }}
                                    // interval={10000}
                                    // stopAutoPlayOnHover={true}
                                    autoPlay={false}>
                                    {slides}
                                </Carousel>
                            </div>);
                        }
    
                    }
                    break;
                default:
                    break; 
            }
            return prev
        }, [])
    }
    return content
}

export class EducationView extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            videos: [],
            progress: null,
        };
    }
    componentDidMount(){
        this.loadPatientEducation()
    }
    loadPatientEducation(){
        let {appContext} = this.props
        const { idToken, patientId, language } = appContext.state;
        const request = {
            url: Config.baseUrl + `${Config.pathPrefix}content/education/${patientId}?language=${language}&educationView=true`,
            method: 'GET',
            headers: { "Authorization": idToken},
        }

        this.setState({progress: <div className='App-loading'><CircularProgress/></div> });
        console.log(`getPatientEducation request`, request);
        AxiosRequest(request).then((response) => {
            console.log(`getPatientEducation response:`, response.data);
            this.setState( {progress:null, education: response.data})
        }).catch((error) => {
            this.setState( {progress:null})
            console.error("getPatientEducation error", error);
        });
    }

    render(){
        const { appContext } = this.props;
        const {education, progress} = this.state;

        let items = processEducationContent(education, appContext, { outerCarousel: false } ).map((e, i) => {
            return <Grid key={i} id={this.props.id} item xs={12} md={6} lg={4}>
                <Paper style={{padding: 5, height: 300}}>
                <div style={{overflow: 'hidden', height: 300 }}>
                    <Paper elevation={0} style={{background: 'transparent', color: '#808080', marginLeft:15, marginRight:15}}>
                        <div style={{ textAlign: 'center', width: '100%', alignItems: 'center' }}><b>{e.props.title}</b></div>
                    </Paper>
                    {e}
                </div>
                </Paper>
            </Grid>;
        });
        if ( !progress && items.length === 0) {
            items.push(<div key={1} style={{margin:'auto'}}><h4><T.span text="noContent"/></h4></div>)
        } 

        let icon = React.cloneElement( this.props.icon, {fontSize: 'small', style: {fill: this.props.color} } );
        return (<Grid container align="center" style={{marginTop:50}}>
            <Grid item style={{width: '100%', marginTop: 15, marginBottom: 15}}>
                <Paper style={{textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 5, padding: 15}} >
                    <div>
                        <Grid container direction="row" alignItems="center">
                            <Grid item style={{ marginRight: 10 }}>
                                <Avatar style={{ width: 35, height: 35, border: `2px solid ${this.props.color}`, backgroundColor: 'white' }}>{icon}</Avatar>
                            </Grid>
                            <Grid item >
                                <span style={{ fontSize: 22, fontColor: '#666666' }}>{this.props.label}</span>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
                    
                <Grid container style={styles.main} spacing={2} align='left'>
                    {progress}
                    {items}
                </Grid>
            </Grid>
        </Grid>)
    }
}

const styles = {
    main:{
        padding:'10px'
    },
    video:{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100% !important',
        height: '100%',
        overflow: 'hidden'
    },
    videoTitle:{
        padding:'5px',
        margin:'10px 10px 10px 10px'
    },
    videoDescription:{
        padding:'5px',
        margin:'5px 5px 5px 10px'
    },
    paper:{
        paddingBottom:'10px'
    }
}