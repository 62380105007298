import React from 'react';
import Moment from 'moment';
import T from 'i18n-react';

export function getTimeSince(time) {
    if ( time ) {
        let currentDay = Moment().dayOfYear();
        let day = Moment(time).dayOfYear();
        while( currentDay < day ) currentDay += 365;          // handle wrapping of days at the new year, TODO: account for the leap year
        let daysAgo = currentDay - day;

        if ( daysAgo > 0 ) {
            if ( daysAgo === 1 ) 
                return <T.span text='yesterday' />;
            else    
                return <T.span text={{key: 'daysAgo', days: daysAgo}} />;
        }
        else {
            return <T.span text='today' />;
        }
    }
    else {
        return null;
    }
}

export default getTimeSince;
