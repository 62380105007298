import React, { Component } from 'react';
import {
    Button, 
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core/'

import T from 'i18n-react';
import Moment from 'moment';
import MuiPhoneNumber from 'material-ui-phone-number';
import phone from 'phone';

import ReminderHeader from '../components/headers/ReminderHeader';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

export class ReminderDialog extends Component {
    constructor(props) {
        super(props);
        const { appContext } = props;
        const { patient } = appContext.state;

        if (! Array.isArray(patient.phoneNumbers)) patient.phoneNumbers = [];

        this.state = {
            patient,
            reminderEnabled: patient.reminderEnabled || true,
            reminderTime: patient.reminderTime ? Moment(patient.reminderTime).toDate() : new Date(),
            reminderInterval: patient.reminderInterval || (1440 * 7),
            mobile: (patient.phoneNumbers.find(a => a.numberType === 'mobile') || {}).number || '',
            progress: null,
            error: null
        };
        //console.log("ReminderDialog:", this.state, patient );
    }

    onNo(){
        if ( this.state.patient.reminderEnabled !== false) {
            this.setState({reminderEnabled:false}, this.savePatient)
        } else {
            this.props.onSet();
        }
    }

    onYes(){
        if (this.state.patient.reminderEnabled !== true ) {
            this.setState({reminderEnabled:true}, this.savePatient)
        } else {
            this.props.onSet();
        }
    }

    savePatient(){
        const { appContext } = this.props;
        const { patient } = appContext.state;
        const { reminderTime, reminderInterval, reminderEnabled, mobile } = this.state;

        this.setState({ progress: <CircularProgress size={20} />, error: null });

        let mobileNumber = patient.phoneNumbers.find(a => a.numberType === 'mobile');
        if (! mobileNumber ) {
            patient.phoneNumbers.push({ number: phone(mobile).phoneNumber, numberType: 'mobile'})
        } else {
            mobileNumber.number = phone(mobile).phoneNumber;
        }

        appContext.updatePatient({
            reminderTime,
            reminderInterval,
            reminderEnabled,
            phoneNumbers: patient.phoneNumbers
        }).then(() => {
            this.setState({ progress: null }, this.props.onSet);
        }).catch((err) => {
            console.error("savePatient error:", err );
            this.setState({ progress: null, error: getErrorMessage(err) });
        });
    }

    onChangeReminderTime = (time) => {
        this.setState({reminderTime: Moment(time, 'HH:mm A').toDate()})
    }

    render() {
        const { reminderTime, reminderInterval, mobile, progress, error } = this.state; 
        const reminderTimeString = reminderTime && Moment(reminderTime).format('HH:mm');
        const hasPhoneValue = phone(mobile).isValid;

        return <Dialog open={true} fullWidth={true}>
            <div style={{ marginTop: '20px', paddingLeft: '20px', paddingRight: '20px' }}>
                <ReminderHeader
                    title={<T.span text='checkinReminderSetup' />}
                    color={this.props.color}
                    withBorder={false}
                />
            </div>
            <DialogContent>
                <TextField
                    id="time"
                    label={T.translate('reminderTimeLabel')}
                    style={{ width: '200px', margin: 5 }}
                    type="time"
                    defaultValue="08:30"
                    value={reminderTimeString ? reminderTimeString : null}
                    margin="normal"
                    onChange={(event) => { let rTime = Moment(event.target.value, 'HH:mm').format('h:mm A z'); this.onChangeReminderTime(rTime) }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                />
                <FormControl style={{ margin: 5 }}>
                    <InputLabel>
                        <T.span text="reminderInterval" />
                    </InputLabel>
                    <Select
                        value={reminderInterval}
                        style={{ width: 100 }}
                        onChange={(e) => {
                            this.setState( { reminderInterval: e.target.value } );
                        }}
                    >
                        <MenuItem value={1440}>
                            <T.span text="daily" />
                        </MenuItem>
                        <MenuItem value={10080}>
                            <T.span text="weekly" />
                        </MenuItem>
                    </Select>
                </FormControl>
                {reminderInterval === 10080 && <FormControl style={{ margin: 5}}>
                <InputLabel>
                        <T.span text="reminderWeekday" />
                    </InputLabel>
                    <Select
                        value={Moment(reminderTime).day()}
                        style={{ width: 200 }}
                        onChange={(e) => {
                            console.log("dayOfWeek:", e.target.value );
                            let newReminderTime = Moment(reminderTime).day(e.target.value);
                            
                            // normalize the upcoming reminder time to be in the future, but not more then one
                            // week into the future..
                            while( newReminderTime > Moment().add(reminderInterval,'minute') ) {
                                newReminderTime.subtract(reminderInterval,'minute');
                            }
                            while( newReminderTime < Moment() ) {
                                newReminderTime.add(reminderInterval,'minute')
                            }
                            this.setState({ reminderTime: newReminderTime.toDate() })
                        }}
                    >
                        {Array.from(Array(7).keys()).map((d) => <MenuItem value={d}>{Moment().day(d).format('dddd')}</MenuItem>)}
                    </Select>
                </FormControl>}
                <br />
                <p style={{ marginTop: 20 }}><T.span text='setReminderConfirmPhone' /></p>
                    <MuiPhoneNumber
                        defaultCountry={'us'}
                        label={<T.span text='mobile' />}
                        value={mobile}
                        onChange={(e) => { this.setState({ mobile: e }) }}
                    />
            </DialogContent>
            <DialogActions>
                        <span style={{color: 'red'}}>{error}</span>
                        {progress}
                        <Button id="reject" onClick={this.onNo.bind(this)}><T.span text='cancel' /></Button>
                        <Button id="accept" color="primary" variant="contained" onClick={this.onYes.bind(this)} disabled={!hasPhoneValue}><T.span text='confirm' /></Button>
            </DialogActions>
        </Dialog>
    }
};

