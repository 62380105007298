import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { Route, BrowserRouter } from 'react-router-dom';

ReactDOM.render(
    <BrowserRouter basename="/">
        <Route component={App} />
    </BrowserRouter>,
    document.getElementById('root')
);

//ReactDOM.render(<App />, document.getElementById('root'));
