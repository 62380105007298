import React from "react";
import {
    CircularProgress,
    Box
} from '@material-ui/core';

import _ from 'lodash';

import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import { getReport } from '@apricityhealth/web-common-lib/utils/Services';

export class ReportContentItem extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            report: this.props.report && this.props.report.content && JSON.parse(this.props.report.content),
            result: "",
        };
    }

    componentDidMount() {
        this.loadContent();
    }

    loadContent() {
        const { appContext } = this.props;
        const { cache } = appContext.state;
        const { reportName, recipe, args } = this.state.report;

        try {
            if (!reportName) throw new Error("reportName not found in content!");
            if (!recipe) throw new Error("recipe not found in content!");
            if (!args) throw new Error("args not found in content!");

            args.patientId = _.get(this.props.appContext.state,'patientId');
            args.language = _.get(this.props.appContext.state,'language', 'en-us' );
            
            let cacheKey = `ReportContentItem.${reportName}.${recipe}.${JSON.stringify(args)}`;
            if ( cache[cacheKey] !== undefined ) {
                return this.setState({ result: cache[cacheKey].result });
            }
            this.setState({ progress: <CircularProgress size={30} style={{margin:'auto'}} />});
            getReport(this.props.appContext, reportName, recipe, args)
                .then((result) => {
                    this.setState({ result, progress:null });
                    cache[cacheKey] = { result };       
                })
                .catch((err) => {
                    this.setState({ result: getErrorMessage(err), progress:null });
                });
        } catch (err) {
            this.setState({ result: getErrorMessage(err), progress:null });
        }
    }

    render() {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column',margin:'auto', minHeight: this.props.height || 240}}>
                {this.state.progress ? this.state.progress :<Box width="65%"><div dangerouslySetInnerHTML={{ __html: this.state.result }} /></Box>}
            </div>
        )
    }
}
