import React, { Component } from 'react';
import {
    Paper,
    FormControl,
    InputLabel,
    CircularProgress,
    TextField,
    Select,
    MenuItem,
    Button,
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
    Avatar
} from '@material-ui/core/'

import T from 'i18n-react';
import { Link } from 'react-router-dom'
import FileReaderInput from 'react-file-reader-input';
import Jimp from 'jimp/es';

import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

function isArrayValid(array) {
    return Array.isArray(array) && array.length > 0;
}

export class FeedbackView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageBody: "",
            feedbackType: "Technical Enhancement",
            image: null,
            type: null,
            dialog: null
        };
    }

    sendFeedback() {
        let self = this;
        let { messageBody, feedbackType, image, type } = this.state;
        console.log(`feeback `, feedbackType, messageBody);
        const { appContext } = this.props
        const {patient, user} = appContext.state;
        const userAgent = navigator.userAgent;
        const message = messageBody;
        
        if (userAgent) {
            messageBody = messageBody + "\n" + JSON.stringify(userAgent, null, 4);
        }

        const data = {
            subject: feedbackType + " (Patient Web App)",
            comment: messageBody,
            
            // decoupling our data for zendesk, but dont break existing for now
            feedbackType,
            userAgent,
            message,
            application: "Patient Web App",
            url: window.location.href,
            user: {
                groups: user.groups,
                email: user.email,
                firstName: patient.firstName,
                lastName: patient.lastName,
            }
        };

        if (image) {
            data.image = {
                data: image,
                mimeType: type
            }
        }
        const request = {
            data,
            url: Config.baseUrl + `${Config.pathPrefix}messaging/feedback`,
            method: 'POST',
            headers: { "Authorization": appContext.state.idToken }
        }
        console.log(`sendingFeedback request `, request);

        self.setState({ progress: <CircularProgress />, error: null });
        AxiosRequest(request).then((response) => {
            console.log(`sendingFeedback response `, response);
            let dialog = <Dialog open={true}>
                <DialogContent>
                    <T.div text="feedbackSent" />
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='primary' component={Link} to="/dashboard"><T.span text='ok' /></Button>
                </DialogActions>
            </Dialog>;

            self.setState({ dialog, progress: null, messageBody: "", image: null, type: null })
        }).catch((err) => {
            console.error("sendingFeedback error:", err);
            self.setState({ progress: null, error: getErrorMessage(err) });
        })
    }

    onUploadFile = (e, results) => {
        const self = this;

        console.log('onUploadFile', e, results);
        if (isArrayValid(results)) {
            //   just get the first image
            let result = results[0];
            const [e] = result;
            let data = e.target.result;

            self.setState({progress: <CircularProgress />, error: null });
            Jimp.read(data).then((image) => {
                //console.log("image:", image );
                if ( image.bitmap.width > 1024 || image.bitmap.height > 1024 ) {
                    let aspect = image.bitmap.width / image.bitmap.height;
                    if ( image.bitmap.width > image.bitmap.height ) {
                        return image.resize( 1024, 1024 / aspect )
                    }
                    else {
                        return image.resize( 1024 * aspect, 1024 );
                    }
                }
                else {
                    return Promise.resolve(image);
                }
            }).then((image) => {
                //console.log("resized image:", image );
                return image.getBase64Async('image/jpeg');
            }).then((image64) => {
                //console.log("encoded image:", image64 );
                let image = image64.substr(image64.indexOf(',') + 1);
                self.setState({ progress: null, image, type: 'image/jpg', uploadedImage: data });
            }).catch((err) => {
                console.error("onUploadFile error:", err );
                self.setState({ progress: null, error: getErrorMessage(err) });
            })
        }
    }

    onClick(e) {
        console.log('onClick', e);
    }

    onCancel(e) {
        console.log('onCancel', e);
    }

    render() {
        const { dialog, redirect, progress, error, messageBody, feedbackType, uploadedImage } = this.state
        const self = this

        if (redirect)
            return redirect;

        // eslint-disable-next-line
        let img = uploadedImage ? <FileReaderInput as="url" id="upload" onChange={this.onUploadFile.bind(self)} onClick={this.onClick.bind(self)}>
                <img src={uploadedImage} style={{ width: 120 }} alt='Upload' /></FileReaderInput> :
            <FileReaderInput as="url" id="upload" onChange={this.onUploadFile.bind(self)} onClick={this.onClick.bind(self)}>
                <Button color="primary" variant="contained" style={styles.imageUploadbutton}><T.span text="imageUpload" /></Button></FileReaderInput>;

        let icon = React.cloneElement( this.props.icon, {fontSize: 'small', style: {fill: this.props.color} } );
        return (
            <Grid id={this.props.id} container align="center" className='messages' style={{marginTop:50}}>
                <Grid item style={{width: '100%', marginTop: 15, marginBottom: 15}}>
                    <Paper style={{textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 5, padding: 15}} >

                        <div style={{ marginBottom: 15, paddingBottom: 15, borderBottom: `1px solid grey` }}>
                            <Grid container direction="row" alignItems="center">
                                <Grid item style={{ marginRight: 10 }}>
                                    <Avatar style={{ width: 35, height: 35, border: `2px solid ${this.props.color}`, backgroundColor: 'white' }}>{icon}</Avatar>
                                </Grid>
                                <Grid item >
                                    <span style={{ fontSize: 22, fontColor: '#666666' }}>{this.props.label}</span>
                                </Grid>
                            </Grid>
                        </div>
                        
                        <h5><T.span text="feedbackInstructions" /></h5>
                        
                        <Grid item xs={12}>
                            <FormControl style={{ margin: 5 }}>
                                <InputLabel><T.span text="feedbackType"></T.span>:</InputLabel>
                                <Select
                                    style={{ width: 250 }}
                                    value={feedbackType}
                                    onChange={(e) => { let body = e.target.value; self.setState({ feedbackType: body }); }}
                                >
                                    <MenuItem key={"1"} value={'Technical Issue'}><T.span text='technicalIssue' /></MenuItem>
                                    <MenuItem key={"2"} value={"Technical Enhancement"}><T.span text='technicalEnhancement' /></MenuItem>
                                    <MenuItem key={"3"} value={"Request Account Removal"}><T.span text='removeAccountRequest' /></MenuItem>
                                </Select>
                            </FormControl>
                            <br />
                            <TextField
                                id="outlined-multiline-flexible"
                                label={<T.span text='feedback'></T.span>}
                                multiline
                                fullWidth
                                rows="6"
                                style={{ margin: 5 }}
                                value={messageBody}
                                onChange={(e) => { let body = e.target.value; self.setState({ messageBody: body }); }}
                                variant="outlined"
                            />
                            <br />
                            <div align='center'>
                                {img}
                            </div>
                            <br />
                            <div style={styles.sendButton}>
                                <Button color="primary" variant="contained" style={styles.sendButton} onClick={self.sendFeedback.bind(this)}><T.span text="send"></T.span></Button>
                            </div>
                        </Grid>
                    </Paper>
                </Grid>
                {progress}
                {error}
                {dialog}
            </Grid>
        )
    }

}

const styles = {
    imageUploadbutton: {
        margin: 5,
        width: '30'
    },
    subjectField: {
        width: '100%'
    },
    inboxSentTable: {
        margin: 10
    },
    newMessage: {
        textAlign: 'left',
        // marginLeft: 20
    },
    inboxHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 10
    },
    composeHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 10
    },
    composeBody: {
        textAlign: 'left',
        justifyContent: 'space-between',
        margin: 10,
        padding: 10
    },
    sendButton: {
        margin: 5,
        textAlign: 'right',
        // float: 'right',
        // position: 'relative'
    }
}

export default FeedbackView;