import React, { useRef } from 'react';
import { useSetState } from 'react-use'
import Moment from 'moment-timezone';
import {
	TextField,
	Button,
	Select,
	MenuItem,
    FormControl,
    InputLabel,
    DialogTitle,
    DialogContent,
    DialogActions,
    } from '@material-ui/core';    
import T from 'i18n-react';

import UploadPatientImage from '@apricityhealth/web-common-lib/components/UploadPatientImage';
import PatientImage from '@apricityhealth/web-common-lib/components/PatientImage';
import defaultImage from '@apricityhealth/web-common-lib/uploadImage.jpg';
import Dialog from '@material-ui/core/Dialog';


const testSampleTypes = [
    {value: 1, label: 'noseOrThroatSwab', hidden: true},
    {value: 2, label: 'saliva', hidden: true},
    {value: 3, label: 'pcrMolecularTesting'},
    {value: 4, label: 'rapidAntigenTesting'},
]

const testResultTypes = [
    {value: 0, label: 'negative'},
    {value: 1, label: 'positive'},
    {value: 'NaN', label: 'indeterminant'},
]


export default function CovidTestDialog(props) {
    const imageUploadRef = useRef()
    const [test, setTest] = useSetState(props.test)

    const onDone = () => {
        if (props.onDone)
		    props.onDone(test);
    }

    const onCancel = () => {
        if (props.onCancel)
            props.onCancel()
    }

    const onImageChange = (blobId) => setTest({ blobId })
    const onChangeTestResult = (e) => setTest({ testResult: e.target.value })
    const onChangeEventTime = (e) => setTest({ date: Moment(e.target.value).utc(false).format('YYYY-MM-DD') })
    const onChangeTestSample = (e) => setTest({ sample: e.target.value })

    const isFutureDate = test.date && Moment(test.date).startOf('day').toISOString() > Moment().startOf('day').toISOString();
    const isInvalidDate = !test.date || !Moment(test.date).isValid();
    const disabled = !test.blobId || isFutureDate || isInvalidDate

    const resultSelect = <FormControl fullWidth>
        <InputLabel>{T.translate('testResult')}</InputLabel> 
        <Select fullWidth
            value={String(test.testResult)}
            onChange={onChangeTestResult}
            label={T.translate('testResult')}
            style={styles.formItem}
        >
            {testResultTypes.map(({ value, label }) => <MenuItem key={value} value={value}>{T.translate(label)}</MenuItem>)}
        </Select> 
    </FormControl>

    const dateSelect = <FormControl fullWidth>
        <TextField id="eventTime"
            fullWidth
            label={T.translate('date')}
            style={styles.formItem}
            InputLabelProps={{ shrink: true }}
            type={'date'}
            inputProps= { {inputMode: 'date'}}
            value={test.date}
            onChange={onChangeEventTime}
        />
    </FormControl>;

    const sampleSelect = <FormControl fullWidth style={styles.formItem}>
        <InputLabel>{T.translate('sample')}</InputLabel> 
        <Select fullWidth
            value={test.sample}
            onChange={onChangeTestSample}
            label={T.translate('sample')}
            placeholder={T.translate('sample')}
            style={styles.formItem}
        >
            {testSampleTypes.filter(item => !item.hidden || item.value === test.sample)
            .map(({ value, label }) => <MenuItem key={value} value={value}>{T.translate(label)}</MenuItem>)}
        </Select>
    </FormControl>

    return (
        <Dialog open={true} onClose={onCancel}>
            <DialogTitle>{T.translate('covidTest')}</DialogTitle>
            <DialogContent>
                {resultSelect}
                {dateSelect}
                {isFutureDate && <span style={styles.invalidDate}>{T.translate('dateFutureError')}</span>}
                {sampleSelect}
                <UploadPatientImage
                    styles={styles.formItem}
                    maxImageCount={1}
                    key={0}
                    ref={imageUploadRef}
                    appContext={props.appContext}
                    patientId={props.appContext.state.patientId}
                    disabled={false}
                    onChange={onImageChange}
                    hideUploads={false}
                >
                    <PatientImage
                            key={0}
                            styles={{justifyContent:'center'}}
                            appContext={props.appContext}
                            blobId={test.blobId}
                            size={300}
                            defaultImage={defaultImage}
                            alt={'covid test photo'}
                            square={false}
                            enableDelete={false}
                        />
                </UploadPatientImage>
            </DialogContent>

            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={onCancel}>
                    {T.translate('cancel')}
                </Button>
                <Button disabled={disabled} variant="contained" style={styles.button} onClick={onDone}>
                    {T.translate('save')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const styles = {
    formItem: {
        marginTop:15,
        marginBottom:15,
        minWidth:300
    },
    button:{
        margin:5
    },
    buttonSection:{
        display:'flex',
        justifyContent:'space-between'
    },
    invalidDate: {
        color: 'red'
    }
} 
