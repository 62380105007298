import React from "react"

export class VideoItemYT extends React.Component {
    render() {
        const { height, description } = this.props;
        return (
            <div style={{ height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                    <div style={{ color: '#474747', fontFamily: 'Arial,Helvetica,sans-serif', alignContent: 'center', fontSize: '12px' }}>
                        <i>{description}</i>
                    </div>
                    <iframe
                        style={{
                            maxHeight: height - 50, maxWidth: '100%',
                            width: 'auto', height, margin: 'auto'
                        }}
                        title="Embed Preview"
                        frameBorder="0"
                        scrolling="no"
                        type="text/html"
                        src={`https://www.youtube.com/embed/${this.props.content}`}
                    />
                </div>
            </div>
        )
    }
}
