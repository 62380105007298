import React, { Component } from 'react';

import {
    DialogActions, 
    DialogContent, 
    Dialog, 
    Button, 
    MenuItem,
    Select
} from '@material-ui/core/'

import T from 'i18n-react';

export class SelectSelf extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            patient: null
        }
    }

    onSelectPatient(e) {
        console.log("onSelectPatient:", e.target.value );
        this.setState({patient: e.target.value});
    }

    onSelect() {
        this.props.onSelect(this.state.patient);
    }

    onCancel() {
        this.props.onSelect(null);
    }

    render() {
        let { patient } = this.state;
        let { patients } = this.props;

        patients.sort((a,b) => {
            let compare = a.lastName.localeCompare( b.lastName );
            if ( compare !== 0 ) return compare;
            return a.firstName.localeCompare( b.firstName );
        })
        let items = [];
        for (let i = 0; i < patients.length; ++i) {
            let patient = patients[i];
            items.push( <MenuItem key={i} value={patient}>{patient.lastName}, {patient.firstName}</MenuItem>);
        }

        return <Dialog model="true" open={true} onClose={this.onCancel.bind(this)}>
            <DialogContent>
                <T.span text='selectPatientContent' />
                <br />
                <Select value={patient || ''} style={{ margin: 5, width:300}} onChange={this.onSelectPatient.bind(this)}>
                    {items}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" style={styles.button} onClick={this.onCancel.bind(this)}><T.span text='cancel' /></Button>
                <Button disabled={this.state.patient === null} color="primary" variant="contained" style={styles.button} onClick={this.onSelect.bind(this)}><T.span text='OK' /></Button>
            </DialogActions>
        </Dialog>;
    }
};

const styles = {
    button: {
        margin: 10
    }
};
