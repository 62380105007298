import React from "react";

export class HTMLItem extends React.Component {
    render() {
        const { height, description, content } = this.props;
        return (
            <div style={{ justifyContent: 'center', alignItems: 'center', margin: 5, height, overflowX: 'clip', overflowY: 'auto', width: '99%' }}>
                <div style={{ color: '#474747', fontFamily: 'Arial,Helvetica,sans-serif', textAlign: 'center', fontSize: '12px'}}>
                    <i>{description}</i>
                </div>
                <div style={{ display: 'flow', textAlign: 'left', color: '#474747', fontFamily: 'Arial,Helvetica,sans-serif' }} dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        )
    }
}
