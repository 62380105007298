import React from 'react';
import { 
    Grid,
    Paper,
    Avatar
} from '@material-ui/core';
import T from 'i18n-react';

export class HotSpotsView extends React.Component {
    render(){
        console.log('HotSpots render()', this.props, this.state);
        let text = T.texts[this.props.info];
        let icon = React.cloneElement( this.props.icon, {fontSize: 'small', style: {fill: this.props.color} } );
        return(<Grid id={this.props.id} container align="center" style={{marginTop:50}} >
            <Grid item style={{width: '100%', marginTop: 15, marginBottom: 15}}>
                <Paper style={{textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 5, padding: 15}} >
                    <div style={styles.div}>
                        <div style={{ marginBottom: 15, paddingBottom: 15, borderBottom: `1px solid grey` }}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item style={{ marginRight: 10 }}>
                                <Avatar style={{ width: 35, height: 35, border: `2px solid ${this.props.color}`, backgroundColor: 'white' }}>{icon}</Avatar>
                            </Grid>
                            <Grid item >
                                <span style={{ fontSize: 22, fontColor: '#666666' }}>{this.props.label}</span>
                            </Grid>
                        </Grid>
                        </div>
                            <div id="hotSpotsView" className="App" ref={el => (this.div = el)}>
                            <div style={{padding:'20px 0 20px 0'}} id="talisai_widget"></div>
                            <div style={{textAlign:'left', fontSize:12}} dangerouslySetInnerHTML={{__html: text }}></div>
                         </div>
                    </div >
                </Paper>
            </Grid>
        </Grid>
        )
    }
    componentDidMount() {
            const script = document.createElement("script");
            script.async = true;
            script.src = "https://www.talisai.com/widgets/covid-19/apricity-health/media/js/tai-covid-19.js ";
            this.div.appendChild(script);
      }
}

const styles = {
    div: {
        margin: 5,
        textAlign: 'left'
    }
};

export default HotSpotsView;