import React from 'react';
import _ from 'lodash';
import jwtDecode from 'jwt-decode';
import { Redirect } from 'react-router-dom';

import {
    Grid,
    Button,
    CircularProgress,
} from '@material-ui/core/';

import T from 'i18n-react';

import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

import { Logger } from '@apricityhealth/web-common-lib/utils/Logger';

const log = new Logger();

export class SubscriptionView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plans: [],
            progress: null,
            dialog: null,
            error: null,
            disableSubscribe: false,
            redirect: null,
            userId: '',
            email: '',
            mobile: ''
        };
    }

    componentDidMount() {
        const { appContext: { state: { idToken }} } = this.props;
        const token = jwtDecode(idToken);

        this.setState({
            userId: token["cognito:username"] || token["sub"],
            email: token.email,
            mobile: token.phone_number,
        });
    
        this.getSubscriptionPlans();
    }

    getSubscriptionPlans() {
        const { appContext } = this.props;
        const { state: { idToken, org }} = appContext;
        
        const getSubscriptionPlans = {
            url: Config.baseUrl + `${Config.pathPrefix}billing/${org.orgId}/plans`,
            method: 'GET',
            headers: { "Authorization": idToken }
        }
        log.info("getSubscriptionPlans request:", getSubscriptionPlans );
        AxiosRequest(getSubscriptionPlans).then(({data}) => {
            log.info("getSubscriptionPlans result:", data );
            this.setState({plans: data});
        }).catch((err) => {
            log.error("getSubscriptionPlans error:", err);
        });

        appContext.reloadPatient();
    }

    onSubscribe( plan ) {
        log.info("onSubscribe:", plan );
        this.setState({disableSubscribe: true});
        const { appContext: { state: { idToken, org, patientId, patient }} } = this.props;
        const { email, mobile } = this.state;

        const request = {
            url: Config.baseUrl + `${Config.pathPrefix}billing/${org.orgId}/subscribeLink`,
            method: 'POST',
            headers: { "Authorization": idToken },
            data: {
                subscriptionPlanId: plan.id,
                patientId,
                redirectUrl: Config.patientClientUrl + `?patientId=${encodeURIComponent(patientId)}`,
                email,
                phone: mobile,
                given_name: patient.firstName,
                family_name: patient.lastName
            }
        }

        log.info("createSubscribeLink request:", request );
        AxiosRequest(request).then(({data}) => {
            log.info("createSubscribeLink result:", data );
            this.setState({ redirect: <Redirect to={`/web?url=${encodeURIComponent(data.url)}`} />}, this.props.onDone )
        }).catch((err) => {
            log.error("createSubscribeLink error:", err);
            this.setState({ error: getErrorMessage(err) })
        });
    }

    onManageSubscription( manageUrl ) {
        log.info("onManageSubscription:", manageUrl );
        this.setState({ redirect: <Redirect to={`/web?url=${encodeURIComponent(manageUrl)}`} />})
    }

    render() {
        const { appContext: { state: { patient }}} = this.props;
        const{ plans, dialog, error, disableSubscribe, redirect } = this.state;

        if ( redirect ) {
            return redirect;
        }

        if ( plans.length === 0 ) {
            return <div align='center'><CircularProgress /></div>;       // wait for the plans to load
        }
 
        let items = [];

        const { subscriptionInfo } = patient; 
        const { manageUrl, subscription } = (subscriptionInfo || {});
        const status = _.get((subscription || {}),"status");
        const planId = _.get((subscription || {}),"plan_id");
        const isSubscribed = patient.inactive !== true && patient.subscriptionId && status === 'ACTIVE';
        if ( isSubscribed ) {

            let plan = plans.find((e) => e.id === planId);
            let priceInfo = '';
            let name = '';
            if ( plan ) {
                let lastPhase = plan.subscriptionPlanData.phases.slice(-1)[0];
                name = plan.subscriptionPlanData.name;
                priceInfo = `$${(lastPhase.recurringPriceMoney.amount / 100).toFixed(2)} ${lastPhase.recurringPriceMoney.currency} / ${T.translate(lastPhase.cadence)}`;
            }

            items.push(<div key='info' style={{margin: 5, marginBottom: 25}}>
                <T.span text={{ key: 'activeSubscriptionInfo', name, priceInfo, status }} />
            </div>);

            if ( manageUrl ) {
                items.push(<Button key={items.length} variant='contained' color='primary' onClick={this.onManageSubscription.bind(this, manageUrl)}><T.span text='manage' /></Button>)
            }

        } else {
            items.push(<div key='info' style={{margin: 5, marginBottom: 25}}>
                <T.span text='noActiveSubscriptionInfo' />
            </div>);

            for(let i=0;i<plans.length;++i) {
                let plan = plans[i];
                let lastPhase = plan.subscriptionPlanData.phases.slice(-1)[0];
                let priceInfo = `$${(lastPhase.recurringPriceMoney.amount / 100).toFixed(2)} ${lastPhase.recurringPriceMoney.currency} / ${T.translate(lastPhase.cadence)}`;

                items.push(
                    <Grid container key={items.length} wrap="nowrap" direction="row" justifyContent="flex-start">
                        <Grid item style={{ marginRight: 100 }}>
                            <Button disabled={disableSubscribe} variant='contained' color='primary' onClick={this.onSubscribe.bind(this, plan)} style={{minWidth: 250, margin: 5}}>{plan.subscriptionPlanData.name}<br />{priceInfo}</Button>
                        </Grid>
                    </Grid>
                );
            }
        }


        return ( 
            <div>
                {items}
                {dialog}
                <span style={{color: 'red'}}>{error}</span>
            </div>
        )
    }
}

export default SubscriptionView;