import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    CircularProgress,
} from '@material-ui/core/';

import MuiPhoneNumber from 'material-ui-phone-number';
import T from 'i18n-react';
import phone from 'phone';

import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

import { Logger } from '@apricityhealth/web-common-lib/utils/Logger';

const log = new Logger();

export class ChangeUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: props.userId || '',
            email: props.email || '',
            mobile: props.mobile || '',
            progress: null,
            error: null,
        };
    }

    onCancel() {
        this.props.onDone();
    }

    onDone() {
        const self = this;
        const { appContext } = this.props;
        const { idToken, patientId } = appContext.state;
        const { userId, email, mobile } = this.state;

        let saveUser = {
            url: Config.baseUrl + `${Config.pathPrefix}authentication/users`,
            method: 'POST',
            headers: { Authorization: idToken },
            data: {
                username: userId,
                email: email.toLowerCase().trim(),
                phone: phone(mobile).phoneNumber || undefined,
            },
        };

        let updatePatient = {
            url: Config.baseUrl + `${Config.pathPrefix}patients/${patientId}`,
            method: 'PUT',
            headers: { Authorization: idToken },
            data: {
                phoneNumbers: [
                    {
                        number: phone(mobile).phoneNumber || undefined,
                        numberType: 'mobile'
                    }
                ],
                emails: [
                    {
                        emailType: 'primary',
                        email,
                    },
                ],
            },
        };

        log.debug('saveUser request:', saveUser);
        this.setState({ progress: <CircularProgress />, error: null });
        AxiosRequest(saveUser)
            .then((result) => {
                log.debug('saveUser response:', result.data);
                appContext.setState({user: result.data.user });
                log.debug('updatePatient request:', updatePatient );
                return AxiosRequest(updatePatient);
            })
            .then((result) => {
                log.debug('updatePatient response:', result);
                appContext.setState({ username: email || mobile }, () => {
                    this.props.onDone(email, mobile);
                });
            })
            .catch((err) => {
                log.error('saveUser error:', err);
                self.setState({ progress: null, error: getErrorMessage(err) });
                this.props.onDone();
            });
    }

    render() {
        const self = this;
        const { confirmMobile } = this.props;
        let { error, progress, email, mobile } = this.state;

        return <Dialog open={true}>
            <DialogTitle>{confirmMobile ? <T.span text='setReminderConfirmPhone' /> : <T.span text='updateUser' />}</DialogTitle>
            <DialogContent>
                {!confirmMobile && (
                    <TextField
                        style={styles.text}
                        label={<T.span text="email" />}
                        value={email}
                        onChange={(e) => {
                            self.setState({ email: e.target.value });
                        }}
                    />
                )}
                <MuiPhoneNumber
                    defaultCountry={'us'}
                    label={<T.span text="mobile" />}
                    style={styles.text}
                    value={mobile}
                    onChange={(e) => {
                        self.setState({ mobile: e });
                    }}
                />
                <br />
            </DialogContent>
            <DialogActions>
                {progress}
                <Button color="primary" variant="contained" onClick={this.onCancel.bind(this)} style={{width: 150, margin: 5}}>
                    <T.span text="cancel" />
                </Button>
                <Button
                    style={{width: 150, margin: 5}}
                    disabled={progress !== null || !phone(mobile).isValid}
                    color="primary"
                    variant="contained"
                    onClick={this.onDone.bind(this)}
                >
                    <T.span text="ok" />
                </Button>
                <br />
                <span style={{color: 'red'}}>{error}</span>
            </DialogActions>
        </Dialog>;
    }
}

const styles = {
    text: {
        margin: 5,
        width: 250,
    }
};

export default ChangeUser;
